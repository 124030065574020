import { Button, ButtonProps } from '@mui/material'
import { styled } from '@mui/system'

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    background: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 800,
    padding: '0.6em 2.6em',
    minWidth: '16em',

    '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    },

    '&:disabled': {
        background: theme.palette.action.disabledBackground,
        border: 'none'
    }
}))
