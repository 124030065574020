import { ButtonProps, CircularProgress, Typography } from '@mui/material'
import { FC } from 'react'
import { IButtonProps } from './button.interface'
import { StyledButton } from './button.styled'

const Button: FC<ButtonProps & IButtonProps> = (props): JSX.Element => {
    const { sx, isLoading, ...others } = props
    return (
        <StyledButton {...others}>
            <Typography variant="body1" fontWeight={800}>
                {props.children}
            </Typography>
            {isLoading && (
                <CircularProgress
                    size={20}
                    sx={{ position: 'absolute', right: 35 }}
                />
            )}
        </StyledButton>
    )
}

export default Button
