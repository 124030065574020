import { Container } from '@mui/material'
import { styled } from '@mui/system'

export const Wrapper = styled(Container)(({ theme }) => ({
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    maxHeight: '100%',
    background: theme.palette.mainBg.main
}))
