import { useRoutes } from 'react-router-dom'
import adminRoutes from './adminRoutes.const'
import clientRoutes from './clientRoutes.const'
import publicRoutes from './publicRoutes.const'

function RenderAllRoutes() {
    return useRoutes([...adminRoutes, ...clientRoutes, ...publicRoutes])
}

export default RenderAllRoutes
