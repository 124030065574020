import React, { FC, useEffect, useState } from 'react'
import { Grid, Box, Typography } from '@mui/material'
import Button from 'components/global/Button'
import CommonModal from 'components/global/commonModal'
import TextInput from 'components/global/TextInput'
import { myAccountContentLeft, myAccountContentRight } from '../myAccount.cont'
import { IUser } from 'utils/app.interface'
import { getSenderNames } from 'api/senderNames/sender'
import { getUser } from 'utils/token'

const MyAccountProfile: FC = () => {
    // Hooks
    const [open, setOpen] = useState(false)
    const [requestModalOpen, setRequestModalOpen] = useState(false)
    const [user, setUser] = useState<IUser | null>(null)
    const [senderNames, setSenderNames] = useState<any>([])

    useEffect(() => {
        const user = getUser()
        setUser(user)
        getSenderNames({ id: user?.id! }).then((res) => {
            setSenderNames(res.data)
        })
    }, [])

    // Handlers
    const handleClose = () => setOpen(false)

    const printSenderNames = () => {
        if (senderNames.length === 0) return
        return senderNames.map((item: any) => item.sender_name).join(',')
    }

    if (!user) {
        return (
            <div>
                <h1>Loading</h1>
            </div>
        )
    }

    return (
        <>
            <Box mt={5.7}>
                <Typography variant="h2" color="primary" mb={2}>
                    My Account
                </Typography>
                <Grid container>
                    <Box>
                        <Box display="flex" gap={15}>
                            <Box>
                                {myAccountContentLeft.map((item) => (
                                    <>
                                        <Typography
                                            variant="subtitle1"
                                            key={item.key}
                                        >
                                            {item.value}
                                        </Typography>
                                        <Typography
                                            key={item.key}
                                            variant="h3"
                                            fontWeight="normal"
                                            mb={5}
                                        >
                                            {user[
                                                item.key as keyof typeof user
                                            ] || 'N/A'}
                                        </Typography>
                                    </>
                                ))}
                                <>
                                    <Typography variant="subtitle1">
                                        REQUESTED SENDER NAME/S
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        fontWeight="normal"
                                        mb={5}
                                    >
                                        {printSenderNames()}
                                    </Typography>
                                </>
                            </Box>
                            <Box>
                                {myAccountContentRight.map((item) => (
                                    <>
                                        <Typography
                                            variant="subtitle1"
                                            key={item.key}
                                        >
                                            {item.value}
                                        </Typography>
                                        <Typography
                                            key={item.key}
                                            variant="h3"
                                            fontWeight="normal"
                                            mb={5}
                                        >
                                            {user[
                                                item.key as keyof typeof user
                                            ] || 'N/A'}
                                        </Typography>
                                    </>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Box>
            <CommonModal
                open={requestModalOpen}
                close={() => setRequestModalOpen(false)}
            >
                <Box sx={{ width: { xs: '90vw', md: 610 } }} padding={1}>
                    <Box>
                        <Typography variant="h2" color="primary" gutterBottom>
                            Request New Sender Names
                        </Typography>
                        <Typography variant="h4" fontWeight="normal">
                            Preferred SMS Sender Name
                        </Typography>
                        <Typography
                            variant="h6"
                            fontWeight="normal"
                            color="#959595"
                        >
                            (Please give options; Separate with comma, Max 11
                            Characters; No Spaces)
                        </Typography>
                        <Box mb={5} mt={1}>
                            <TextInput fullWidth autoFocus />
                            <Typography
                                variant="h6"
                                fontStyle="italic"
                                color="primary"
                            >
                                SMS Sender Names are subject to provisioning and
                                approval.
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <Button>Submit</Button>
                        </Box>
                    </Box>
                </Box>
            </CommonModal>

            <CommonModal open={open} close={handleClose}>
                <Box sx={{ width: { xs: '90vw', md: 610 } }} padding={1}>
                    <Box>
                        <Typography variant="h2" color="primary" gutterBottom>
                            Success!
                        </Typography>
                        <Typography>
                            Requested names have successfully been submitted.
                        </Typography>
                        <Typography>
                            You will receive an email confirmation in 3-5
                            working days.
                        </Typography>
                    </Box>
                </Box>
            </CommonModal>
        </>
    )
}

export default MyAccountProfile
