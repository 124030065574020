import { styled } from '@mui//system'

export const StyledMainContainer = styled('div')(({ theme }) => ({
    width: '100%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.mainBg.main,
    padding: '5em',

    '@media(max-width: 720px)': {
        padding: '1.5em'
    }
}))

export const StyledMainBackgroundImage = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: `url(${require('assets/images/landing-page-background-image.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center right',
    zIndex: 1
})

export const StyledMainContent = styled('div')(({ theme }) => ({
    position: 'relative',
    zIndex: 2,
    maxWidth: '30em',

    '@media(max-width: 720px)': {
        background: theme.palette.mainBg.main,
        maxWidth: '100%',
        padding: '1.5em',
        borderRadius: theme.shape.borderRadius,
        textAlign: 'center'
    }
}))

export const StyledMainForgotpassword = styled('p')(() => ({
    '&:hover': {
        cursor: 'pointer'
    }
}))
