import { FormControl, InputLabel } from '@mui/material'
import { styled } from '@mui/system'

export const StyledInputLabel = styled(InputLabel)({
    color: 'black'
})
export const StyledFormControl = styled(FormControl)({
    width: 110,
    height: 46
})
