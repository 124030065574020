// Absolute
import React, { FC } from 'react'
import { Box, InputAdornment, Typography, Grid } from '@mui/material'
// Relative
import TextInput from '../TextInput'
import { commonTextAreaContent } from './commonTextArea.cont'
import { ICommonTextArea } from './commonTextArea.interface'
import ToolTip from '../commonToolTip'

const CommonTextArea: FC<ICommonTextArea> = (props: ICommonTextArea) => {
    const {
        placeholder,
        rows,
        textAreaValue,
        onChangeTextAreaValue,
        counterGap,
        require
    } = props
    // Utility
    const isAMessage = Math.ceil(textAreaValue.length / 160)

    // Static Tooltip data
    const rules = {
        title: 'Rules for Dynamic Messages',
        details: [
            'Enclose all variables/fields in “<>” signs.  ',
            'All name and number of variables should match the provided fields in the csv file.'
        ]
    }
    const notes = {
        title: 'Notes',
        details: [
            'If there are extra variables or incorrect variable name, all messages will not be sent.'
        ]
    }

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                gap={1}
                mb={2}
            >
                <Box display="flex">
                    <Typography variant="h4" style={{ marginRight: '10px' }}>
                        {commonTextAreaContent.type}
                        <ToolTip
                            rules={rules}
                            notes={notes}
                            tooltipSX={{ width: '25rem' }}
                            textBodySX={{ fontWeight: 200 }}
                        />
                    </Typography>
                    <Typography variant="h5" fontStyle="italic">
                        {commonTextAreaContent.guide}
                    </Typography>
                </Box>
                <Box display="flex" gap={counterGap}>
                    <Box display="flex">
                        <Typography variant="h4" fontWeight="normal">
                            {commonTextAreaContent.charactersUsed}
                        </Typography>
                        <Typography variant="h4">
                            {textAreaValue.length}
                        </Typography>
                    </Box>
                    <Box display="flex">
                        <Typography variant="h4" fontWeight="normal">
                            {commonTextAreaContent.messagedUsed}
                        </Typography>
                        <Typography variant="h4">{isAMessage}</Typography>
                    </Box>
                </Box>
            </Grid>
            <Box mb={1}>
                <TextInput
                    fullWidth
                    multiline
                    {...(require ? { require } : {})}
                    rows={rows}
                    placeholder={placeholder}
                    value={textAreaValue}
                    onChange={onChangeTextAreaValue}
                    inputProps={{ maxLength: commonTextAreaContent.limit }}
                    InputProps={{
                        endAdornment: (
                            <Box mt={10}>
                                <InputAdornment position="end">
                                    <Typography fontStyle="italic" variant="h5">
                                        {textAreaValue.length}/
                                        {commonTextAreaContent.limit}
                                    </Typography>
                                </InputAdornment>
                            </Box>
                        )
                    }}
                />
            </Box>
        </>
    )
}

export default CommonTextArea
