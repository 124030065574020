// Absolute
import { FC, FormEvent, useState } from 'react'
import 'react-toastify/dist/ReactToastify.css'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
// Relative
import {
    StyledMainBackgroundImage,
    StyledMainContainer,
    StyledMainContent
} from './login.styled'
import TextInput from 'components/global/TextInput'
import Button from 'components/global/Button'
import { login } from 'api/login'
import { getRole, getUser, isAuthorized, setToken, setUser } from 'utils/token'
import { AccountRoles } from 'utils/app.const'

const Login: FC = (): JSX.Element => {
    // Hooks
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<string | null>(null)

    // Handlers
    const handleLogIn = async (e: FormEvent) => {
        // TODO: Implement login logic/api integration
        // Simulate success redirect to dashboard
        e.preventDefault()

        try {
            const res = await login({ username, password })
            const { token, ...details } = res.data
            setUser(details)
            setToken(token)
            if (isAuthorized(AccountRoles.CLIENT)) {
                navigate(`${process.env.PUBLIC_URL}/home`)
            } else {
                setError('User Account unauthorized.')
            }
        } catch (error: any) {
            setError(error.message)
        }
    }

    const loginDisabled = (): boolean => {
        return username.length === 0 || password.length === 0
    }

    return (
        <StyledMainContainer>
            <StyledMainBackgroundImage />
            <StyledMainContent>
                <Box>
                    <Box>
                        <img src={require('assets/images/Logo.png')} alt="" />
                    </Box>
                    <Box mb={5}>
                        <Typography variant="h2" color="primary">
                            LOG IN
                        </Typography>
                    </Box>
                    <Box>
                        <form onSubmit={handleLogIn}>
                            <Box mb={3}>
                                <Typography
                                    variant="subtitle1"
                                    mb={1}
                                    fontWeight={500}
                                >
                                    Email Address
                                </Typography>
                                <TextInput
                                    autoFocus
                                    required
                                    type="email"
                                    fullWidth
                                    value={username}
                                    error={error !== null}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    onClick={(e) => setError(null)}
                                />
                            </Box>
                            <Box mb={3}>
                                <Typography
                                    variant="subtitle1"
                                    mb={1}
                                    fontWeight={500}
                                >
                                    Password
                                </Typography>
                                <TextInput
                                    required
                                    fullWidth
                                    type="password"
                                    value={password}
                                    error={error !== null}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    onClick={(e) => setError(null)}
                                />
                            </Box>
                            <Box>
                                <Button
                                    fullWidth
                                    type="submit"
                                    disabled={loginDisabled()}
                                >
                                    LOG IN
                                </Button>
                                {error && (
                                    <Typography
                                        mt={2}
                                        color="red"
                                        fontSize={12}
                                        align="center"
                                    >
                                        {error}
                                    </Typography>
                                )}
                            </Box>
                        </form>
                    </Box>
                </Box>
            </StyledMainContent>
        </StyledMainContainer>
    )
}

export default Login
