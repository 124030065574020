import { useState } from 'react'
import { IEditClientProps } from './editClient.interface'
import { IClientForm } from '../_components/ClientForm/clientform.interface'
import ClientForm from '../_components/ClientForm'
import { editClient } from 'api/clients/editClient'
import useToast from 'utils/useToast'

const EditClient = (props: IEditClientProps) => {
    const { selectedClient, handleModalClose, isReadOnly } = props

    const initialFormState = {
        id: selectedClient.id,
        username: selectedClient.username,
        password: selectedClient.password,
        confirm_password: selectedClient.password,
        company_name: selectedClient.company_name,
        company_address: selectedClient.company_address,
        contact_name: selectedClient.contact_name,
        contact_title: selectedClient.contact_title,
        contact_mobile: selectedClient.contact_mobile,
        contact_email: selectedClient.contact_email
    }

    const [formData, setFormData] = useState<IClientForm>(initialFormState)

    const handleEditClient = async () => {
        try {
            const res: any = await editClient({
                id: formData.id,
                username: formData.username,
                password: formData.password,
                company_name: formData.company_name,
                company_address: formData.company_address,
                contact_name: formData.contact_name,
                contact_title: formData.contact_title,
                contact_mobile: formData.contact_mobile,
                contact_email: formData.contact_email
            })

            if (res.status === 200) {
                handleModalClose()
                useToast().success("Editing client's details was successful.")
            } else {
                useToast().error(res.response.data.message)
            }
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    return (
        <ClientForm
            formTitle="Edit Details"
            formData={formData}
            setFormData={setFormData}
            handleModalClose={handleModalClose}
            isReadOnly={isReadOnly}
            confirmationText="Are you sure you want to edit client's details?"
            handleSubmit={handleEditClient}
        />
    )
}

export default EditClient
