import moment from 'moment'
import { REGEX } from './app.const'
import { IGroups } from './app.interface'

export const modifyResponseDate = (data: Array<IGroups>) => {
    return data.map(
        (item: any): IGroups => ({
            ...item,
            date_created: moment(item.date_created).format(
                'MM/DD/YYYY, h:mm A'
            ),
            last_used: moment(item.last_used).format('MM/DD/YYYY, h:mm A')
        })
    )
}

export const hasBlank = (data: Array<any>) => {
    const lengths = data.map((item) => item.length)
    return lengths.includes(0)
}

export const isValidNumber = (number: string) => {
    return REGEX.MOBILE_NUMBER_FORMAT.test(number)
}
