// Absolute
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Relative
import Step1 from './step1'
import Step2 from './step2'
import Step3 from './step3'
import Step4 from './step4'

import { IScheduledHistoryResponse } from 'api/scheduled/scheduledHistory'
import { getSenderNames } from 'api/senderNames/sender'
import moment from 'moment'
import { IGroups, ISenderName } from 'utils/app.interface'
import { getUser } from 'utils/token'
import useToast from 'utils/useToast'
import sendCSVScheduledEdit from 'api/scheduled/sendCSVScheduledEdit'
import smsQueueScheduledList from 'api/scheduled/smsQueueScheduledList'
import sendEdited from 'api/scheduled/sendEdited'

interface IEditScheduled {
    back: () => void
    clickedHistory: IScheduledHistoryResponse
}
function EditScheduled(props: IEditScheduled) {
    // Hook
    const navigate = useNavigate()
    const [step, setStep] = useState<number>(1)
    const [text, setText] = useState(props.clickedHistory.message)
    const [campaign, setCampaign] = useState(props.clickedHistory.campaign_name)
    // const [sender, setSender] = useState<ISenderName | null>(null)
    const [phoneNumbers, setPhoneNumbers] = useState<string[]>([]) //  Phone numbers
    const [csvFile, setCsvFile] = useState(null)
    const [senderNames, setSenderNames] = useState<any[]>([])
    const [selectedGroup, setSelectedGroup] = useState<IGroups | null>(null)
    const [groupNumbers, setGroupNumbers] = useState<number | null>(null)
    const messageLength = Math.ceil(text.length / 160)
    const [date, setDate] = useState<Date | null>(
        new Date(props.clickedHistory.date_scheduled)
    )
    const [hour, setHour] = useState('12')
    const [minute, setMinute] = useState('00')
    const [meridiem, setMeridiem] = useState('AM')

    const sender = useRef<ISenderName | null>(null)

    const militaryTime = () => {
        const _militaryTime = hour
        if (!_militaryTime) return
        if (meridiem === 'PM') {
            return parseInt(_militaryTime) + 12
        }
        return _militaryTime
    }
    const scheduledTime =
        moment(date).format('YYYY-MM-DD ') +
        militaryTime() +
        ':' +
        minute +
        ':00'
    const momentedDate = moment(date).format('MMMM DD YYYY')
    const concatenatedTime = hour + ':' + minute + ' ' + meridiem

    const isScheduled = () => {
        if (date === null) {
            return null
        } else {
            return momentedDate + ', ' + concatenatedTime
        }
    }

    const getMobileNumbers = () => {
        try {
            const res = smsQueueScheduledList({ id: props.clickedHistory.id })
        } catch (e) {
            useToast().error('Cannot find mobile numbers.')
        }
    }

    const handleOnChangeDate = (newValue: any) => {
        setDate(newValue)
    }
    const handleOnChangeHour = (event: any) => {
        const {
            target: { value }
        } = event
        setHour(value)
    }
    const handleOnChangeMinute = (event: any) => {
        const {
            target: { value }
        } = event
        setMinute(value)
    }
    const handleOnChangeMeridiem = (event: any) => {
        const {
            target: { value }
        } = event
        setMeridiem(value)
    }

    useEffect(() => {
        getSenderNames({ id: getUser()!.id }).then((res) => {
            if (res.data) {
                sender.current =
                    res.data.find(
                        (sender) =>
                            sender.id === props.clickedHistory.sender_name_id
                    ) ?? null
                setSenderNames(res.data)
            }
        })

        if (senderNames != null) {
            sender.current = senderNames.find(
                (sender) => sender.id === props.clickedHistory.sender_name_id
            )
        }

        smsQueueScheduledList({ id: props.clickedHistory.id }).then((res) => {
            if (Array.isArray(res.data) && res != null) {
                setPhoneNumbers(res.data.map((data) => data.mobile))
            }
        })
    }, [])

    // Handler
    const handleHome = () => {
        navigate(`${process.env.PUBLIC_URL}/home`)
        setStep(1)
    }

    const handleOnChangeText = (event: any) => {
        const {
            target: { value }
        } = event
        setText(value)
    }
    const handleOnChangeCampaign = (event: any) => {
        const {
            target: { value }
        } = event
        setCampaign(value)
    }
    const handleOnSenderChange = (event: any) => {
        const {
            target: { value }
        } = event
        const result = senderNames.find((sender: any) => sender.id === value)
        if (result) sender.current = result
    }

    const handleSendNow = async () => {
        if (sender == null) throw Error('Sender not initialized.')
        const request = {
            id: props.clickedHistory.id,
            sender_name_id: sender.current!.id,
            group_id: selectedGroup ? parseInt(selectedGroup.id) : 0,
            mobile_number: phoneNumbers,
            campaign_name: campaign,
            campaign_status: 0,
            sender_name: sender.current!.sender_name,
            total_amount: messageLength,
            cost: messageLength,
            recipient_type: 0,
            message: text,
            date_scheduled: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }
        if (csvFile) {
            const data = new FormData()
            data.append('numset', csvFile[0])
            data.append('request', JSON.stringify(request))

            try {
                await sendCSVScheduledEdit({ form: data })
                setStep(4)
            } catch (error: any) {
                useToast().error(error.message)
            }
        } else {
            try {
                await sendEdited(request)
                setStep(4)
            } catch (error: any) {
                useToast().error(error.message)
            }
        }
    }

    const handleScheduledSend = async () => {
        if (sender == null) throw Error('Sender not initialized')
        const request = {
            id: props.clickedHistory.id,
            sender_name_id: sender.current!.id,
            group_id: selectedGroup ? parseInt(selectedGroup.id) : 0,
            mobile_number: phoneNumbers,
            campaign_name: campaign,
            campaign_status: 0,
            sender_name: sender.current!.sender_name,
            total_amount: messageLength,
            cost: messageLength,
            recipient_type: 0,
            message: text,
            date_scheduled: scheduledTime.toString()
        }
        if (csvFile) {
            const data = new FormData()
            data.append('numset', csvFile[0])
            data.append('request', JSON.stringify(request))

            try {
                await sendCSVScheduledEdit({ form: data })
                setStep(4)
            } catch (error: any) {
                useToast().error(error.message)
            }
        } else {
            try {
                await sendEdited(request)
                setStep(4)
            } catch (error: any) {
                useToast().error(error.message)
            }
        }
    }
    const handleReset = () => {
        setDate(null)
        setHour('12')
        setMinute('00')
        setMeridiem('AM')
    }
    // HOC
    if (step === 1) {
        return (
            <Step1
                sendNow={() => setStep(2)}
                campaignName={campaign}
                senderName={sender.current!}
                onSenderChange={handleOnSenderChange}
                setMessage={setText}
                senderNames={senderNames}
                onChangeCampaignName={handleOnChangeCampaign}
                message={text}
                onMessageChange={handleOnChangeText}
                setCampaignName={setCampaign}
                back={props.back}
                clickedHistory={props.clickedHistory}
            />
        )
    } else if (step === 2) {
        return (
            <Step2
                onPreview={() => setStep(3)}
                setPhoneValueNumbersProps={setPhoneNumbers}
                phoneValueNumbersProps={phoneNumbers}
                csvFile={csvFile}
                setCsvFile={setCsvFile}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                setGroupNumbers={setGroupNumbers}
            />
        )
    } else if (step === 3) {
        return (
            <Step3
                detailProps={{
                    campaignName: campaign,
                    mobileNumber:
                        groupNumbers?.toString() ||
                        phoneNumbers.length.toString(),
                    senderName: props.clickedHistory.sender_name,
                    message: text,
                    group: selectedGroup?.name,
                    paymentMethod: 'Wallet Credits',
                    paymentStatus: 'Pending'
                }}
                buttonProps={{
                    onSendNow: handleSendNow,
                    onEdit: () => setStep(1),
                    onScheduled: handleScheduledSend,
                    onReset: handleReset
                }}
                scheduledProps={{
                    date,
                    hour,
                    minute,
                    meridiem,
                    onDateChange: handleOnChangeDate,
                    onHourChange: handleOnChangeHour,
                    onMinuteChange: handleOnChangeMinute,
                    onMeridiemChange: handleOnChangeMeridiem
                }}
            />
        )
    } else if (step === 4) {
        return (
            <Step4
                detailProps={{
                    campaignName: campaign,
                    mobileNumber:
                        groupNumbers?.toString() ||
                        phoneNumbers.length.toString(),
                    senderName: props.clickedHistory.sender_name, // Need Login Credentials
                    message: text,
                    group: selectedGroup?.name,
                    paymentMethod: 'Wallet Credits',
                    paymentStatus: 'Completed',
                    scheduled: isScheduled()?.toString()
                }}
                buttonProps={{
                    onBackToMain: handleHome
                }}
            />
        )
    } else {
        return (
            <Step1
                sendNow={() => setStep(2)}
                campaignName={campaign}
                setCampaignName={setCampaign}
                senderName={sender.current!}
                setMessage={setText}
                onSenderChange={handleOnSenderChange}
                onChangeCampaignName={handleOnChangeCampaign}
                message={text}
                onMessageChange={handleOnChangeText}
                senderNames={senderNames}
                back={props.back}
                clickedHistory={props.clickedHistory}
            />
        )
    }
}
export default EditScheduled
