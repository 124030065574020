import React, { FC } from 'react'
import { Typography, Box } from '@mui/material'

import { ICommonConfirmationProps } from './commonConfirmation.interface'
import CommonModal from '../commonModal'

const CommonConfirmation: FC<ICommonConfirmationProps> = (
    props: ICommonConfirmationProps
) => {
    const { open, close, confirmationText, children } = props
    return (
        <>
            <CommonModal open={open} close={close}>
                <>
                    <Box sx={{ width: 580 }}>
                        <Typography variant="h2" textAlign="center" mb={5}>
                            {confirmationText}
                        </Typography>
                        <Box display="flex" justifyContent="center" gap={5}>
                            {children}
                        </Box>
                    </Box>
                </>
            </CommonModal>
        </>
    )
}

export default CommonConfirmation
