import { privateClient } from 'api/interceptor/request.interceptor'

export const addClient = async (reqPayload: {
    username?: string
    password: string
    company_name: string
    company_address: string
    contact_name: string
    contact_title: string
    contact_mobile: string
    contact_email: string
    role?: string
}) => {
    try {
        return await privateClient({
            url: '/account/register',
            method: 'POST',
            data: reqPayload
        })
    } catch (error) {
        return error
    }
}
