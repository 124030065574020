import { privateClient } from 'api/interceptor/request.interceptor'

export const getSender = async (param: { id: number }) => {
    try {
        return await privateClient({
            url: `/sender/names?id=${param.id}`,
            method: 'GET'
        })
    } catch (error) {
        return error
    }
}
