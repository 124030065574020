import { ITableColumn } from 'components/global/commonTable/commonTable.interface'
import { StyledButton } from '../campaignsTable/campaignsTable.styled'
import { IScheduledHistoryData } from './scheduled.interface'
import { StyledScheduledButtonContainer } from './scheduledHistoryTable/scheduledHistoryTable.styled'

export const scheduledColumns: ITableColumn<IScheduledHistoryData>[] = [
    {
        columnLabel: 'Date Created',
        columnName: 'dateCreated'
    },
    {
        columnLabel: 'Date of Blast',
        columnName: 'dateOfBlast'
    },
    {
        columnLabel: 'Campaign Name',
        columnName: 'campaignName'
    },
    {
        columnLabel: 'Campaign Type',
        columnName: 'campaignType',
        sx: {
            maxWidth: '5rem'
        }
    },
    {
        columnLabel: '',
        columnName: 'buttons',
        sx: {
            minWidth: '8rem'
        }
    }
]
