import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { Box, Typography } from '@mui/material'
import TextInput from '../TextInput/TextInput'
import { IAddSenderFormProps } from './senderForm.interface'
import {
    StyledAdminButton,
    StyledAdminFilledButton,
    StyledButtonFormContainer
} from 'admin/clients/clients.styled'
import { senderFormSchema } from './senderForm.const'
import CommonConfirmation from 'components/global/commonConfirmation'

const AddSenderForm = (props: IAddSenderFormProps) => {
    const [showConfirmation, setShowConfirmation] = useState(false)

    return (
        <Box>
            <Typography variant="h3" textAlign="center" mb={4}>
                {props.isReadOnly ? 'Sender Details' : props.formTitle}
            </Typography>
            <Formik
                initialValues={props.formData}
                validationSchema={senderFormSchema}
                onSubmit={(values) => {
                    props.setFormData(values)
                    setShowConfirmation(true)
                }}
            >
                <Form>
                    <Box display="flex" gap={2}>
                        <TextInput
                            fullWidth
                            required
                            label="Sender name"
                            name="sender_name"
                        />
                    </Box>
                    <Box display="flex" gap={2} mb={4}>
                        <TextInput
                            required
                            label="Client ID"
                            name="client_id"
                        />
                        <TextInput
                            required
                            label="Password"
                            name="client_password"
                            type="password"
                        />
                        <TextInput
                            required
                            label="Confirm Password"
                            name="confirm_password"
                            type="password"
                        />
                    </Box>

                    <StyledButtonFormContainer>
                        <StyledAdminButton onClick={props.handleModalClose}>
                            Cancel
                        </StyledAdminButton>
                        <StyledAdminFilledButton type="submit">
                            Save
                        </StyledAdminFilledButton>
                    </StyledButtonFormContainer>

                    {/* __ cONFIRMATION MODAL __ */}
                    <CommonConfirmation
                        open={showConfirmation}
                        close={() => setShowConfirmation(false)}
                        confirmationText={props.confirmationText}
                    >
                        <Box
                            display="flex"
                            justifyContent="center"
                            columnGap={2}
                        >
                            <StyledAdminButton
                                onClick={() => {
                                    setShowConfirmation(false)
                                }}
                            >
                                No
                            </StyledAdminButton>
                            <StyledAdminFilledButton
                                onClick={() => props.handleSubmit()}
                            >
                                Yes
                            </StyledAdminFilledButton>
                        </Box>
                    </CommonConfirmation>
                </Form>
            </Formik>
        </Box>
    )
}

export default AddSenderForm
