import { privateClient } from 'api/interceptor/request.interceptor'
import React from 'react'

type sendEditedReqPayload = {
    id: number
    sender_name_id: number
    group_id: number
    mobile_number: string[]
    campaign_name: string
    campaign_status: number
    sender_name: string
    total_amount: number
    cost: number
    recipient_type: number
    message: string
    date_scheduled: string
}

async function sendEdited(reqPayload: sendEditedReqPayload) {
    return await privateClient({
        url: 'sender/send/edit',
        data: reqPayload,
        method: 'PATCH'
    })
}

export default sendEdited
