import { privateClient } from '../interceptor/request.interceptor'

export const getMembers = async (reqPayload: {
    groupId: string
    limit: number
    page: number
    search: string
}) => {
    try {
        return await privateClient({
            url: '/members',
            method: 'GET',
            params: {
                ...reqPayload,
                order: 'desc',
                columnName: 'id'
            }
        })
    } catch (error) {
        return error
    }
}
