import { Typography } from '@mui/material'
import React, { FC } from 'react'

const FAQ: FC = () => {
    return (
        <div>
            <Typography variant="h2" mb={5}>
                Frequently Asked Questions
            </Typography>
            <Typography variant="h3" mb={3}>
                SMS Blast
            </Typography>
            <Typography variant="h4" mb={2}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </Typography>
            <Typography variant="h5" mb={5}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                fermentum dui at libero venenatis, at hendrerit libero
                condimentum. Pellentesque venenatis turpis eget aliquam congue.
                Duis quis purus nibh. Mauris eu gravida augue. Sed nec diam
                neque. Suspendisse lacus lectus, luctus a orci id, rutrum
                elementum est. Nam non mauris in mauris molestie ultricies.
                Fusce placerat interdum diam non ultricies. Suspendisse eget
                nibh est.
            </Typography>
            <Typography variant="h4" mb={2}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </Typography>
            <Typography variant="h5" mb={5}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                fermentum dui at libero venenatis, at hendrerit libero
                condimentum. Pellentesque venenatis turpis eget aliquam congue.
                Duis quis purus nibh. Mauris eu gravida augue. Sed nec diam
                neque. Suspendisse lacus lectus, luctus a orci id, rutrum
                elementum est. Nam non mauris in mauris molestie ultricies.
                Fusce placerat interdum diam non ultricies. Suspendisse eget
                nibh est.
            </Typography>
            <Typography variant="h3" mb={3}>
                Load
            </Typography>
            <Typography variant="h4" mb={2}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </Typography>
            <Typography variant="h5" mb={5}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                fermentum dui at libero venenatis, at hendrerit libero
                condimentum. Pellentesque venenatis turpis eget aliquam congue.
                Duis quis purus nibh. Mauris eu gravida augue. Sed nec diam
                neque. Suspendisse lacus lectus, luctus a orci id, rutrum
                elementum est. Nam non mauris in mauris molestie ultricies.
                Fusce placerat interdum diam non ultricies. Suspendisse eget
                nibh est.
            </Typography>
            <Typography variant="h4" mb={2}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit?
            </Typography>
            <Typography variant="h5" mb={5}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                fermentum dui at libero venenatis, at hendrerit libero
                condimentum. Pellentesque venenatis turpis eget aliquam congue.
                Duis quis purus nibh. Mauris eu gravida augue. Sed nec diam
                neque. Suspendisse lacus lectus, luctus a orci id, rutrum
                elementum est. Nam non mauris in mauris molestie ultricies.
                Fusce placerat interdum diam non ultricies. Suspendisse eget
                nibh est.
            </Typography>
        </div>
    )
}

export default FAQ
