import { publicClient } from './interceptor/request.interceptor'

export const login = async (reqPayload: {
    username: string
    password: string
}) => {
    try {
        return await publicClient({
            data: reqPayload,
            url: '/account/signin',
            method: 'post'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}
