import React from 'react'
import { StandardTextFieldProps } from '@mui/material'
import { useField, FieldHookConfig } from 'formik'
import { StyledTextInput } from './TextInput.styled'

type ITextInput = {
    label: React.ReactNode
    flex?: number
    name?: string
    id?: string
} & StandardTextFieldProps

const TextInput = (props: ITextInput & FieldHookConfig<any>) => {
    const [field, meta] = useField(props)

    return (
        <StyledTextInput
            {...field}
            {...props}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            InputProps={{
                disableUnderline: true,
                autoComplete: 'new-password'
            }}
            margin="dense"
            sx={{ flex: `${props.flex}` }}
            error={meta.touched && Boolean(meta.error)}
            helperText={meta.touched && meta.error}
        />
    )
}

export default TextInput
