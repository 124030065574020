// Absolute
import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'

const Saved: FC = () => {
    return (
        <>
            <Outlet />
        </>
    )
}
export default Saved
