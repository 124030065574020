import { Chip } from '@mui/material'
import { styled } from '@mui/system'
import TextInput from 'components/global/TextInput'

export const StyledChip = styled(Chip)({
    background: 'none',
    border: 'none',
    width: '100%',
    display: 'flex',
    justifyContent: 'start'
})
export const StyledUpload = styled('button')({
    border: '2px dashed #1E20714D',
    borderRadius: '10px',
    height: '11.813rem',
    cursor: 'pointer',
    background: '#E6E8EE',
    width: '100%',
    position: 'relative',
    overflow: 'hidden'
})
export const StyledTextInput = styled(TextInput)({
    maxHeight: '11.813rem',
    overflowY: 'auto',
    height: '100%'
})

export const StyledComingSoonOverlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '120%',
    minHeight: '2em',
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'rotate(-13deg)',
    color: theme.palette.common.white,
    fontSize: '1.3rem',
    fontWeight: 500,
    opacity: 0.73
}))
