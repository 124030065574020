import { privateClient } from 'api/interceptor/request.interceptor'

export const editMember = async (
    param: {
        memberId: string
        groupId: string
    },
    payload: {
        first_name?: string
        last_name?: string
        mobile_number?: string
        email_address?: string | null
        birthday?: string | null
        gender?: string
    }
) => {
    try {
        return await privateClient({
            url: `/members/${param.memberId}`,
            method: 'PATCH',
            params: {
                groupId: param.groupId
            },
            data: payload
        })
    } catch (error) {
        return error
    }
}
