import React, { ChangeEvent } from 'react'
import Container from '@mui/material/Container'
import { Box, Paper } from '@mui/material'
import background from '../Phoneframe/frame/blue.png'
export interface IPhoneFrame {
    msg?: string
    multiline?: boolean
    onMessageChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const PhoneFrame: React.FC<IPhoneFrame> = (props: IPhoneFrame) => {
    return (
        <Box>
            <Container maxWidth="lg">
                <Box
                    height={'80vh'}
                    width={'20vw'}
                    style={{
                        backgroundImage: `url(${background})`,
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundPosition: 'center',
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat',
                        position: 'relative',
                        top: '20%'
                    }}
                >
                    <Paper
                        elevation={0}
                        style={{
                            padding: '1rem',
                            position: 'absolute',
                            maxWidth: '75%',
                            maxHeight: '80%',
                            top: '15%',
                            left: '15%',
                            fontSize: 10,
                            wordBreak: 'break-word'
                        }}
                    >
                        {props.msg}
                    </Paper>
                </Box>
            </Container>
        </Box>
    )
}
export { PhoneFrame }

// DON'T DELETE THIS @jordan
// export const Portal = () => {
//     return (
//         <React.Fragment>
//             {ReactDOM.createPortal(
//                 <div>Overlay</div>,
//                 document.getElementById('phone-framework') as HTMLElement | null,
//             )}
//     </React.Fragment>
//     )
// }
