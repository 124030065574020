import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Typography } from '@mui/material'
import { StyledButton } from '../scheduledHistoryTable.styled'
import { ISortDateFilter } from '../scheduledHistoryTable.interface'

type Props = {
    handleClick: (event: any) => void
    sortDateFilter: ISortDateFilter
    label?: string
    open: boolean
}

const SortByDateDropDown = ({ handleClick, sortDateFilter, open }: Props) => {
    const id = open ? 'simple-popover' : undefined

    return (
        <>
            <Typography variant="h6">Sort By</Typography>
            <StyledButton
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
            >
                {sortDateFilter}
            </StyledButton>
        </>
    )
}

export default SortByDateDropDown
