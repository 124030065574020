import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const StyledSidebarLayoutContainer = styled('div')({})

export const StyledSidebarLayoutMainContent = styled(Box)(({ theme }) => ({
    minHeight: '100vh',
    flexGrow: 1,
    width: 'calc(100% -240px)',
    background: theme.palette.mainBg.main
}))
