export const clientsColumms = [
    {
        columnLabel: 'Client ID',
        columnName: 'id' as const,
        colSpan: 2
    },
    {
        columnLabel: 'Company Name',
        columnName: 'company_name' as const,
        colSpan: 2
    }
]
