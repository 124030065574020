import React from 'react'

const Page404 = () => {
    return (
        <>
            <h1>Page Not Found</h1>
            <button onClick={() => history.back()}>Go Back</button>
        </>
    )
}

export default Page404
