// Absolute
import { SearchOff } from '@mui/icons-material'
import {
    TableBody,
    TableCell,
    TableHead,
    TableContainer,
    Paper,
    Table,
    Typography
} from '@mui/material'
import { FC } from 'react'

// Relative
import {
    ICommonTableProps,
    ITableColumn,
    TRender
} from './commonTable.interface'
import {
    StyledHeaderTableRow,
    StyledBodyTableRow,
    StyledHeaderTypography,
    StyledBodyTypography,
    StyledNoDataContainer
} from './commonTable.styled'

import CommonPagination from '../commonPagination'

function CommonTable<T>(props: ICommonTableProps<T>) {
    const {
        columns,
        rows,
        noDataMessage = 'We cannot find what you are looking for',
        rowClick = () => {},
        dense,
        paginationPayload
    } = props

    const columnKeys = columns.map((column: ITableColumn<T>) => {
        if (column.render) return column.render
        return column.columnName
    })

    return (
        <>
            {Array.isArray(rows) && rows.length === 0 ? (
                <NoData msg={noDataMessage} />
            ) : (
                <TableContainer
                    component={Paper}
                    sx={{
                        borderRadius: '10px 10px 0px 0px',
                        cursor: 'pointer'
                    }}
                >
                    <Table size={dense ? 'small' : 'medium'}>
                        <TableHead>
                            <StyledHeaderTableRow>
                                {columns.map((headerItem) => (
                                    <TableCell
                                        align="center"
                                        key={headerItem.columnName}
                                        sx={{
                                            ...(headerItem.sx ?? headerItem.sx)
                                        }}
                                    >
                                        <StyledHeaderTypography variant="h5">
                                            {headerItem.columnLabel.toUpperCase()}
                                        </StyledHeaderTypography>
                                    </TableCell>
                                ))}
                            </StyledHeaderTableRow>
                        </TableHead>
                        <TableBody>
                            {rows &&
                                rows.map((rowItem, rowKey) => (
                                    <StyledBodyTableRow
                                        hover
                                        key={rowKey}
                                        onClick={() => rowClick(rowItem)}
                                    >
                                        {columnKeys.map(
                                            (
                                                column: TRender | keyof T,
                                                columnKey: number
                                            ) =>
                                                typeof column === 'function' ? (
                                                    <TableCell
                                                        align="center"
                                                        key={columnKey}
                                                    >
                                                        {column(rowItem)}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell
                                                        align="center"
                                                        key={columnKey}
                                                        sx={{
                                                            whiteSpace:
                                                                'normal',
                                                            wordWrap:
                                                                'break-word',
                                                            maxWidth: 150,
                                                            minWidth: 150
                                                        }}
                                                    >
                                                        <StyledBodyTypography
                                                            variant="h6"
                                                            fontWeight="normal"
                                                        >
                                                            {rowItem[column]}
                                                        </StyledBodyTypography>
                                                    </TableCell>
                                                )
                                        )}
                                    </StyledBodyTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {paginationPayload && <CommonPagination {...paginationPayload} />}
        </>
    )
}

const NoData: FC<{ msg: string }> = ({ msg }): JSX.Element => {
    return (
        <StyledNoDataContainer>
            <SearchOff fontSize="large" />
            <Typography variant="h3" mt={2.5}>
                {msg}
            </Typography>
        </StyledNoDataContainer>
    )
}

export default CommonTable
