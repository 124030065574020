export const pageSendContent = {
    title: 'Send SMS',
    campNameTitle: 'Campaign Name',
    terms: 'by sending this message, you hereby abide the terms and conditions of Xurpas Inc. in accordance to Data Privacy Act.'
}
export const temporarySendContent = {
    campName: 'Campaign ABC',
    textContent:
        "Good morning! Don't forget our 3-day kick off activities will commence this Friday. Please coordinate with your respective sales heads."
}
export const buttonContent = {
    saved: 'SAVED MESSAGES',
    send: 'SEND NOW'
}
export const summaryContents = [
    { key: 'campaignName', value: 'Campaign Name' },
    { key: 'mobileNumber', value: 'Mobile Number' },
    { key: 'group', value: 'Group' },
    { key: 'senderName', value: 'Sender Name' },
    { key: 'message', value: 'Message' }
]
export const SavedMessagesColumns = [
    {
        columnLabel: 'Title',
        columnName: 'title' as const
    },
    {
        columnLabel: 'Date Created',
        columnName: 'dateCreated' as const
    },
    {
        columnLabel: 'Message',
        columnName: 'message' as const
    },
    {
        columnLabel: 'Total Message Count',
        columnName: 'messageCount' as const
    }
]
