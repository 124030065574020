import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Grid,
    Button as MButton,
    Box,
    Typography,
    Button,
    IconButton,
    SelectChangeEvent,
    CircularProgress
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import CommonTable from 'components/global/commonTable'
import TextInput from 'components/global/TextInput'
import CommonModal from 'components/global/commonModal'
import { editGroup, getGroup } from 'api/groups'
import { getMembers } from 'api/members/getMember'
import { IGroups, IMembers } from 'utils/app.interface'
import useToast from 'utils/useToast'
import { GroupsEmployeesColumn } from '../../saved.const'
import SingleNumberModal from './singleNumber.ts'
import MultiNumber from './multiNumber'
import {
    addMultiNumbers,
    addMultiNumbersCSV,
    addSingleNumber
} from 'api/members/addMember'
import { IMembersProps } from './add.interface'
import { deleteMember } from 'api/members/deleteMember'
import CommonConfirmation from 'components/global/commonConfirmation'
import {
    defaultRowsPerPage,
    isCurrentPage,
    isEmpty,
    parseIntConfig
} from 'utils/app.const'
import { editMember } from 'api/members/editMember'
import Search from 'components/global/Search'
import moment from 'moment'

const CommonButton = require('components/global/Button').default
type Rows = Array<IMembers>

const AddMembersGroup: FC = () => {
    // Hooks
    const params = useParams()
    const navigate = useNavigate()

    // Hooks - Modals
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [addNewNumberModal, setAddNewNumberModal] = useState(false)
    const [singleNumberModal, setSingleNumberModal] = useState(false)
    const [multipleNumberModal, setMultipleNumberModal] = useState(false)
    const [editing, setEditing] = useState(false)
    const [editingMember, setEditingMember] = useState(false)

    // Hooks - Data
    const [members, setMembers] = useState<Rows>([])
    const [group, setGroup] = useState<IGroups | null>(null)
    const [selectedMember, setSelectedMember] = useState<IMembers | null>(null)

    // Hooks - Add Single Number Member
    const [lastName, setLastName] = useState('')
    const [firstName, setFirstName] = useState('')
    const [contactNumber, setContactNumber] = useState('')
    const [email, setEmail] = useState('')
    const [birthYear, setBirthYear] = useState('')
    const [birthMonth, setBirthMonth] = useState('')
    const [birthDay, setBirthDay] = useState('')
    const [genderMember, setGenderMember] = useState('')

    // Hooks - Add Multiple Numbers
    const [phoneNumbers, setPhoneNumbers] = useState<string[]>([])
    const [csvFile, setCsvFile] = useState(null)

    // Hooks - Editing (Group Name)
    const [newName, setNewName] = useState('')

    // Hooks - Editing (Member Details)
    const [newLastName, setNewLastName] = useState('')
    const [newFirstName, setNewFirstName] = useState('')
    const [newContactNumber, setNewContactNumber] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [newBirthYear, setNewBirthYear] = useState('')
    const [newBirthMonth, setNewBirthMonth] = useState('')
    const [newBirthDay, setNewBirthDay] = useState('')
    const [newGenderMember, setNewGenderMember] = useState('')

    // Hooks - Utilities
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(0)
    const [recordLength, setRecordLength] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
    const [error, setError] = useState(null)

    // Constant Variables
    const birthDate = birthYear + birthMonth + birthDay
    const newBirthDate = `${newBirthYear}/${newBirthMonth}/${newBirthDay}`
    const GroupID = params.id!

    const handleChangePage = (
        event: ChangeEvent<unknown> | null,
        newPage: number
    ) => {
        setPage(newPage - 1)
    }

    const handleChangeRowsPerPage = (event: SelectChangeEvent<string>) => {
        setRowsPerPage(parseInt(event.target.value, parseIntConfig))
        setPage(0)
    }

    const paginationPayload = {
        currentPage: page,
        currentLimit: rowsPerPage,
        total: recordLength,
        onPageChange: handleChangePage,
        handleOnPageSizeChange: handleChangeRowsPerPage,
        showOption: true
    }

    const MemberID = selectedMember?.id!
    useEffect(() => {
        if (GroupID) {
            getGroup({ id: GroupID })
                .then((res: any) => {
                    setGroup(res.data)
                    setNewName(res.data.name)
                })
                .catch((err) => {
                    useToast().error(err.message)
                })
        }
    }, [])

    useEffect(() => {
        getGroupMembers()
    }, [page, rowsPerPage])

    useEffect(() => {
        if (isEmpty(search)) {
            getGroupMembers()
        }
    }, [search])

    const getGroupMembers = async () => {
        try {
            const res: any = await getMembers({
                groupId: GroupID,
                limit: rowsPerPage,
                page: isCurrentPage(page, search),
                search
            })
            setMembers(res.data.data)
            setRecordLength(res.data.total)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }
    // Handlers
    const handleOpenAddNewNumberModal = () => setAddNewNumberModal(true)
    const handleCloseAddNewNumberModal = () => setAddNewNumberModal(false)
    const handleOpen = () => setSingleNumberModal(true)
    const handleClose = () => setSingleNumberModal(false)

    const handleCloseEditingModal = () => {
        setEditingMember(false)
        setSelectedMember(null)
    }
    const handleOpenAddNewMultipleNumberModal = () =>
        setMultipleNumberModal(true)
    const handleCloseAddNewultipleNumberModal = () =>
        setMultipleNumberModal(false)
    const handleConfirmationClose = () => {
        setSelectedMember(null)
        setShowConfirmation(false)
    }
    const handleAddSingleNumber = async (event: FormEvent) => {
        event.preventDefault()
        try {
            const res: any = await addSingleNumber(
                {
                    group_id: GroupID,
                    last_name: lastName || 'N/A',
                    first_name: firstName || 'N/A',
                    mobile_number: contactNumber,
                    email_address: email || null,
                    gender: genderMember || 'N/A',
                    birthday: birthDate || null
                },
                {
                    id: GroupID
                }
            )
            setAddNewNumberModal(false)
            setSingleNumberModal(false)
            getGroupMembers()
            setLastName('')
            setFirstName('')
            setContactNumber('')
            setEmail('')
            setBirthYear('')
            setBirthMonth('')
            setBirthDay('')
            setGenderMember('')
            useToast().success(res.data.message)
        } catch {
            const res: any = await addSingleNumber(
                {
                    group_id: GroupID,
                    last_name: lastName || 'N/A',
                    first_name: firstName || 'N/A',
                    mobile_number: contactNumber,
                    email_address: email || null,
                    gender: genderMember || 'N/A',
                    birthday: birthDate || null
                },
                {
                    id: GroupID
                }
            )
            useToast().error(res.response.data.message)
        }
    }

    const handleAddMultiple = async (event: FormEvent) => {
        event.preventDefault()
        if (csvFile) {
            const data = new FormData()
            data.append('numset', csvFile[0])
            try {
                const res: any = await addMultiNumbersCSV({
                    numset: data,
                    id: GroupID
                })
                setAddNewNumberModal(false)
                setMultipleNumberModal(false)
                setPhoneNumbers([])
                getGroupMembers()
                useToast().success(res.data.message)
            } catch (error: any) {
                useToast().error(error.message)
            }
        } else {
            try {
                const res: any = await addMultiNumbers(phoneNumbers, {
                    id: GroupID
                })
                setAddNewNumberModal(false)
                setMultipleNumberModal(false)
                setPhoneNumbers([])
                getGroupMembers()
                useToast().success(res.data.message)
            } catch {
                const res: any = await addMultiNumbers(phoneNumbers, {
                    id: GroupID
                })
                useToast().error(res.response.data.message)
            }
        }
    }

    const handleEditSingleMember = async (event: FormEvent) => {
        if (!selectedMember) return
        event.preventDefault()
        try {
            const res: any = await editMember(
                {
                    memberId: MemberID,
                    groupId: GroupID
                },
                {
                    last_name: newLastName || 'N/A',
                    first_name: newFirstName || 'N/A',
                    mobile_number: newContactNumber,
                    email_address: newEmail || null,
                    gender: newGenderMember || 'N/A',
                    birthday: newBirthDate || null
                }
            )
            getGroupMembers()
            setEditingMember(false)
            useToast().success(res.data.message)
        } catch {
            const res: any = await editMember(
                {
                    memberId: MemberID,
                    groupId: GroupID
                },
                {
                    last_name: newLastName || 'N/A',
                    first_name: newFirstName || 'N/A',
                    mobile_number: newContactNumber,
                    email_address: newEmail || null,
                    gender: newGenderMember || 'N/A',
                    birthday: newBirthDate || null
                }
            )
            useToast().error(res.response.data.message)
        }
    }

    const handleOpenEditingModal = (member: IMembers) => {
        setEditingMember(true)
        setSelectedMember(member)
        setNewLastName(member.last_name)
        setNewFirstName(member.first_name)
        setNewContactNumber(member.mobile_number)
        setNewEmail(member.email_address)
        setNewBirthYear(
            new Date(parseInt(member.birthday)).getFullYear().toString()
        )
        setNewBirthMonth(
            new Date(parseInt(member.birthday)).getMonth().toString()
        )
        setNewBirthDay(new Date(parseInt(member.birthday)).getDate().toString())
        setNewGenderMember(member.gender)
    }

    if (!group) {
        return (
            <div>
                <CircularProgress />
            </div>
        )
    }
    const selectDelete = (member: IMembers) => {
        setSelectedMember(member)
        setShowConfirmation(true)
    }
    const handleDelete = async () => {
        if (!selectedMember) return
        try {
            await deleteMember({
                memberId: MemberID,
                groupId: GroupID
            })
            useToast().success('Delete success')
            getGroupMembers()
            setShowConfirmation(false)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    const handleEditGroupname = () => {
        setEditing(true)
    }

    const handleEditCancel = () => {
        setEditing(false)
    }

    const handleSaveGroupname = async (e: FormEvent) => {
        e.preventDefault()
        try {
            await editGroup({ id: parseInt(GroupID), name: newName })
            setGroup({ ...group, name: newName })
            useToast().success('Group name has been successfully edited')
        } catch (error: any) {
            setError(error)
        } finally {
            setEditing(false)
        }
    }

    const handleSearch = (e: FormEvent) => {
        e.preventDefault()
        getGroupMembers()
    }

    return (
        <>
            <Grid container gap={2} mb={2} alignItems="center">
                <Typography variant="h2" color="primary">
                    {group.name}
                </Typography>

                <IconButton onClick={handleEditGroupname}>
                    <EditIcon />
                </IconButton>
            </Grid>
            <Grid
                container
                gap={2}
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Grid item>
                    <MButton
                        startIcon={<AddIcon />}
                        size="small"
                        sx={{ background: '#FFF', padding: '5px 20px' }}
                        onClick={handleOpenAddNewNumberModal}
                    >
                        New Member
                    </MButton>
                </Grid>
                <Grid item justifySelf="flex-end" alignSelf="flex-end">
                    <Search
                        handleSearchChange={setSearch}
                        handleSubmit={handleSearch}
                    />
                </Grid>
            </Grid>
            <CommonTable
                columns={[
                    ...GroupsEmployeesColumn,
                    {
                        columnName: 'edit' as const,
                        columnLabel: '',
                        colSpan: 4,
                        render: (row: IMembersProps) => {
                            return (
                                <Button
                                    onClick={() => {
                                        handleOpenEditingModal(row)
                                    }}
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '20px',
                                        background: '#FFF'
                                    }}
                                >
                                    Edit
                                </Button>
                            )
                        }
                    },
                    {
                        columnName: 'delete' as const,
                        columnLabel: '',
                        colSpan: 4,
                        render: (row: IMembersProps) => {
                            return (
                                <Button
                                    onClick={() => selectDelete(row)}
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '20px',
                                        background: '#FFF'
                                    }}
                                >
                                    Delete
                                </Button>
                            )
                        }
                    }
                ]}
                rows={members}
                paginationPayload={paginationPayload}
            />
            {/* DELETE CONFIRMATION */}
            <CommonConfirmation
                open={showConfirmation}
                close={handleConfirmationClose}
                confirmationText="Warning!"
            >
                <Box>
                    <Typography align="center" color="red">
                        Are you sure you want to delete this member?
                    </Typography>

                    <Box display="flex" justifyContent="center" gap={5} mt={5}>
                        <CommonButton onClick={handleConfirmationClose}>
                            Cancel
                        </CommonButton>
                        <CommonButton onClick={handleDelete}>
                            Proceed
                        </CommonButton>
                    </Box>
                </Box>
            </CommonConfirmation>
            <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
                <CommonButton onClick={() => navigate(`/saved/groups`)}>
                    Go Back
                </CommonButton>
            </Box>

            {/* Insert Pagination Ui */}

            <CommonModal
                open={addNewNumberModal}
                close={handleCloseAddNewNumberModal}
            >
                <>
                    <Box>
                        <Typography variant="h2" color="primary" mb={3}>
                            Add New Member Via
                        </Typography>
                        <Box display="flex" gap={5}>
                            <CommonButton onClick={handleOpen}>
                                SINGLE NUMBER
                            </CommonButton>
                            <CommonButton
                                onClick={handleOpenAddNewMultipleNumberModal}
                            >
                                MULTIPLE NUMBER
                            </CommonButton>
                        </Box>
                    </Box>
                </>
            </CommonModal>

            <CommonModal
                open={multipleNumberModal}
                close={handleCloseAddNewultipleNumberModal}
            >
                <>
                    <MultiNumber
                        phoneValueNumbersProps={phoneNumbers}
                        setPhoneValueNumbersProps={setPhoneNumbers}
                        handleAddMultipleNumbers={handleAddMultiple}
                        csvFile={csvFile}
                        setCsvFile={setCsvFile}
                    />
                </>
            </CommonModal>

            <CommonModal open={singleNumberModal} close={handleClose}>
                <>
                    <SingleNumberModal
                        last_Name={lastName}
                        first_Name={firstName}
                        number={contactNumber}
                        email_address={email}
                        birth_year={birthYear}
                        birth_month={birthMonth}
                        birth_day={birthDay}
                        gender={genderMember}
                        handleAddMemberProp={handleAddSingleNumber}
                        handleLastNameProp={(e) => setLastName(e.target.value)}
                        handleFirstNameProp={(e) =>
                            setFirstName(e.target.value)
                        }
                        handleContactNumberProp={(e) =>
                            setContactNumber(e.target.value)
                        }
                        handleEmailProp={(e) => setEmail(e.target.value)}
                        handleBirthYearProp={(e) =>
                            setBirthYear(e.target.value as string)
                        }
                        handleBirthMonthProp={(e) =>
                            setBirthMonth(e.target.value as string)
                        }
                        handleBirthDayProp={(e) =>
                            setBirthDay(e.target.value as string)
                        }
                        handleGenderMemberProp={(e) =>
                            setGenderMember(e.target.value as string)
                        }
                        actionMember="Add New"
                    />
                </>
            </CommonModal>
            <CommonModal open={editingMember} close={handleCloseEditingModal}>
                <>
                    <SingleNumberModal
                        actionMember="Edit"
                        last_Name={newLastName}
                        first_Name={newFirstName}
                        number={newContactNumber}
                        email_address={newEmail}
                        birth_year={newBirthYear}
                        birth_month={newBirthMonth}
                        birth_day={newBirthDay}
                        gender={newGenderMember}
                        handleAddMemberProp={handleEditSingleMember}
                        handleLastNameProp={(e) =>
                            setNewLastName(e.target.value)
                        }
                        handleFirstNameProp={(e) =>
                            setNewFirstName(e.target.value)
                        }
                        handleContactNumberProp={(e) =>
                            setNewContactNumber(e.target.value)
                        }
                        handleEmailProp={(e) => setNewEmail(e.target.value)}
                        handleBirthYearProp={(e) =>
                            setNewBirthYear(e.target.value as string)
                        }
                        handleBirthMonthProp={(e) =>
                            setNewBirthMonth(e.target.value as string)
                        }
                        handleBirthDayProp={(e) =>
                            setNewBirthDay(e.target.value as string)
                        }
                        handleGenderMemberProp={(e) =>
                            setNewGenderMember(e.target.value as string)
                        }
                    />
                </>
            </CommonModal>
            <CommonModal open={editing} close={handleEditCancel}>
                <Box sx={{ width: 500 }} padding={1}>
                    <Box>
                        <form onSubmit={handleSaveGroupname}>
                            <Typography
                                variant="h2"
                                color="primary"
                                gutterBottom
                            >
                                New Group Name
                            </Typography>
                            <Box mb={5}>
                                <TextInput
                                    required
                                    fullWidth
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                />
                                {error && (
                                    <Typography
                                        mt={2}
                                        color="red"
                                        fontSize={12}
                                        align="center"
                                    >
                                        {error}
                                    </Typography>
                                )}
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <CommonButton
                                    disabled={newName.length < 1}
                                    type="submit"
                                >
                                    Edit
                                </CommonButton>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </CommonModal>
        </>
    )
}

export default AddMembersGroup
