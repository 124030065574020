import { TableRow, Typography } from '@mui/material'
import { styled } from '@mui/system'

export const StyledHeaderTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main
}))

export const StyledHeaderTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary
}))

export const StyledBodyTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary
}))

export const StyledBodyTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.mainBg.main
    }
}))

export const StyledNoDataContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(5),
    textAlign: 'center'
}))
