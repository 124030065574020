import { styled } from '@mui/system'

export const StyledUpload = styled('div')({
    border: '2px dashed #1E20714D',
    borderRadius: '10px',
    height: '11.813rem',
    cursor: 'pointer',
    background: '#E6E8EE',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
})

export const StyledComingSoonOverlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '150%',
    minHeight: '2em',
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'rotate(-30deg)',
    color: theme.palette.common.white,
    fontSize: '1.3rem',
    fontWeight: 500,
    opacity: 0.73
}))
