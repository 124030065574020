export const SmsTabs = [
    {
        label: 'Sms',
        path: '/send'
    }
]

export const MyAccountTabs = [
    {
        label: 'My Account',
        path: '/account'
    }
]

export const SavedTabs = [
    {
        label: 'Messages',
        path: '/saved'
    },
    {
        label: 'Groups',
        path: '/saved/groups'
    }
]

export const CampaignTabs = [
    {
        label: 'Ongoing',
        path: '/campaigns'
    },
    {
        label: 'Scheduled',
        path: '/campaigns/scheduled'
    },
    {
        label: 'History',
        path: '/campaigns/history'
    }
]
