// Absoulte
import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'

const MyAccount: FC = () => {
    return (
        <div>
            <Outlet />
        </div>
    )
}

export default MyAccount
