import {
    Box,
    Button,
    ButtonProps,
    Checkbox,
    CheckboxProps
} from '@mui/material'
import { styled } from '@mui/system'

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    background: theme.palette.common.white,
    border: `1px solid #C4C4C4`,
    fontWeight: 600,
    padding: '0.7em',
    minWidth: '8em',
    color: 'black',
    boxShadow: 'none',

    '&:hover': {
        background: theme.palette.common.white,
        boxShadow: 'none',
        border: `1px solid #494949`
    }
}))

export const StyledCheckBox = styled(Checkbox)<CheckboxProps>({
    visibility: 'hidden',
    padding: '0.5em 0'
})

export const StyledScheduledButtonContainer = styled(Box)({
    margin: '0 3rem',
    display: 'flex',
    columnGap: 4,
    flexWrap: 'nowrap',
    justifyContent: 'right'
})

export const StyledOutlinedButton = styled(Button)({
    border: '2px solid ',
    borderRadius: '20px',
    background: '#FFF',
    padding: '0 2rem',
    ':hover': {
        backgroundColor: '#001AA2',
        color: '#fff'
    }
})
