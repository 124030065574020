import { Tab } from '@mui/material'
import { styled } from '@mui/system'

export const StyledTab = styled(Tab)(({ theme }) => ({
    background: theme.palette.tertiary.main,
    borderRadius: '10px 10px 0px 0px',
    boxShadow: '2px 2px 6px #00000029',

    '&.Mui-selected': {
        background: '#EEF0F8'
    }
}))
