import { Box, BoxProps, ListItemIcon } from '@mui/material'
import { styled } from '@mui/system'

export const StyledSidebar = styled(Box)<BoxProps>(({ theme }) => ({
    zIndex: 1
}))
export const StyledSidebarLogo = styled('div')(({ theme }) => ({
    padding: theme.spacing(5),

    '& img': {
        cursor: 'pointer',
        width: '100%'
    }
}))
export const StyledListItemIcon = styled(ListItemIcon)({
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 0'
})
