import { privateClient } from 'api/interceptor/request.interceptor'

export const editSender = async (payload: {
    id: number
    sender_name?: string
    account_id: number
    client_password?: string
    client_id?: string
}) => {
    try {
        return await privateClient({
            url: '/sender/names',
            method: 'PATCH',
            data: payload
        })
    } catch (error) {
        return error
    }
}
