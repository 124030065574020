import { styled } from '@mui/system'
import { CSVLink } from 'react-csv'

export const StyledCsvLink = styled(CSVLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    background: theme.palette.common.white,
    border: `2px solid ${theme.palette.primary.main}`,
    fontWeight: 800,
    padding: '0.5em 2.6em',
    minWidth: '14em',
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration: 'none',
    borderRadius: '10px',

    '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white
    }
}))
