import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
    Box,
    Button,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography
} from '@mui/material'
import CustomButton from 'components/global/Button'

import {
    csvColumns,
    filterBy,
    historyColumns,
    modalColumns,
    downloadModalSelect,
    historyHeaders,
    STATUS_DESCRIPTION
} from './history.const'
import { IHistory, IDownloadHistory, IRecipients } from 'utils/app.interface'
import { getHistories, getRecipients } from 'api/account'
import CommonModal from 'components/global/commonModal'
import CommonTable from 'components/global/commonTable'
import TextInput from 'components/global/TextInput'
import useToast from 'utils/useToast'
import CampaignsTable from '../campaignsTable'
import {
    defaultRowsPerPage,
    isCurrentPage,
    isEmpty,
    parseIntConfig
} from 'utils/app.const'
import Search from 'components/global/Search'
import DownloadIcon from '@mui/icons-material/Download'
import { CSVLink } from 'react-csv'
import { StyledCsvLink } from './history.styled'
import Dropdown from 'components/global/Dropdown'
import { AxiosResponse } from 'axios'
import { IRecipientsTableData } from './history.interface'

const History: FC = () => {
    const [search, setSearch] = useState('')
    const [message, setMessage] = useState('')
    const [recipients, setRecipients] = useState<IRecipientsTableData[]>([])
    const [selectedRow, setSelectedRow] = useState<IHistory | null>(null)
    const [showModal, setShowModal] = useState(false)
    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
    const [recordLength, setRecordLength] = useState(0)

    // Download modal states
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false)
    const [selectDownloadType, setSelectDownloadType] = useState('')
    const [downloadHistory, setDownloadHistory] = useState([])
    const [totalHistory, setTotalHistory] = useState(defaultRowsPerPage)

    // Recipients states
    const [downloadRecipients, setDownloadRecipients] = useState<IRecipients[]>(
        []
    )

    const handleOnFilterChange = (event: any) => {
        const {
            target: { value }
        } = event
        setFilter(value)
    }
    const handleChangePage = (
        event: ChangeEvent<unknown> | null,
        newPage: number
    ) => {
        setPage(newPage - 1)
    }

    const handleChangeRowsPerPage = (event: SelectChangeEvent<string>) => {
        setRowsPerPage(parseInt(event.target.value, parseIntConfig))
        setPage(0)
    }

    const paginationPayload = {
        currentPage: page,
        currentLimit: rowsPerPage,
        total: recordLength,
        onPageChange: handleChangePage,
        handleOnPageSizeChange: handleChangeRowsPerPage
    }

    const handleClose = () => {
        setShowModal(false)
        setRecipients([])
        setSelectedRow(null)
        setMessage('')
        setPage(0)
        setSearch('')
    }

    const handleRowClick = (row: IHistory) => {
        setShowModal(true)
        setSelectedRow(row)
        getData()
    }

    // Download All in History
    useEffect(() => {
        getHistoryData()
    }, [])

    useEffect(() => {
        getHistoryData()
    }, [showDownloadModal])

    const handleDownloadModalOpen = () => {
        setShowDownloadModal(true)
    }

    const handleDownloadModalClose = () => {
        setShowDownloadModal(false)
    }

    const handleonChangeSelect = (e: any) => {
        const value = e.target.value
        setSelectDownloadType(value)
    }

    const getHistoryData = async () => {
        try {
            const res = await getHistories({
                s: 'newest',
                t: 'sms',
                d: null,
                o: 'AZ',
                status: 1,
                k: search,
                in: totalHistory,
                page: 0
            })

            setDownloadHistory(
                res.data.map((item: IDownloadHistory) => ({
                    date_created: item.date_created,
                    date_of_blast: item.date_of_blast,
                    campaign_name: item.campaign_name,
                    campaign_type: 'SMS',
                    total_amount: item.total_amount,
                    sentCount: item.sentCount
                }))
            )
            setTotalHistory(res.data[0]?.totalHistory || 0)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    useEffect(() => {
        getData()
    }, [filter, selectedRow, page])

    useEffect(() => {
        if (isEmpty(search)) {
            getData()
        }
    }, [search])

    const getData = async () => {
        if (!selectedRow) return
        try {
            const res = (await getRecipients({
                id: selectedRow.id,
                in: rowsPerPage,
                page: isCurrentPage(page, search),
                key: search,
                status: filter.length > 0 ? filter : null
            })) as unknown as AxiosResponse<IRecipients[]>
            setRecordLength(selectedRow.numberOfRecipients)
            setRecipients(
                res.data.map((recipient) => {
                    return {
                        mobile: recipient.mobile,
                        status: STATUS_DESCRIPTION[recipient.status],
                        reason: recipient.reason
                    } as unknown as IRecipientsTableData
                })
            )
            setMessage(selectedRow.message)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    const handleSearch = () => {
        getData()
    }

    // Download in Recipients
    const totalPages = Math.ceil(recordLength / rowsPerPage) | 0

    const pageRange = Array(totalPages)
        .fill(null)
        .map((_, i) => i)

    const getRecipientsPromise = pageRange.map((pageNumber) => {
        if (!selectedRow) return null
        return getRecipients({
            id: selectedRow!.id,
            in: rowsPerPage,
            page: pageNumber,
            key: search,
            status: filter.length > 0 ? filter : null
        })
    })

    const getAllRecipientPromise = async () => {
        try {
            const res = (await Promise.all(
                getRecipientsPromise
            )) as unknown as AxiosResponse<IRecipients[]>[]

            const dataList = res.map((data) => data!.data)
            const item = dataList.reduce((prev, current) => {
                return [...prev, ...current]
            }, [])
            return item
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    // Download Handler
    async function handleDownloadRecipients() {
        const res = (await getAllRecipientPromise()) as IRecipients[]
        setDownloadRecipients(res)
    }

    const csvReport = {
        filename: 'History.csv',
        headers: csvColumns,
        data: downloadRecipients
    }

    return (
        <>
            <CampaignsTable
                title="History"
                columns={historyColumns}
                status={1}
                rowClick={handleRowClick}
                downloadModal={handleDownloadModalOpen}
                hasDownload
            />

            {/* __DOWNLOAD_ALL MODAL__ */}
            <CommonModal
                open={showDownloadModal}
                close={handleDownloadModalClose}
            >
                <Box
                    sx={{
                        width: 600,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Typography variant="h2" mb={3}>
                        DOWNLOAD ALL
                    </Typography>
                    <Box width={200}>
                        <Dropdown
                            fullWidth
                            options={downloadModalSelect}
                            value={selectDownloadType}
                            onChange={handleonChangeSelect}
                        />
                    </Box>
                    <Box display="flex" columnGap={2} mt={3}>
                        <CustomButton onClick={handleDownloadModalClose}>
                            Cancel
                        </CustomButton>

                        {selectDownloadType === 'csv' ? (
                            <StyledCsvLink
                                data={downloadHistory || null}
                                filename={'History.csv'}
                                headers={historyHeaders}
                            >
                                Download
                            </StyledCsvLink>
                        ) : (
                            <CustomButton>Download</CustomButton>
                        )}
                    </Box>
                </Box>
            </CommonModal>

            {/* __RECIPIENTS MODAL__ */}
            <CommonModal open={showModal} close={handleClose}>
                <>
                    <Box sx={{ width: 752, height: '75vh' }}>
                        <Grid container justifyContent="center" mb={2}>
                            <Typography variant="h3" mb={1}>
                                Details
                            </Typography>
                        </Grid>
                        <Grid mb={4}>
                            <Typography variant="h4" mb={1}>
                                Message
                            </Typography>
                            <TextInput
                                value={message}
                                fullWidth
                                multiline
                                rows={3}
                                InputProps={{
                                    readOnly: true,
                                    sx: {
                                        fontSize: 13
                                    }
                                }}
                            />
                        </Grid>

                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={1}
                        >
                            <Box display="flex" alignItems="center" gap={1}>
                                <Typography variant="h4" color="primary">
                                    Recipients
                                </Typography>
                                <Select
                                    value={filter}
                                    onChange={handleOnFilterChange}
                                    displayEmpty
                                    sx={{
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: 'none'
                                        }
                                    }}
                                >
                                    {filterBy.map((name) => (
                                        <MenuItem
                                            key={name.value}
                                            value={name.value}
                                        >
                                            {name.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box display="flex" alignItems="center" gap={1}>
                                <Button>
                                    <CSVLink {...csvReport}>
                                        <span
                                            onClick={() =>
                                                handleDownloadRecipients()
                                            }
                                        >
                                            <DownloadIcon />
                                        </span>
                                    </CSVLink>
                                </Button>
                                <Search
                                    handleSearchChange={setSearch}
                                    handleSubmit={handleSearch}
                                />
                            </Box>
                        </Box>

                        <CommonTable
                            columns={modalColumns}
                            rows={recipients}
                            paginationPayload={paginationPayload}
                            dense
                        />
                    </Box>
                </>
            </CommonModal>
        </>
    )
}
export default History
