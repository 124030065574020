import { privateClient } from './interceptor/request.interceptor'

export const saveGroup = async (reqPayload: { name: string }) => {
    try {
        return await privateClient({
            url: '/groups',
            method: 'post',
            data: reqPayload
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}

export const getGroups = async (reqPayload: {
    order: string
    limit: number
    page: number
    search: string
}) => {
    try {
        return await privateClient({
            url: '/groups',
            method: 'get',
            params: {
                ...reqPayload
            }
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}

export const getGroup = async (reqPayload: { id: string }) => {
    try {
        return await privateClient({
            url: `/groups/${reqPayload.id}`,
            method: 'get'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}

export const deleteGroups = async (reqPayload: { ids: Array<string> }) => {
    try {
        return await privateClient({
            data: reqPayload,
            url: '/groups',
            method: 'delete'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}

export const editGroup = async (reqPayload: { id: number; name: string }) => {
    try {
        return await privateClient({
            data: reqPayload,
            url: '/groups',
            method: 'patch'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}
