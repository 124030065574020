import * as Yup from 'yup'

// Validation
export const senderFormSchema = () => {
    return Yup.object({
        sender_name: Yup.string()
            .required('Required')
            .max(20, 'Must be 20 characters or less'),
        client_id: Yup.string().required('Required'),
        client_password: Yup.string()
            .required('Required')
            .min(4, 'Password is too short'),
        confirm_password: Yup.string()
            .required('Required')
            .oneOf([Yup.ref('client_password')], 'Passwords do not match')
    })
}
