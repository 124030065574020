import { privateClient } from 'api/interceptor/request.interceptor'

export const addSender = async (reqPayload: {
    sender_name: string
    account_id: number
    client_password: string
    client_id: string
}) => {
    try {
        await privateClient({
            url: '/sender/names',
            method: 'POST',
            data: reqPayload
        })
    } catch (error) {
        return error
    }
}
