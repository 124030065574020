export const contactSubjects = [
    {
        key: 'inquiry',
        title: 'Inquiry'
    },
    {
        key: 'special_request',
        title: 'Special Request'
    },
    {
        key: 'postpaid_billing',
        title: 'Postpaid billing'
    },
    {
        key: 'top_up',
        title: 'Top up'
    },
    {
        key: 'account_details',
        title: 'Account Details'
    }
]
