import { privateClient } from 'api/interceptor/request.interceptor'

export const editClient = async (payload: {
    id?: number
    username?: string
    password: string
    company_name: string
    company_address: string
    contact_name: string
    contact_title: string
    contact_mobile: string
    contact_email: string
}) => {
    try {
        return await privateClient({
            url: '/account',
            method: 'PATCH',
            data: payload
        })
    } catch (error) {
        return error
    }
}
