import { MUIStyledCommonProps, styled } from '@mui/system'

interface IStyledPrevNextButton extends MUIStyledCommonProps {
    buttonType: string
}

export const StyledPrevNextButton = styled('button')<IStyledPrevNextButton>(
    ({ theme, disabled }) => ({
        cursor: 'pointer',
        color: disabled ? theme.palette.action.disabledBackground : '#272E4C',
        textDecoration: 'underline',
        border: 'none',
        background: 'none'
    })
)

interface IStyledPageButtons extends MUIStyledCommonProps {
    islast: '1' | '0'
}

export const StyledPageButtons = styled('button')<IStyledPageButtons>({
    color: '#272E4C',
    cursor: 'pointer',
    border: 'none',
    background: 'none'
})
