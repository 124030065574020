import { privateClient } from 'api/interceptor/request.interceptor'

export const addSingleNumber = async (
    reqPayload: {
        group_id: string
        first_name?: string
        last_name?: string
        mobile_number: string
        email_address?: string | null
        birthday?: string | null
        gender?: string
    },
    param: { id: string }
) => {
    try {
        return await privateClient({
            data: reqPayload,
            url: `/members?groupId=${param.id}`,
            method: 'POST'
        })
    } catch (error) {
        return error
    }
}
export const addMultiNumbers = async (
    reqPayload: {},
    param: { id: string }
) => {
    try {
        return await privateClient({
            data: reqPayload,
            url: `/members/mobiles?groupId=${param.id}`,
            method: 'POST'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}

export const addMultiNumbersCSV = async (reqPayload: {
    id: string
    numset: FormData
}) => {
    try {
        return await privateClient({
            data: reqPayload.numset,
            url: '/members/file',
            method: 'POST',
            params: {
                groupId: reqPayload.id
            }
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}
