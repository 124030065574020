import { FC, useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { StyledWelcomeCard, StyledButton } from './home.styled'
import IconCard from 'components/global/IconCard'
import { IUser } from 'utils/app.interface'
import { getUser } from 'utils/token'

const Home: FC = (): JSX.Element => {
    const navigate = useNavigate()
    const [user, setUser] = useState<IUser | null>(null)

    useEffect(() => {
        const data = getUser()
        setUser(data)
    }, [])

    return (
        <Box>
            <StyledWelcomeCard>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box>
                        <Typography variant="h2" color="primary" mb={2}>
                            Welcome back, {user?.contact_name}!
                        </Typography>
                        <Typography>
                            What would you like to send today?
                        </Typography>
                    </Box>
                    <Box>
                        <img
                            src={require('assets/images/welcome.png')}
                            alt="Welcome vector"
                        />
                    </Box>
                </Box>
            </StyledWelcomeCard>
            <Grid container spacing={5} mt={0.5}>
                <Grid item xs={4}>
                    <StyledButton
                        onClick={() =>
                            navigate(`${process.env.PUBLIC_URL}/send`)
                        }
                    >
                        <IconCard iconFilename="SMS_icon.png" label="SMS" />
                    </StyledButton>
                </Grid>
                <Grid item xs={4}>
                    <IconCard
                        isComingSoon={true}
                        iconFilename="Load_icon.png"
                        label="Load"
                    />
                </Grid>
                <Grid item xs={4}>
                    <IconCard
                        isComingSoon={true}
                        iconFilename="Digital_icon.png"
                        label="Digital Gifts"
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Home
