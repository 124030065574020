// Absolute
import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react'
import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material'

// Relative
import TextInput from 'components/global/TextInput'
import CommonTextArea from 'components/global/commonTextArea'
import Button from 'components/global/Button'
import {
    pageSendContent,
    temporarySendContent,
    buttonContent,
    SavedMessagesColumns
} from '../send.conts'
import CommonModal from 'components/global/commonModal'
import CommonTable from 'components/global/commonTable'
import Dropdown from 'components/global/Dropdown'
import {
    defaultRowsPerPage,
    filterBy,
    isCurrentPage,
    isEmpty,
    parseIntConfig
} from 'utils/app.const'
import { Step1Props } from './step1.interface'
import { getMessage } from 'api/savemessage/getmessage'
import useToast from 'utils/useToast'
import { hasBlank } from 'utils'
import Search from 'components/global/Search'
import { PhoneFrame } from 'Phoneframe'
import { Wrapper } from './step1.styled'

const Step1: FC<Step1Props> = (props: Step1Props) => {
    const {
        sendNow,
        message,
        setMessage,
        senderName,
        onSenderChange,
        onMessageChange,
        campaignName,
        setCampaignName,
        onChangeCampaignName,
        senderNames
    } = props

    // Hooks
    const [open, setOpen] = useState(false)
    const [filter, setFilter] = useState('desc')
    const [error] = useState(null)
    const [messages, setMessages] = useState([])
    const [search, setSearch] = useState('')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
    const [recordLength, setRecordLength] = useState(0)

    const handleChangePage = (
        event: ChangeEvent<unknown> | null,
        newPage: number
    ) => {
        setPage(newPage - 1)
    }

    const handleChangeRowsPerPage = (event: SelectChangeEvent<string>) => {
        setRowsPerPage(parseInt(event.target.value, parseIntConfig))
        setPage(0)
    }

    const paginationPayload = {
        currentPage: page,
        currentLimit: rowsPerPage,
        total: recordLength,
        onPageChange: handleChangePage,
        handleOnPageSizeChange: handleChangeRowsPerPage
    }

    useEffect(() => {
        getData()
    }, [filter, page, rowsPerPage])

    useEffect(() => {
        if (isEmpty(search)) {
            getData()
        }
    }, [search])

    const getData = async () => {
        try {
            const res = await getMessage({
                order: filter,
                limit: rowsPerPage,
                page: isCurrentPage(page, search),
                title: search
            })
            setMessages(res.data.data)
            setRecordLength(res.data.total)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }
    // Handlers
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleOnFilterChange = (event: any) => {
        const {
            target: { value }
        } = event
        setFilter(value)
    }

    const getOptions = () => {
        if (senderNames && senderNames.length < 1) return []
        const result = senderNames.map((item: any) => {
            const { id, sender_name: name, ...others } = item

            return { label: name, value: id, ...others }
        })
        return result
    }

    const handleSelectMessage = (value: any) => {
        setCampaignName(value.title)
        setMessage(value.message)
        setOpen(false)
    }

    const isButtonDisabled = () => {
        return hasBlank([campaignName, senderName || '', message])
    }

    const handleSearch = (e: FormEvent) => {
        e.preventDefault()
        getData()
    }
    return (
        <>
            <Wrapper>
                <Box
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 1fr',
                        columnGap: '6rem'
                    }}
                >
                    <Grid container flexDirection="column">
                        <div>
                            <Typography variant="h4" mb={1}>
                                Select Sender Name
                            </Typography>
                            <Box mb={3} sx={{ width: '100%' }}>
                                <Dropdown
                                    value={senderName?.id}
                                    options={getOptions()}
                                    onChange={onSenderChange}
                                    fullWidth
                                />
                            </Box>
                        </div>
                        <div>
                            <Typography variant="h4" mb={1}>
                                {pageSendContent.campNameTitle}
                            </Typography>

                            <Box mb={3} sx={{ width: '100%' }}>
                                <TextInput
                                    placeholder={temporarySendContent.campName}
                                    value={campaignName}
                                    onChange={onChangeCampaignName}
                                    autoComplete="off"
                                    fullWidth
                                />
                            </Box>
                        </div>
                        <CommonTextArea
                            rows={4}
                            textAreaValue={message}
                            onChangeTextAreaValue={onMessageChange}
                            counterGap={5}
                        />
                        <Box display="flex" justifyContent="end" mb={3}>
                            <Button onClick={handleOpen}>
                                {buttonContent.saved}
                            </Button>
                        </Box>
                        <Typography
                            variant="subtitle1"
                            fontStyle="italic"
                            color="primary"
                            mb={2}
                            textAlign="center"
                        >
                            {pageSendContent.terms}
                        </Typography>
                        <Box display="flex" justifyContent="center" gap={5}>
                            <Button
                                onClick={sendNow}
                                disabled={isButtonDisabled()}
                            >
                                {buttonContent.send}
                            </Button>
                        </Box>
                        {error && (
                            <Typography
                                mt={2}
                                color="red"
                                fontSize={12}
                                align="center"
                            >
                                {error}
                            </Typography>
                        )}
                    </Grid>
                    {/* BORDER LINE  */}
                    <Wrapper>
                        <PhoneFrame
                            msg={message}
                            multiline={true}
                            onMessageChange={onMessageChange}
                        />
                    </Wrapper>
                </Box>
            </Wrapper>
            <CommonModal open={open} close={handleClose}>
                <>
                    <Box sx={{ width: 752 }}>
                        <Grid container gap={1} justifyContent="flex-end">
                            <Grid
                                item
                                xs={3}
                                container
                                alignItems="center"
                                justifyContent="flex-end"
                                gap={1}
                            >
                                <Typography variant="h6">Sort By</Typography>
                                <Dropdown
                                    value={filter}
                                    options={filterBy}
                                    onChange={handleOnFilterChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Search
                                    handleSearchChange={setSearch}
                                    handleSubmit={handleSearch}
                                />
                            </Grid>
                        </Grid>
                        <Grid container gap={2} alignItems="center" mb={2}>
                            <Typography variant="h2" color="primary">
                                Saved Messages
                            </Typography>
                        </Grid>
                        <CommonTable
                            columns={SavedMessagesColumns}
                            rows={messages}
                            rowClick={(value) => handleSelectMessage(value)}
                            paginationPayload={paginationPayload}
                        />
                    </Box>
                </>
            </CommonModal>
        </>
    )
}

export default Step1
