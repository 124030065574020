import React, { ReactNode } from 'react'
import { StyledPopover } from '../../clients.styled'

interface IPopoverProps {
    id?: string
    open: boolean
    anchorEl: any
    onClose: () => void
    children?: ReactNode
}

const Popover = (props: IPopoverProps) => {
    const { id, open, anchorEl, onClose, children } = props

    return (
        <StyledPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            {children}
        </StyledPopover>
    )
}

export default Popover
