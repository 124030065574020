import { Box, Typography } from '@mui/material'
import Dropdown from 'components/global/Dropdown'
import React, { FC, useState } from 'react'
import { filterByTopUp } from 'utils/app.const'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import DownloadIcon from '@mui/icons-material/Download'
import CommonTable from 'components/global/commonTable'
import { TopUpHistoryColumn, TopUpHistoryRow } from './topUpHistory.const'
import { ITopUpHistoryProps } from './topUpHistory.interface'
import { StyledDownload } from './topUpHistory.styled'
type Rows = Array<ITopUpHistoryProps>
const TopUpHistory: FC = () => {
    // Hook
    const [filter, setFilter] = useState('latest')
    // Handler
    const handleOnFilterChange = (event: any) => {
        const {
            target: { value }
        } = event
        setFilter(value)
    }
    // Utility
    const data: Rows = TopUpHistoryRow
    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                gap={2}
                justifyContent="end"
            >
                <Typography>Sort By</Typography>
                <Dropdown
                    value={filter}
                    options={filterByTopUp}
                    onChange={handleOnFilterChange}
                />
                <CalendarMonthIcon color="primary" />
            </Box>
            <Typography variant="h2" color="primary" mb={2}>
                Top Up History
            </Typography>
            <CommonTable
                columns={[
                    ...TopUpHistoryColumn,
                    {
                        columnName: 'download' as const,
                        columnLabel: '',
                        render: (row: ITopUpHistoryProps) => {
                            return (
                                <>
                                    <Box display="flex" justifyContent="center">
                                        <DownloadIcon color="success" />
                                        <StyledDownload>
                                            Download
                                        </StyledDownload>
                                    </Box>
                                </>
                            )
                        }
                    }
                ]}
                rows={data}
            />
        </>
    )
}

export default TopUpHistory
