import { FC, FormEvent, useState } from 'react'
// Components
import { Box, Typography } from '@mui/material'
import Button from 'components/global/Button'
import CommonModal from 'components/global/commonModal'
import Dropdown from 'components/global/Dropdown'
import TextInput from 'components/global/TextInput'

import { theme } from 'theme'

import { contactSubjects } from './contactus.const'

const ContactUs: FC = (): JSX.Element => {
    const [subject, setSubject] = useState('')
    const [inputMessage, setInputMessage] = useState('')
    const [showSubmitModal, setShowSubmitModal] = useState(false)

    const getOptions = () => {
        if (contactSubjects && contactSubjects.length < 1) return []
        const result = contactSubjects.map((item: any) => {
            return { label: item.title, value: item.key }
        })
        return result
    }

    const handleOnFilterChange = (event: any) => {
        const value = event.target.value
        setSubject(value)
    }

    const handleInputMessage = (event: any) => {
        const input = event.target.value
        setInputMessage(input)
    }

    const handleOpenModal = (e: FormEvent) => {
        e.preventDefault()
        setShowSubmitModal(true)
    }

    const handleCloseModal = () => {
        setShowSubmitModal(false)
        setSubject('')
        setInputMessage('')
    }

    return (
        <Box>
            <Typography variant="h2" color="primary" mb={2}>
                Contact Us
            </Typography>
            <Typography variant="h3" mb={1}>
                7th Floor, Cambridge Centre, 108 Tordesillas Street, Salcedo
                Village, Makati 1227
            </Typography>
            <Typography variant="h3" mb={1}>
                Telephone: +632 (8) 889 6467
            </Typography>
            <Typography variant="h3" mb={4}>
                Email: enterprise@xurpas.com
            </Typography>

            {/* ---- TEXT AREA ----- */}
            <Box>
                <Typography variant="h4" color="gray" mb={1}>
                    Subject*
                </Typography>

                {/* action="mailto:samplecompanyemail@gmail.com" */}
                <form
                    style={{ width: '50vw' }}
                    onSubmit={(e) => handleOpenModal(e)}
                >
                    <Dropdown
                        value={subject}
                        options={getOptions()}
                        onChange={handleOnFilterChange}
                        fullWidth
                        required
                    />

                    <Typography variant="h4" color="gray" mt={2} mb={1}>
                        Message*
                    </Typography>
                    <TextInput
                        multiline
                        fullWidth
                        value={inputMessage}
                        rows={7}
                        onChange={handleInputMessage}
                        required
                    />
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button type="submit">Submit</Button>
                    </Box>

                    {/* ----- MODAL ------ */}
                    <CommonModal
                        open={showSubmitModal}
                        close={handleCloseModal}
                    >
                        <>
                            <Box sx={{ width: 665 }}>
                                <Typography
                                    variant="h1"
                                    mb={2}
                                    color={theme.palette.primary.main}
                                >
                                    Thank you!
                                </Typography>
                                <Typography variant="h3" mb={1}>
                                    We have received your message.
                                </Typography>
                                <Typography variant="h3" mb={2}>
                                    Someone from our team will get in touch with
                                    you shortly.
                                </Typography>
                            </Box>
                        </>
                    </CommonModal>
                </form>
            </Box>
        </Box>
    )
}

export default ContactUs
