import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { theme } from 'theme'

import { IContentToolBarProps } from './contentToolBar.interface'
import { StyledContentToolBar } from './contentToolBar.styled'

const AdminContentToolBar = (props?: IContentToolBarProps): JSX.Element => {
    // const balance = useBalanceState()
    // const { pathname } = useLocation()
    // const [tabs, setTabs] = useState<any>([])

    // useEffect(() => {
    //     renderTabs(pathname.split('/')[1])
    // }, [pathname])

    // const renderTabs = (location: string) => {
    //     switch (location) {
    // }

    return (
        <Box {...props} sx={{ zIndex: 999 }}>
            <div style={{ position: 'absolute', bottom: 0, left: 40 }}>
                {/* <BasicTabs tabs={tabs} /> */}
            </div>

            <StyledContentToolBar>
                <img
                    src={require('assets/images/admin_avatar.jpg')}
                    alt="Admin Avatar"
                    width={'65rem'}
                    height={'65rem'}
                    style={{ borderRadius: '50%', marginInline: '.5rem' }}
                />
                <Typography
                    variant="h2"
                    fontWeight={'light'}
                    color={theme.palette.text.primary}
                >
                    Admin
                </Typography>
            </StyledContentToolBar>
        </Box>
    )
}

export default AdminContentToolBar
