import { Typography, Box } from '@mui/material'
import { FC } from 'react'
import { StyledBank, StyledImportant } from './topUpMain.styled'

const TopUpMain: FC = () => {
    return (
        <>
            <Box mt={5.7}>
                <Typography variant="h2" color="primary" mb={2}>
                    TopUp
                </Typography>
                <Typography mb={2}>
                    To top up your wallet, please deposit desired amount to any
                    of the following bank <br />
                    accounts:
                </Typography>
                <Box display="flex" gap={5} mb={2}>
                    <StyledBank>
                        <Box>
                            <Typography color="white">
                                BDO Savings Account
                            </Typography>
                            <Typography color="white">123456789</Typography>
                        </Box>
                    </StyledBank>
                    <StyledBank>
                        <Box>
                            <Typography color="white">
                                BPI Savings Account
                            </Typography>
                            <Typography color="white">123456789</Typography>
                        </Box>
                    </StyledBank>
                </Box>
                <Typography variant="subtitle1" mb={1}>
                    IMPORTANT!*
                </Typography>
                <StyledImportant mb={2}>
                    <Typography variant="h6" color="primary" fontStyle="italic">
                        Please email your <strong>Proof of Payment</strong> to
                        info@xurpas.com
                    </Typography>
                </StyledImportant>
                <Typography fontStyle="italic">
                    Please wait up to 3 banking days for payment to be processed
                    and amount to be <br />
                    reflected in your wallet. You will receive an email once
                    transfer is complete.
                </Typography>
            </Box>
        </>
    )
}
export default TopUpMain
