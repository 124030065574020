import { privateClient } from 'api/interceptor/request.interceptor'

export const deleteMember = async (param: {
    memberId: string
    groupId: string
}) => {
    try {
        return await privateClient({
            url: `/members/${param.memberId}?groupId=${param.groupId}`,
            method: 'DELETE'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}
