import { privateClient } from './interceptor/request.interceptor'

export const send = async (reqPayload: {
    sender_name_id: number
    group_id: number
    mobile_number: Array<string>
    campaign_name: string
    campaign_status: number
    sender_name: string
    total_amount: number
    cost: number
    recipient_type: number
    message: string
}) => {
    try {
        return await privateClient({
            data: reqPayload,
            url: '/sender/send',
            method: 'post'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}

export const sendCsv = async (reqPayload: { form: FormData }) => {
    try {
        return await privateClient({
            data: reqPayload.form,
            url: '/sender/send/file',
            method: 'post'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}

export const editMessage = async (reqPayload: {
    id: number
    title: string
    message: string
}) => {
    try {
        return await privateClient({
            data: JSON.stringify({
                id: reqPayload.id,
                title: reqPayload.title,
                message: reqPayload.message
            }),
            url: '/message',
            method: 'patch'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}

export const deleteMessage = async (reqPayload: Array<number>) => {
    try {
        return await privateClient({
            data: JSON.stringify(reqPayload),
            url: '/message',
            method: 'delete'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}
export const scheduledSend = async (reqPayload: {
    sender_name_id: number
    group_id: number
    mobile_number: Array<string>
    campaign_name: string
    campaign_status: number
    sender_name: string
    total_amount: number
    cost: number
    recipient_type: number
    message: string
    date_scheduled: string
}) => {
    try {
        return await privateClient({
            data: reqPayload,
            url: '/sender/send/scheduled',
            method: 'POST'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}
export const sendScheduledCsv = async (reqPayload: { form: FormData }) => {
    try {
        return await privateClient({
            data: reqPayload.form,
            url: '/sender/send/scheduled-file',
            method: 'POST'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}
