import React, { FC } from 'react'
import { Backdrop, Typography } from '@mui/material'

import { IModalProps } from './commonModal.interface'
import {
    modalBackground,
    StyledModal,
    StyledModalBody,
    StyledModalBoxHeader,
    StyledModalCloseIcon,
    StyledModalBox
} from './commonModal.styled'

const CommonModal: FC<IModalProps> = (props: IModalProps) => {
    const { children, open, close } = props

    return (
        <>
            <StyledModal
                open={open}
                onClose={() => close(false)}
                components={{ Backdrop: modalBackground }}
            >
                <Typography variant="body1">
                    <StyledModalBox>
                        <StyledModalBoxHeader
                            display="flex"
                            justifyContent="end"
                        >
                            <StyledModalCloseIcon
                                onClick={() => close(false)}
                            />
                        </StyledModalBoxHeader>
                        <StyledModalBody>{children}</StyledModalBody>
                    </StyledModalBox>
                </Typography>
            </StyledModal>
        </>
    )
}

export default CommonModal
