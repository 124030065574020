import Clients from 'admin/clients'
import Logs from 'admin/logs'
import Reports from 'admin/reports'
import AdminContentToolBar from 'components/layout/contentToolBar/AdminContentToolbar'
import AdminSidebar from 'components/layout/sidebar/AdminSidebar'
import SidebarLayout from 'components/layout/sidebarLayout'
import { RouteObject } from 'react-router-dom'
import { AccountRoles, AdminPaths } from 'utils/app.const'
import PrivateRoute from './privateRoute'

const reportsRoute: RouteObject = {
    path: AdminPaths.HOME,
    element: <Reports />
}

const clientRoute: RouteObject = {
    path: AdminPaths.CLIENTS,
    element: <Clients />
}
const adminLogsRoute: RouteObject = {
    path: AdminPaths.LOGS,
    element: <Logs />
}

/** This is the parent route of Admin */
const adminRoutes: RouteObject[] = [
    {
        element: (
            <PrivateRoute role={AccountRoles.ADMIN}>
                <SidebarLayout
                    sidebar={<AdminSidebar />}
                    toolbar={<AdminContentToolBar />}
                />
            </PrivateRoute>
        ),

        children: [reportsRoute, clientRoute, adminLogsRoute]
    }
]

export default adminRoutes
