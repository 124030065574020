import { privateClient } from 'api/interceptor/request.interceptor'
import React from 'react'
import useToast from 'utils/useToast'

type ICancelScheduledRequestPayload = {
    id: number
}

async function cancelScheduled({ id }: ICancelScheduledRequestPayload) {
    try {
        const res = await privateClient({
            url: 'sender/send/scheduled-cancel',
            method: 'POST',
            params: { id }
        })
        return res
    } catch (e) {
        if (e instanceof Error) {
            useToast().error(e.message)
        }
    }
}

export default cancelScheduled
