import { privateClient } from '../interceptor/request.interceptor'

export const getReports = async (reqPayload: {
    company_names?: Array<string>
    start_date?: string
    end_date?: string
    limit: number
    page: number
}) => {
    try {
        return await privateClient({
            data: reqPayload,
            url: '/history/reports',
            method: 'POST'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}

export const getAllCompanyNames = async () => {
    try {
        return await privateClient({
            url: '/account/company-names',
            method: 'GET'
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}
