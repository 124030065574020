import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { IClientFormProps } from './clientform.interface'
import { StyledButtonContainer } from './clientform.styled'
import CommonConfirmation from 'components/global/commonConfirmation'
import { REGEX } from 'utils/app.const'
import * as Yup from 'yup'

import {
    StyledAdminButton,
    StyledAdminFilledButton
} from 'admin/clients/clients.styled'

import { Form, Formik } from 'formik'
import TextInput from '../TextInput/TextInput'

const ClientForm = (props: IClientFormProps) => {
    // States
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)

    // Validation
    const clientFormSchema = () => {
        return Yup.object({
            username: Yup.string().required('Required'),
            password: Yup.string()
                .required('Required')
                .min(4, 'Password is too short')
                .max(20, 'Must be 20 characters or less'),
            confirm_password: Yup.string()
                .required('Required')
                .oneOf([Yup.ref('password')], 'Passwords do not match'),
            company_name: Yup.string().required('Required'),
            company_address: Yup.string().required('Required'),
            contact_name: Yup.string().required('Required'),
            contact_title: Yup.string().required('Required'),
            contact_mobile: Yup.string()
                .required('Required')
                .matches(
                    REGEX.MOBILE_NUMBER_FORMAT || REGEX.INTERNATIONAL_DIAL_CODE,
                    'Invalid number'
                ),
            contact_email: Yup.string()
                .required('Required')
                .email('Invalid email address')
        })
    }

    return (
        <Box mx={2}>
            <Typography variant="h3" mb={4} textAlign="center">
                {props.isReadOnly ? 'Details' : props.formTitle}
            </Typography>

            <Formik
                initialValues={props.formData}
                validationSchema={clientFormSchema}
                onSubmit={(values) => {
                    props.setFormData(values)
                    setShowConfirmation(true)
                }}
            >
                {({ isValid }) => (
                    <Form>
                        <TextInput
                            fullWidth
                            required={!props.isReadOnly}
                            disabled={props.isReadOnly}
                            label="Username"
                            name="username"
                            type="text"
                        />

                        <Box display="flex" columnGap={2}>
                            <TextInput
                                required={!props.isReadOnly}
                                disabled={props.isReadOnly}
                                label="Company Name"
                                name="company_name"
                                type="text"
                            />
                            <TextInput
                                required={!props.isReadOnly}
                                disabled={props.isReadOnly}
                                label="Company Address"
                                name="company_address"
                                type="text"
                            />
                        </Box>
                        <Box display="flex" columnGap={2}>
                            <TextInput
                                required={!props.isReadOnly}
                                disabled={props.isReadOnly}
                                label="Contact Person"
                                name="contact_name"
                                type="text"
                            />
                            <TextInput
                                required={!props.isReadOnly}
                                disabled={props.isReadOnly}
                                label="Title"
                                name="contact_title"
                                type="text"
                            />
                        </Box>
                        <Box display="flex" columnGap={2}>
                            <TextInput
                                required={!props.isReadOnly}
                                disabled={props.isReadOnly}
                                label="Contact Number"
                                name="contact_mobile"
                                type="text"
                            />
                            <TextInput
                                required={!props.isReadOnly}
                                disabled={props.isReadOnly}
                                label="Email Address"
                                name="contact_email"
                                type="text"
                            />
                        </Box>
                        <Box display="flex" columnGap={2}>
                            <TextInput
                                required={!props.isReadOnly}
                                disabled={props.isReadOnly}
                                label="Password"
                                name="password"
                                type="password"
                            />
                            <TextInput
                                required={!props.isReadOnly}
                                disabled={props.isReadOnly}
                                label="Confirm Password"
                                name="confirm_password"
                                type="password"
                            />
                        </Box>
                        {!props.isReadOnly && (
                            <StyledButtonContainer>
                                <StyledAdminButton
                                    onClick={props.handleModalClose}
                                >
                                    Cancel
                                </StyledAdminButton>
                                <StyledAdminFilledButton
                                    disabled={!isValid}
                                    type="submit"
                                >
                                    Submit
                                </StyledAdminFilledButton>
                            </StyledButtonContainer>
                        )}

                        {/* __ Confirmation Modal __ */}
                        <CommonConfirmation
                            open={showConfirmation}
                            close={() => setShowConfirmation(false)}
                            confirmationText={props.confirmationText}
                        >
                            <Box
                                display="flex"
                                justifyContent="center"
                                columnGap={2}
                            >
                                <StyledAdminButton
                                    onClick={() => {
                                        props.setFormData(null)
                                        setShowConfirmation(false)
                                    }}
                                >
                                    No
                                </StyledAdminButton>
                                <StyledAdminFilledButton
                                    onClick={() => props.handleSubmit()}
                                >
                                    Yes
                                </StyledAdminFilledButton>
                            </Box>
                        </CommonConfirmation>
                    </Form>
                )}
            </Formik>
        </Box>
    )
}

export default ClientForm
