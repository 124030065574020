import { Box, Typography } from '@mui/material'
import type { FC } from 'react'
import { IIconCardProps } from './iconCard.interface'
import {
    StyledComingSoonOverlay,
    StyledWelcomeCardIcon
} from './iconCard.styled'

const IconCard: FC<IIconCardProps> = (props): JSX.Element => {
    const { iconFilename, label, isComingSoon } = props

    return (
        <StyledWelcomeCardIcon>
            {isComingSoon ? (
                <StyledComingSoonOverlay>Coming Soon</StyledComingSoonOverlay>
            ) : (
                ''
            )}
            <Box textAlign="center">
                <img
                    src={require(`assets/images/${iconFilename}`)}
                    alt="Load Icon"
                />
                <Typography variant="h3" color="white" fontWeight={500}>
                    {label}
                </Typography>
            </Box>
        </StyledWelcomeCardIcon>
    )
}

export default IconCard
