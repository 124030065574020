import { SelectChangeEvent, Typography } from '@mui/material'
import CommonModal from 'components/global/commonModal'
import CommonTable from 'components/global/commonTable'
import { ChangeEvent, FormEvent, useEffect, useState, useCallback } from 'react'
import { clientsColumms } from './clients.const'
import {
    StyledAdminButton,
    StyledButtonContainer,
    StyledForm,
    StyledIconButton,
    StyledPopoverText,
    StyledSearchIcon,
    StyledTextField
} from './clients.styled'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { getClients } from 'api/clients/getClients'
import { AxiosResponse } from 'axios'
import {
    defaultRowsPerPage,
    isCurrentPage,
    isEmpty,
    parseIntConfig
} from 'utils/app.const'
import useToast from 'utils/useToast'
import AddClient from './AddClient'
import EditClient from './EditClient'
import EditSender from './EditSender/EditSender'
import { IClientForm } from './_components/ClientForm/clientform.interface'
import Popover from './_components/Popover/Popover'

const Clients = () => {
    // Clients Form
    const [clients, setClients] = useState<IClientForm[]>([])
    const [editClient, setEditClient] = useState({} as IClientForm)
    const [rowId, setRowId] = useState(0)
    const [isAdding, setIsAdding] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false)
    // Sender Form
    const [isSenderEditing, setIsSenderEditing] = useState<boolean>(false)
    // Others
    const [anchorEl, setAnchorEl] = useState(null)
    const [search, setSearch] = useState('')
    // Pagination
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
    const [page, setPage] = useState(0)
    const [totalAccounts, setTotalAccounts] = useState(0)

    useEffect(() => {
        if (isEmpty(search)) {
            getClientResponse()
        }
    }, [search])

    useEffect(() => {
        getClientResponse()
    }, [isEditing, page, rowsPerPage])

    const getClientResponse = useCallback(async () => {
        try {
            const res = (await getClients({
                in: rowsPerPage,
                page: isCurrentPage(page, search)
            })) as AxiosResponse<IClientForm[]>
            setClients(res.data)
            setTotalAccounts(res.data[0].total_account!)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }, [rowsPerPage, page, search])

    // Handlers
    const handleAddModalClose = () => {
        setIsAdding(false)
    }

    const handleAddModalOpen = () => {
        setIsAdding(true)
    }

    const handleEditModalClose = () => {
        setIsEditing(false)
    }

    const handleEditModalOpen = () => {
        setIsEditing(true)
        setAnchorEl(null)

        const selected = clients.find((client) => client.id === rowId)
        if (selected) setEditClient(selected)
    }

    const handleSenderModalOpen = () => {
        setAnchorEl(null)
        setIsSenderEditing(true)
    }

    const handleSearch = (e: FormEvent) => {
        e.preventDefault()
        const item = clients.filter((client) => {
            return client.company_name
                ?.toLowerCase()
                .includes(search.toLowerCase())
        })
        setClients(item)
    }

    const handlePopOver = (event: any, id: number, readOnly: boolean) => {
        setIsReadOnly(readOnly)
        handleAnchor(event)
        setRowId(id)
    }

    const handleAnchor = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleChangePage = (
        event: ChangeEvent<unknown> | null,
        newPage: number
    ) => {
        setPage(newPage - 1)
    }

    const handleChangeRowsPerPage = (event: SelectChangeEvent<string>) => {
        setRowsPerPage(parseInt(event.target.value, parseIntConfig))
        setPage(0)
    }

    const paginationPayload = {
        currentLimit: rowsPerPage,
        currentPage: page,
        onPageChange: handleChangePage,
        OnPageSizeChange: handleChangeRowsPerPage,
        total: totalAccounts,
        showOption: true
    }

    // Popover
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <>
            <Typography variant="h2" color="primary" mb={4}>
                Clients
            </Typography>

            <StyledForm onSubmit={handleSearch}>
                {/* __ SEARCH __ */}
                <StyledTextField
                    fullWidth
                    placeholder="Search"
                    InputProps={{
                        startAdornment: <StyledSearchIcon />
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                />

                {/* __ ADD CLIENT BUTTON __ */}
                <StyledAdminButton
                    sx={{ borderRadius: '40px', minWidth: '9em' }}
                    onClick={handleAddModalOpen}
                >
                    <AddIcon /> Add Client
                </StyledAdminButton>
            </StyledForm>

            {/* __ TABLE __ */}
            <CommonTable
                paginationPayload={paginationPayload}
                columns={[
                    ...clientsColumms,
                    {
                        columnName: 'action' as const,
                        columnLabel: '',
                        render: (row: any) => {
                            return (
                                <StyledButtonContainer>
                                    <StyledIconButton
                                        onClick={(event: any) => {
                                            handlePopOver(event, row.id, true)
                                        }}
                                    >
                                        <VisibilityIcon fontSize="small" />
                                    </StyledIconButton>
                                    <StyledIconButton
                                        onClick={(event: any) =>
                                            handlePopOver(event, row.id, false)
                                        }
                                    >
                                        <EditIcon fontSize="small" />
                                    </StyledIconButton>
                                </StyledButtonContainer>
                            )
                        }
                    }
                ]}
                rows={clients}
            />

            {/* Viewing and Editing Popover  */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <StyledPopoverText onClick={handleEditModalOpen}>
                    Client Details
                </StyledPopoverText>
                <StyledPopoverText onClick={handleSenderModalOpen}>
                    Sender Details
                </StyledPopoverText>
            </Popover>

            {/* Clients Form Modal */}
            {isAdding ? (
                <CommonModal open={isAdding} close={handleAddModalClose}>
                    <AddClient handleModalClose={handleAddModalClose} />
                </CommonModal>
            ) : (
                <CommonModal open={isEditing} close={handleEditModalClose}>
                    <EditClient
                        selectedClient={editClient}
                        isReadOnly={isReadOnly}
                        handleModalClose={handleEditModalClose}
                    />
                </CommonModal>
            )}

            {/* Sender Form Modal */}
            <CommonModal
                open={isSenderEditing}
                close={() => setIsSenderEditing(false)}
            >
                <EditSender
                    formTitle="Edit Sender Details"
                    handleModalClose={() => setIsSenderEditing(false)}
                    isReadOnly={isReadOnly}
                    rowId={rowId}
                />
            </CommonModal>
        </>
    )
}

export default Clients
