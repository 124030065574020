import { privateClient } from '../interceptor/request.interceptor'

export const addMessages = async (reqPayload: {
    title: string
    message: string
}) => {
    try {
        return await privateClient({
            url: '/message',
            method: 'post',
            data: JSON.stringify(reqPayload)
        })
    } catch (error) {
        return error
    }
}
