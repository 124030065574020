export const types: any = [
    { label: 'SMS', value: 'sms' }
    // FOR FUTURE USE
    // { label: 'Load', value: 'load' }
]

export enum CAMPAIGN_TYPE {
    SMS = 0,
    LOAD = 1
}

export const sortBy = [
    {
        label: 'Date',
        key: 'date',
        children: [
            { label: 'Newest', value: 'newest' },
            { label: 'Oldest', value: 'oldest' }
        ]
    }
    // comment out for now for future use
    // {
    //     label: 'Alphabetically',
    //     key: 'alpha',
    //     children: [
    //         { label: 'A-Z', value: 'AZ' },
    //         { label: 'Z-A', value: 'ZA' }
    //     ]
    // }
]
