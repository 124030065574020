import { Button, ButtonProps, Checkbox, CheckboxProps } from '@mui/material'
import { styled } from '@mui/system'

export const StyledButton = styled(Button)<ButtonProps>(({ theme }) => ({
    background: theme.palette.common.white,
    border: `1px solid #C4C4C4`,
    fontWeight: 600,
    padding: '0.7em',
    minWidth: '8em',
    color: 'black',
    boxShadow: 'none',

    '&:hover': {
        background: theme.palette.common.white,
        boxShadow: 'none',
        border: `1px solid #494949`
    }
}))

export const StyledCheckBox = styled(Checkbox)<CheckboxProps>({
    visibility: 'hidden',
    padding: '0.5em 0'
})
