export const TopUpHistoryColumn = [
    {
        columnLabel: 'Date',
        columnName: 'date' as const
    },
    {
        columnLabel: 'Amount Due Paid',
        columnName: 'amountDuePaid' as const
    },
    {
        columnLabel: 'Credit Amount',
        columnName: 'creditAmount' as const
    },
    {
        columnLabel: 'Status',
        columnName: 'status' as const
    }
]
export const TopUpHistoryRow = [
    {
        date: '04/05/2020 12:28PM',
        amountDuePaid: 5000,
        creditAmount: 5000,
        status: 'Pending'
    },
    {
        date: '04/10/2020 12:28PM',
        amountDuePaid: 1500,
        creditAmount: 1500,
        status: 'Completed'
    },
    {
        date: '04/10/2020 12:28PM',
        amountDuePaid: 5000,
        creditAmount: 5000,
        status: 'Completed'
    },
    {
        date: '04/28/2020 12:28PM',
        amountDuePaid: 500,
        creditAmount: 500,
        status: 'Completed'
    },
    {
        date: '04/31/2020 12:28PM',
        amountDuePaid: 100,
        creditAmount: 100,
        status: 'Completed'
    }
]
