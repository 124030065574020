import { Typography } from '@mui/material'
import { theme } from 'theme'
import React from 'react'

type INoteProps = {
    message: string
}

const Note = ({ message }: INoteProps) => {
    return (
        <Typography
            variant="body1"
            color={theme.palette.grey[50]}
            fontStyle="italic"
        >
            {message}
        </Typography>
    )
}

export default Note
