import { FC, useState } from 'react'
import TuneIcon from '@mui/icons-material/Tune'
import {
    Button,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Popover,
    TextField,
    Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { StyledActionButton, StyledButton } from './filter.styled'
import { IFilterProps } from './filter.interface'

const Filter: FC<IFilterProps> = (props): JSX.Element => {
    const {
        types,
        date,
        selectedTypes,
        onDateChange,
        onTypeSelect,
        applyFilter,
        clearFilter
    } = props
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleApplyFilter = () => {
        applyFilter()
        handleClose()
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledButton
                    variant="contained"
                    endIcon={<TuneIcon />}
                    onClick={handleClick}
                >
                    Filter
                </StyledButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Box sx={{ width: 450 }} p={2}>
                        <Grid container alignItems="center" py={2}>
                            <Grid item xs={4}>
                                <Typography variant="h4">Type</Typography>
                            </Grid>
                            {types.map((type) => (
                                <Grid item key={type.value}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedTypes.includes(
                                                    type.value
                                                )}
                                                onChange={() =>
                                                    onTypeSelect(type.value)
                                                }
                                            />
                                        }
                                        label={type.label}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        <Divider />
                        <Grid container alignItems="center" py={2}>
                            <Grid item xs={4}>
                                <Typography variant="h4">Date</Typography>
                            </Grid>
                            <Grid item>
                                <DesktopDatePicker
                                    PopperProps={{
                                        sx: {
                                            span: {
                                                color: '#000'
                                            }
                                        }
                                    }}
                                    className="filterdate"
                                    inputFormat="yyyy-MM-dd"
                                    value={date}
                                    onChange={onDateChange}
                                    renderInput={(params: any) => (
                                        <TextField
                                            autoComplete="off"
                                            {...params}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container alignItems="center" py={2}>
                            <Grid item flex={1}>
                                <Button color="error" onClick={clearFilter}>
                                    Clear all filters
                                </Button>
                            </Grid>
                            <Grid item px={1}>
                                <StyledActionButton
                                    variant="contained"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </StyledActionButton>
                            </Grid>
                            <Grid item px={1}>
                                <StyledActionButton
                                    variant="contained"
                                    onClick={handleApplyFilter}
                                >
                                    Apply
                                </StyledActionButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Popover>
            </LocalizationProvider>
        </>
    )
}

export default Filter
