import { FC, useState } from 'react'
import { Grid, Typography, Box, useTheme } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import Button from 'components/global/Button'
import CommonModal from 'components/global/commonModal'
import { summaryContents } from '../edit.const'
import { IStep3Props } from './summary.interface'
import DateTimePicker from 'components/global/DateTimePicker'

const Step3: FC<IStep3Props> = (props: IStep3Props) => {
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const [showScheduleModal, setShowScheduleModal] = useState(false)
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    const handleShowScheduleModal = () => setShowScheduleModal(true)
    const handleHideScheduleModal = () => setShowScheduleModal(false)
    const handleShowConfirmationModal = () => setShowConfirmationModal(true)
    const handleHideConfirmationModal = () => setShowConfirmationModal(false)
    const handleProceed = (e: any) => {
        setLoading(true)
        props.buttonProps.onSendNow(e)
    }
    const handleScheduledProceed = (e: any) => {
        setLoading(true)
        props.buttonProps.onScheduled(e)
    }

    const concatenatedTime =
        props.scheduledProps.hour +
        ':' +
        props.scheduledProps.minute +
        ' ' +
        props.scheduledProps.meridiem

    const momentedDate = moment(props.scheduledProps.date).format(
        'MMMM DD, YYYY'
    )
    const disabled = (): boolean => {
        return props.scheduledProps.date === null
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Typography variant="h2" color="primary" mb={2}>
                    Summary
                </Typography>

                {summaryContents.map(({ key, value }) => (
                    <Grid container spacing={2} mt={0.1} key={key}>
                        <Grid item xs={12} lg={2}>
                            <Typography>{value}</Typography>
                        </Grid>
                        <Grid item xs={10} pr={10}>
                            <Typography variant="h4" color="primary">
                                {props.detailProps[
                                    key as keyof typeof props.detailProps
                                ] || 'N/A'}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}

                <Box display="flex" justifyContent="center" gap={5} mt={10}>
                    <Button onClick={props.buttonProps.onEdit}>Edit</Button>
                    <Button
                        onClick={handleProceed}
                        isLoading={loading}
                        disabled={loading}
                    >
                        Send Now
                    </Button>
                    <Button onClick={handleShowScheduleModal}>
                        Send Later
                    </Button>
                    <CommonModal
                        open={showScheduleModal}
                        close={handleHideScheduleModal}
                    >
                        <>
                            <Typography variant="h3">
                                Schedule Sending
                            </Typography>
                            <DateTimePicker
                                date={props.scheduledProps.date}
                                hour={props.scheduledProps.hour}
                                minute={props.scheduledProps.minute}
                                meridiem={props.scheduledProps.meridiem}
                                onDateChange={props.scheduledProps.onDateChange}
                                onHourChange={props.scheduledProps.onHourChange}
                                onMinuteChange={
                                    props.scheduledProps.onMinuteChange
                                }
                                onMeridiemChange={
                                    props.scheduledProps.onMeridiemChange
                                }
                            />
                            <Box
                                display="flex"
                                justifyContent="center"
                                mt={5}
                                gap={5}
                            >
                                <Button onClick={props.buttonProps.onReset}>
                                    Reset
                                </Button>
                                <Button
                                    disabled={disabled()}
                                    onClick={handleShowConfirmationModal}
                                >
                                    Schedule
                                </Button>
                            </Box>
                        </>
                    </CommonModal>
                    <CommonModal
                        open={showConfirmationModal}
                        close={handleHideConfirmationModal}
                    >
                        <>
                            <Box
                                display="flex"
                                justifyContent="center"
                                textAlign="center"
                            >
                                <Typography variant="h3">
                                    Are you sure you want to schedule the date
                                    <br /> of blast on{' '}
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            color: theme.palette.primary.main,
                                            display: 'inline-block'
                                        }}
                                    >
                                        {momentedDate}
                                    </Typography>{' '}
                                    at{' '}
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            color: theme.palette.primary.main,
                                            display: 'inline-block'
                                        }}
                                    >
                                        {concatenatedTime}
                                    </Typography>
                                    ?
                                </Typography>
                            </Box>
                            <Box display="flex" mt={5} gap={5}>
                                <Button
                                    onClick={handleScheduledProceed}
                                    isLoading={loading}
                                    disabled={loading}
                                >
                                    Yes
                                </Button>
                                <Button onClick={handleHideConfirmationModal}>
                                    No
                                </Button>
                            </Box>
                        </>
                    </CommonModal>
                </Box>
            </LocalizationProvider>
        </>
    )
}

export default Step3
