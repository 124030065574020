import { styled } from '@mui/system'
import { TextField, TextFieldProps } from '@mui/material'

export const StyledTextInput = styled(TextField)<TextFieldProps>(
    ({ theme }) => ({
        marginBottom: theme.spacing(1),
        label: {
            color: '#000',
            '&:disabled': {
                color: 'black'
            },
            '&:focus': {
                color: 'green'
            }
        },

        '& .MuiInputBase-input': {
            borderRadius: 8,
            border: `1px solid ${theme.palette.action.disabledBackground}`,
            fontSize: 16,
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`
        }
    })
) as typeof TextField
