import { styled } from '@mui/system'
import { Box, BoxProps } from '@mui/material'

type IBoxProps = {
    isGreaterThanTwo?: boolean
} & BoxProps

export const StyledContainer = styled(Box)<IBoxProps>(
    ({ isGreaterThanTwo }) => ({
        minWidth: 800,
        height: `${isGreaterThanTwo ? '60vh' : 'auto'}`,
        overflowY: 'auto',
        padding: '0 2rem'
    })
)
