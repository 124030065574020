import { privateClient } from 'api/interceptor/request.interceptor'
import { AccountRoles } from 'utils/app.const'

export interface IClientList {
    company_name: string
    company_address: string
    contact_name: string
    contact_title: string
    contact_mobile: string
    contact_email: string
    role: AccountRoles
    total_account: number
}

export const getClients = async (reqPayload: { in: number; page: number }) => {
    try {
        return await privateClient({
            url: '/account/client-list',
            method: 'GET',
            params: reqPayload
        })
    } catch (error) {
        return error
    }
}
