import moment from 'moment'

// ERROR CODE
export const statusCode = {
    UNAUTHORIZE: 401
}

// Filters
export const filterBy = [
    {
        label: 'Newest',
        value: 'desc'
    },
    {
        label: 'Oldest',
        value: 'asc'
    }
]
export const filterByTopUp = [
    {
        label: 'Latest',
        value: 'latest'
    },
    {
        label: 'Oldest',
        value: 'oldest'
    }
]

export const genders = [
    {
        label: '----',
        value: ''
    },
    {
        label: 'Male',
        value: 'Male'
    },
    {
        label: 'Female',
        value: 'Female'
    },
    {
        label: 'Others',
        value: 'Others'
    }
]
export const entries = ['5', '10', '20', '100']

// Moment (Time/Date)
export const year = moment().year()
export const years = Array.from(new Array(150), (value, index) => year - index)
export const months = moment.months()
export const days = Array.from({ length: 31 }, (value, index) => index + 1)

export const meridiem = [
    {
        label: 'AM',
        value: 'AM'
    },
    {
        label: 'PM',
        value: 'PM'
    }
]
export const hours = () => {
    const _hours = []

    for (let i = 1; i < 13; i++) {
        const _i = i
        if (i <= 9) {
            _hours.push({
                label: `0${_i}`,
                value: `0${_i}`
            })
        } else {
            _hours.push({
                label: _i.toString(),
                value: _i.toString()
            })
        }
    }
    return _hours
}

export const minutes = () => {
    const _min = []

    for (let i = 0; i < 60; i++) {
        const _i = i
        if (i <= 9) {
            _min.push({
                label: `0${_i}`,
                value: `0${_i}`
            })
        } else {
            _min.push({
                label: _i.toString(),
                value: _i.toString()
            })
        }
    }
    return _min
}

export const Months = [
    {
        label: 'January',
        value: '0'
    },
    {
        label: 'February',
        value: '1'
    },
    {
        label: 'March',
        value: '2'
    },
    {
        label: 'April',
        value: '3'
    },
    {
        label: 'May',
        value: '4'
    },
    {
        label: 'June',
        value: '5'
    },
    {
        label: 'July',
        value: '6'
    },
    {
        label: 'August',
        value: '7'
    },
    {
        label: 'September',
        value: '8'
    },
    {
        label: 'October',
        value: '9'
    },
    {
        label: 'November',
        value: '10'
    },
    {
        label: 'December',
        value: '11'
    }
]

// Mobile Number Validation
export const REGEX = {
    MOBILE_NUMBER_FORMAT: /^(09|\+639)\d{9}$/,
    INTERNATIONAL_DIAL_CODE: /^(\+639)\d{9}$/
}
export const mobileNumberValidation = (mobileNumber: string) => ({
    isMobileNumberFormat: (): boolean => {
        if (mobileNumber.match(REGEX.MOBILE_NUMBER_FORMAT)) return true
        return false
    },
    isInternationalDialCode: (): boolean => {
        if (mobileNumber.match(REGEX.INTERNATIONAL_DIAL_CODE)) return true
        return false
    }
})

export const isEmpty = (search: string): boolean => {
    return search.length < 1
}

export const isCurrentPage = (page: number, search: string): number => {
    return isEmpty(search) ? page : 0
}

// PAGINATION
export const defaultRowsPerPage = 5
export const parseIntConfig = 10

// USERS
export enum AccountRoles {
    ADMIN = 'admin',
    CLIENT = 'client'
}

const base = `${process.env.PUBLIC_URL}` as const
export const AdminPaths = {
    BASE: `${base}/admin`,
    LOGIN: `${base}/admin/login`,
    HOME: `${base}/admin/reports`,
    CLIENTS: `${base}/admin/clients`,
    LOGS: `${base}/admin/logs`
} as const

export const RoleLoginPaths = {
    CLIENT: `${process.env.PUBLIC_URL}/`,
    ADMIN: AdminPaths.LOGIN
} as const

export const RoleHomePaths = {
    CLIENT: `${process.env.PUBLIC_URL}/home`,
    ADMIN: AdminPaths.HOME
} as const
