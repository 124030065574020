import { AxiosResponse } from 'axios'
import { privateClient } from '../interceptor/request.interceptor'

export interface IScheduledHistoryResponse {
    id: number
    group_id: string
    campaign_status: number
    sender_name: string
    sender_name_id: number
    total_amount: number
    cost: number
    recipient_type: number
    message: string
    campaign_name: string
    number_of_recipients: number
    sent_count: number
    total_history: number
    date_created: string
    date_scheduled: string
}

/**
 * @param {string} s Sorting (newest OR oldest)
 * @param {string} k Search key (campaign name only)
 * @param {string} [d] Single date (NOT a date range)
 * @param {number} limit Number of records to be requested
 * @param {number} page Number of pages based on total_pages / limit
 */
export interface IScheduledHistoryRequest {
    s: 'newest' | 'oldest'
    limit: number
    page: number
    k: string | null
    d?: string
}

export const getScheduledHistory = async (
    requestPayload: IScheduledHistoryRequest
) => {
    try {
        return (await privateClient({
            url: '/history/scheduled',
            method: 'GET',
            params: requestPayload
        })) as unknown as AxiosResponse<IScheduledHistoryResponse[]>
    } catch (error) {
        return error
    }
}
