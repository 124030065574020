import React from 'react'
import { Box } from '@mui/material'
import { Formik, Form } from 'formik'
import TextInput from '../TextInput/TextInput'
import { senderFormSchema } from './senderForm.const'
import { StyledAdminFilledButton } from 'admin/clients/clients.styled'
import { IEditSenderFormProps } from './senderForm.interface'

const EditSenderForm = (props: IEditSenderFormProps) => {
    const {
        formRef,
        formData,
        setDirtySender,
        isReadOnly,
        handleResetPerSender
    } = props

    return (
        <Formik
            innerRef={formRef}
            initialValues={{
                id: formData.id,
                client_id: formData.client_id,
                account_id: formData.account_id,
                sender_name: formData.sender_name,
                client_password: formData.client_password,
                confirm_password: formData.client_password
            }}
            validationSchema={senderFormSchema}
            onSubmit={(values) => {
                setDirtySender((prev) => [...prev, values])
            }}
        >
            {({ isSubmitting, dirty, handleReset }) => (
                <Form>
                    <Box display="flex" gap={2}>
                        <TextInput disabled label="Sender ID" name="id" />
                        <TextInput
                            fullWidth
                            required
                            disabled={isReadOnly || isSubmitting}
                            label="Sender name"
                            name="sender_name"
                        />
                    </Box>
                    <Box display="flex" gap={2}>
                        <TextInput
                            flex={isReadOnly ? 2 : 4}
                            required
                            disabled={isReadOnly || isSubmitting}
                            label="Client ID"
                            name="client_id"
                        />
                        <TextInput
                            flex={6}
                            required
                            disabled={isReadOnly || isSubmitting}
                            label="Password"
                            name="client_password"
                            type={`${!isReadOnly && 'password'}`}
                        />
                        {!isReadOnly && (
                            <TextInput
                                flex={6}
                                required
                                disabled={isReadOnly || isSubmitting}
                                label="Confirm Password"
                                name="confirm_password"
                                type={`${!isReadOnly && 'password'}`}
                            />
                        )}
                    </Box>
                    {!isReadOnly && (
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            my={1}
                        >
                            <StyledAdminFilledButton
                                disabled={!dirty || isSubmitting}
                                type="submit"
                            >
                                Edit
                            </StyledAdminFilledButton>
                            <StyledAdminFilledButton
                                onClick={() => {
                                    handleResetPerSender(formData.id)
                                    handleReset()
                                }}
                            >
                                Reset
                            </StyledAdminFilledButton>
                        </Box>
                    )}
                </Form>
            )}
        </Formik>
    )
}

export default EditSenderForm
