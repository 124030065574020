import { addSender } from 'api/clients/addSender'
import React, { useState } from 'react'
import useToast from 'utils/useToast'
import SenderForm from '../_components/SenderForm/AddSenderForm'
import { ISenderForm } from '../_components/SenderForm/senderForm.interface'
import { IAddSenderProps } from './addSender.interface'

const AddSender = (props: IAddSenderProps) => {
    const { handleModalClose, rowId } = props

    const senderInitialValues = {
        sender_name: '',
        account_id: rowId,
        client_password: '',
        confirm_password: '',
        client_id: ''
    }

    const [formData, setFormdata] = useState<ISenderForm>(senderInitialValues)

    const handleAddSenderRequest = async () => {
        try {
            await addSender({
                sender_name: formData.sender_name,
                account_id: senderInitialValues.account_id,
                client_password: formData.client_password,
                client_id: formData.client_id
            })

            handleModalClose()
            useToast().success('Sender name successfully added')
        } catch (error: any) {
            useToast().error(error.message)
        }
    }
    return (
        <SenderForm
            formTitle="New Sender Name"
            formData={formData}
            setFormData={setFormdata}
            confirmationText="Are you sure you want to add sender name?"
            isReadOnly={false}
            handleModalClose={handleModalClose}
            handleSubmit={handleAddSenderRequest}
        />
    )
}

export default AddSender
