import { Box } from '@mui/material'
import { styled } from '@mui/system'

export const StyledBank = styled(Box)(({ theme }) => ({
    width: '15%',
    height: 105,
    background: theme.palette.secondary.main,
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
}))
export const StyledImportant = styled(Box)(({ theme }) => ({
    border: '0.5px solid #272E4C4E',
    background: theme.palette.common.white,
    width: '35%',
    height: 55,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}))
