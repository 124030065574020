// Absolute
import { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import usePagination from '@mui/material/usePagination'

// Relative
import { ICommonPaginationProps } from './commonPagination.interface'
import {
    StyledPageButtons,
    StyledPrevNextButton
} from './commonPagination.styled'
import { entries } from 'utils/app.const'
import Dropdown from '../Dropdown'

const CommonPagination: FC<ICommonPaginationProps> = (
    props: ICommonPaginationProps
) => {
    // Hooks
    const {
        total,
        currentLimit,
        handleOnPageSizeChange,
        onPageChange,
        currentPage,
        showOption
    } = props

    const getTotalPages = () => {
        const result = total / currentLimit

        return Math.ceil(result)
    }

    const getCurrentPageNumber = () => {
        return currentPage + 1
    }

    const { items } = usePagination({
        count: getTotalPages(),
        onChange: onPageChange
    })

    const isSelected = (page: number | null) => {
        return page === currentPage + 1
    }

    const isButtonDisabled = (type: string, page: number | null) => {
        if (type === 'previous') return currentPage === 0
        else if (type === 'next') return currentPage === getTotalPages() - 1
    }

    const handleButtonClick = (type: string) => {
        if (type === 'previous') {
            onPageChange(null, currentPage)
        } else if (type === 'next') {
            onPageChange(null, currentPage + 2)
        }
    }

    return (
        <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            mt={5}
        >
            <Grid item>
                <Typography>
                    <Typography>
                        {getCurrentPageNumber()} - {getTotalPages()} out of{' '}
                        {total}
                    </Typography>
                </Typography>
                <></>
            </Grid>
            {items.length > 2 && (
                <Grid item display="flex" alignItems="center" gap={1}>
                    {showOption && (
                        <>
                            <Typography>Show</Typography>
                            <Dropdown
                                value={currentLimit}
                                options={entries.map((entry, index) => ({
                                    label: entry.toString(),
                                    value: entry.toString()
                                }))}
                                onChange={handleOnPageSizeChange}
                            />
                        </>
                    )}

                    <Grid>
                        <StyledPrevNextButton
                            buttonType="first"
                            disabled={currentPage === 0}
                            onClick={() => onPageChange(null, 1)}
                        >
                            First
                        </StyledPrevNextButton>

                        {items.map(
                            (
                                { page, type, selected, disabled, ...item },
                                index
                            ) => {
                                let children = null
                                if (
                                    type === 'start-ellipsis' ||
                                    type === 'end-ellipsis'
                                ) {
                                    children = '...'
                                } else if (type === 'page') {
                                    children = (
                                        <StyledPageButtons
                                            type="button"
                                            disabled={isSelected(page)}
                                            style={{
                                                fontWeight: isSelected(page)
                                                    ? 'bold'
                                                    : undefined,
                                                textDecoration: isSelected(page)
                                                    ? 'underline'
                                                    : undefined
                                            }}
                                            islast={
                                                Number(page) === props.count
                                                    ? '1'
                                                    : '0'
                                            }
                                            {...item}
                                        >
                                            {page}
                                        </StyledPageButtons>
                                    )
                                } else {
                                    children = (
                                        <StyledPrevNextButton
                                            buttonType={type}
                                            disabled={isButtonDisabled(
                                                type,
                                                page
                                            )}
                                            {...item}
                                            onClick={() =>
                                                handleButtonClick(type)
                                            }
                                        >
                                            {type === 'previous' && 'Prev'}
                                            {type === 'next' && 'Next'}
                                        </StyledPrevNextButton>
                                    )
                                }
                                return <span key={index}>{children}</span>
                            }
                        )}
                        <StyledPrevNextButton
                            buttonType="last"
                            disabled={currentPage === getTotalPages() - 1}
                            onClick={() => onPageChange(null, getTotalPages())}
                        >
                            Last
                        </StyledPrevNextButton>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default CommonPagination
