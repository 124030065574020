import React, { FC } from 'react'
import { Chip } from '@mui/material'
import CampaignsTable from '../campaignsTable'
import { ongoingColumms } from './ongoing.const'

const Ongoing: FC = () => {
    return (
        <>
            <CampaignsTable
                title="Ongoing"
                columns={[
                    ...ongoingColumms,
                    {
                        columnName: 'campaign_status' as const,
                        columnLabel: 'Status',
                        colSpan: 4,
                        render: () => {
                            return (
                                <Chip
                                    label="Sending"
                                    size="small"
                                    sx={{ fontSize: 11 }}
                                />
                            )
                        }
                    }
                ]}
                status={0}
                hasRefresh
            />
        </>
    )
}
export default Ongoing
