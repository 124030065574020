import { styled } from '@mui/system'

export const StyledWelcomeCard = styled('div')(({ theme }) => ({
    width: '100%',
    background: theme.palette.common.white,
    padding: theme.spacing(3),
    borderRadius: 10
}))
export const StyledButton = styled('button')({
    border: 'none',
    background: 'none',
    width: '100%',
    cursor: 'pointer'
})
