import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Button from 'components/global/Button'
import { IStep4Props } from './step4.interface'
import { summaryContents } from '../send.conts'

const Step4: FC<IStep4Props> = (props: IStep4Props) => {
    return (
        <>
            <Typography variant="h2" color="primary" mb={2}>
                Sending!
            </Typography>

            <Typography sx={{ fontStyle: 'italic' }}>
                To check status, go to <Link to="/campaigns">Campaigns.</Link>
            </Typography>
            {props.detailProps.scheduled == null ? (
                <></>
            ) : (
                <Grid container spacing={2} mt={0.1}>
                    <Grid item xs={12} lg={2}>
                        <Typography>Scheduled Date of Blast</Typography>
                    </Grid>
                    <Grid item xs={10} pr={10}>
                        <Typography variant="h4" color="primary">
                            {props.detailProps.scheduled}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {summaryContents.map(({ key, value }) => (
                <Grid container spacing={2} mt={0.1} key={key}>
                    <Grid item xs={12} lg={2}>
                        <Typography>{value}</Typography>
                    </Grid>

                    <Grid item xs={10} pr={10}>
                        <Typography variant="h4" color="primary">
                            {props.detailProps[
                                key as keyof typeof props.detailProps
                            ] || 'N/A'}
                        </Typography>
                    </Grid>
                </Grid>
            ))}

            <Box display="flex" justifyContent="center" gap={5} mt={10}>
                <Button onClick={props.buttonProps.onBackToMain}>
                    Back to Main
                </Button>
            </Box>
        </>
    )
}

export default Step4
