import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react'
import { Grid, Typography, Button, Box, SelectChangeEvent } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import CommonTable from 'components/global/commonTable'
import TextInput from 'components/global/TextInput'
import Dropdown from 'components/global/Dropdown'
import {
    defaultRowsPerPage,
    filterBy,
    isCurrentPage,
    isEmpty,
    parseIntConfig
} from 'utils/app.const'
import { IMessagesProps } from './messages.interface'
import { addMessages } from 'api/savemessage/addmessage'
import { getMessage } from 'api/savemessage/getmessage'
import { SavedMessagesColumn } from '../saved.const'
import CommonModal from 'components/global/commonModal'
import CommonTextArea from 'components/global/commonTextArea'
import { IMessage } from 'utils/app.interface'
import { deleteMessage, editMessage } from 'api/sendsms'
import useToast from 'utils/useToast'
import CommonConfirmation from 'components/global/commonConfirmation'
import Search from 'components/global/Search'

const CommonButton = require('components/global/Button').default

type Rows = Array<IMessagesProps>

const Messages: FC = () => {
    const [error, setError] = useState(null)
    const [mes, setMes] = useState<Rows>([])
    const [filter, setFilter] = useState('desc')
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const [editOpen, setEditOpen] = useState(false)
    const [editTitle, setEditTitle] = useState('')
    const [messageEdit, setMessageEdit] = useState('')
    const [selectedMessage, setSelectedMessage] = useState<IMessage | null>(
        null
    )
    const [title, setTitle] = useState('')
    const [message, setMessage] = useState('')
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [search, setSearch] = useState('')

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
    const [recordLength, setRecordLength] = useState(0)

    const handleChangePage = (
        event: ChangeEvent<unknown> | null,
        newPage: number
    ) => {
        setPage(newPage - 1)
    }

    const handleChangeRowsPerPage = (event: SelectChangeEvent<string>) => {
        setRowsPerPage(parseInt(event.target.value, parseIntConfig))
        setPage(0)
    }

    const paginationPayload = {
        currentPage: page,
        currentLimit: rowsPerPage,
        total: recordLength,
        onPageChange: handleChangePage,
        handleOnPageSizeChange: handleChangeRowsPerPage,
        showOption: true
    }

    useEffect(() => {
        getData()
    }, [filter, page, rowsPerPage])

    useEffect(() => {
        if (isEmpty(search)) {
            getData()
        }
    }, [search])

    const getData = async () => {
        try {
            const res: any = await getMessage({
                order: filter,
                limit: rowsPerPage,
                page: isCurrentPage(page, search),
                title: search
            })
            setMes(res.data.data)
            setRecordLength(res.data.total)
        } catch (error: any) {
            setError(error)
        }
    }

    const handleOnFilterChange = (event: any) => {
        const {
            target: { value }
        } = event
        setFilter(value)
    }

    const handleEditOpen = (message: IMessage) => {
        setSelectedMessage(message)
        setEditTitle(message.title)
        setMessageEdit(message.message)
        setEditOpen(true)
    }

    const handleEditClose = () => {
        setEditOpen(false)
    }

    const handleAddMessage = async (event: FormEvent) => {
        event.preventDefault()
        try {
            await addMessages({ title, message })
            setOpen(false)
            useToast().success('Successfully added a message')
            getData()
        } catch (error: any) {
            setError(error.message)
        }
    }

    const handleOnChangeEdit = (event: any) => {
        const {
            target: { value }
        } = event
        setMessageEdit(value)
    }

    const handleEditMessage = async () => {
        if (!selectedMessage) return
        try {
            await editMessage({
                id: selectedMessage.id,
                title: editTitle,
                message: messageEdit
            })
            setEditOpen(false)
            useToast().success('Successfully edited a message')
            getData()
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    const handleConfirmationClose = () => {
        setSelectedMessage(null)
        setShowConfirmation(false)
    }

    const selectDelete = (message: IMessage) => {
        setSelectedMessage(message)
        setShowConfirmation(true)
    }

    const handleDelete = async () => {
        if (!selectedMessage) return
        try {
            await deleteMessage([selectedMessage.id])
            useToast().success('Delete success')
            setShowConfirmation(false)
            getData()
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    const addTemplateDisabled = (): boolean => {
        return title.length === 0 || message.length === 0
    }

    const editTemplateDisabled = (): boolean => {
        return editTitle.length === 0 || messageEdit.length === 0
    }

    const handleSearch = (e: FormEvent) => {
        e.preventDefault()
        getData()
    }
    return (
        <>
            <Grid container gap={1} justifyContent="flex-end">
                <Grid
                    item
                    xs={3}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    gap={1}
                >
                    <Typography variant="h6">Sort By</Typography>
                    <Dropdown
                        value={filter}
                        options={filterBy}
                        onChange={handleOnFilterChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Search
                        handleSearchChange={setSearch}
                        handleSubmit={handleSearch}
                    />
                </Grid>
            </Grid>

            <Grid container gap={2} alignItems="center" mb={2}>
                <Typography variant="h2" color="primary">
                    Message Template
                </Typography>
                <Button
                    startIcon={<AddIcon />}
                    size="small"
                    sx={{ background: '#FFF', padding: '5px 20px' }}
                    onClick={handleOpen}
                >
                    New Template
                </Button>
            </Grid>

            <CommonTable
                columns={[
                    ...SavedMessagesColumn,
                    {
                        columnName: 'action' as const,
                        columnLabel: '',
                        colSpan: 4,
                        render: (row: IMessagesProps) => {
                            return (
                                <Button
                                    onClick={() => handleEditOpen(row)}
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '20px',
                                        background: '#FFF'
                                    }}
                                >
                                    Edit
                                </Button>
                            )
                        }
                    },
                    {
                        columnName: 'action' as const,
                        columnLabel: '',
                        colSpan: 4,
                        render: (row: IMessagesProps) => {
                            return (
                                <Button
                                    onClick={() => selectDelete(row)}
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '20px',
                                        background: '#FFF'
                                    }}
                                >
                                    Delete
                                </Button>
                            )
                        }
                    }
                ]}
                rows={mes}
                paginationPayload={paginationPayload}
            />

            {/* Insert Pagination Ui */}

            <CommonModal open={open} close={handleClose}>
                <Box sx={{ maxWidth: 570 }}>
                    <Box>
                        <form onSubmit={handleAddMessage}>
                            <Typography
                                variant="h2"
                                color="primary"
                                gutterBottom
                            >
                                Add Template
                            </Typography>
                            <Typography gutterBottom>Message Title</Typography>
                            <Box mb={2}>
                                <TextInput
                                    fullWidth
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </Box>
                            <Box mb={3}>
                                <CommonTextArea
                                    rows={4}
                                    counterGap={2}
                                    textAreaValue={message}
                                    onChangeTextAreaValue={(e) =>
                                        setMessage(e.target.value)
                                    }
                                />
                            </Box>
                            {error && (
                                <Typography
                                    mt={2}
                                    color="red"
                                    fontSize={12}
                                    align="center"
                                >
                                    {error}
                                </Typography>
                            )}
                            <Box display="flex" justifyContent="center">
                                <CommonButton
                                    disabled={addTemplateDisabled()}
                                    type="submit"
                                >
                                    Submit
                                </CommonButton>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </CommonModal>

            {/* edit Pagination Ui */}

            <CommonModal open={editOpen} close={handleEditClose}>
                <Box sx={{ maxWidth: 570 }}>
                    <Typography variant="h2" color="primary" gutterBottom>
                        Edit Template
                    </Typography>
                    <Typography gutterBottom>Message Title</Typography>
                    <Box mb={2}>
                        <TextInput
                            fullWidth
                            value={editTitle}
                            onChange={(e) => setEditTitle(e.target.value)}
                        />
                    </Box>
                    <Box mb={3}>
                        <CommonTextArea
                            rows={4}
                            textAreaValue={messageEdit}
                            onChangeTextAreaValue={handleOnChangeEdit}
                        />
                    </Box>
                    <Box display="flex" justifyContent="center">
                        <CommonButton
                            disabled={editTemplateDisabled()}
                            onClick={handleEditMessage}
                        >
                            Submit
                        </CommonButton>
                    </Box>
                </Box>
            </CommonModal>

            {/* DELETE CONFIRMATION */}
            <CommonConfirmation
                open={showConfirmation}
                close={handleConfirmationClose}
                confirmationText="Warning!"
            >
                <Box>
                    <Typography align="center" color="red">
                        Are you sure you want to delete this message?
                    </Typography>

                    <Box display="flex" justifyContent="center" gap={5} mt={5}>
                        <CommonButton onClick={handleConfirmationClose}>
                            Cancel
                        </CommonButton>
                        <CommonButton onClick={handleDelete}>
                            Proceed
                        </CommonButton>
                    </Box>
                </Box>
            </CommonConfirmation>
        </>
    )
}

export default Messages
