import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { hours, minutes, meridiem } from 'utils/app.const'
import Dropdown from '../Dropdown'
import TextInput from '../TextInput'
import { IDateTimePicker } from './dateTimePicker.interface'

const DateTimePicker: FC<IDateTimePicker> = (props: IDateTimePicker) => {
    return (
        <>
            <Box display="flex" gap={5} mt={3}>
                <Box>
                    <Typography>Date</Typography>
                    <DesktopDatePicker
                        PopperProps={{
                            sx: {
                                span: {
                                    color: '#000'
                                }
                            }
                        }}
                        className="filterdate"
                        value={props.date}
                        onChange={props.onDateChange}
                        minDate={new Date()}
                        renderInput={(params: any) => (
                            <TextInput autoComplete="off" {...params} />
                        )}
                    />
                </Box>
                <Box>
                    <Typography>Time</Typography>
                    <Box display="flex" gap={2} alignItems="center">
                        <Dropdown
                            options={hours()}
                            value={props.hour}
                            onChange={props.onHourChange}
                        />
                        <Typography>:</Typography>
                        <Dropdown
                            options={minutes()}
                            value={props.minute}
                            onChange={props.onMinuteChange}
                        />
                        <Dropdown
                            options={meridiem}
                            value={props.meridiem}
                            onChange={props.onMeridiemChange}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default DateTimePicker
