import { createContext, FC, ReactNode, useContext, useReducer } from 'react'
import { IBalanceDispatch, IBalanceState } from './balance.interface'

// Action types
const SET_BALANCE = 'SET_BALANCE'

const initialState: IBalanceState = {
    amount: 0
}

const BalanceStateContext = createContext<IBalanceState>(initialState)
const BalanceDispatchContext = createContext<IBalanceDispatch>(
    {} as IBalanceDispatch
)

const balanceReducer = (
    state = initialState,
    action: { type: string; payload: IBalanceState }
) => {
    switch (action.type) {
        case SET_BALANCE:
            return {
                ...state,
                amount: action.payload.amount
            }
        default:
            throw new Error(`Unknown action type ${action.type}`)
    }
}

export const BalanceProvider: FC<{ children: ReactNode }> = (
    props
): JSX.Element => {
    const [state, dispatch] = useReducer(balanceReducer, initialState)
    const setBalance = (amount: number) =>
        dispatch({ type: SET_BALANCE, payload: { amount } })

    return (
        <BalanceDispatchContext.Provider value={{ setBalance }}>
            <BalanceStateContext.Provider value={state}>
                {props.children}
            </BalanceStateContext.Provider>
        </BalanceDispatchContext.Provider>
    )
}

export const useBalanceState = () => useContext(BalanceStateContext)
export const useBalanceDispatch = () => useContext(BalanceDispatchContext)
