import React from 'react'
import { scheduledColumns } from './scheduled.const'
import Note from 'pages/campaigns/scheduled/Note'
import ScheduledHistoryTable from './scheduledHistoryTable/ScheduledHistoryTable'

const message =
    'Please note that amount due will be debited from your wallet/credit card BEFORE sending the SMS/Digital product. Failure to fund your wallet or maintain an active credit card will invalidate the entire transaction.'

const Scheduled = () => {
    return (
        <>
            <ScheduledHistoryTable
                title="Scheduled xBlast"
                Note={<Note message={message} />}
                columns={scheduledColumns}
            />
        </>
    )
}

export default Scheduled
