import { privateClient } from './interceptor/request.interceptor'

export const getHistories = async (reqPayload: {
    s: string
    t: string
    d: string | null
    o: string
    in: number
    page: number
    status: number
    k: string
}) => {
    try {
        return await privateClient({
            url: '/history',
            method: 'get',
            params: {
                ...reqPayload
            }
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}

export const getRecipients = async (reqPayload: {
    id: number
    in: number
    page: number
    key: string
    status: string | null
}) => {
    try {
        return await privateClient({
            url: '/smsQueue/list',
            method: 'get',
            params: {
                ...reqPayload
            }
        })
    } catch (error) {
        return error
    }
}
