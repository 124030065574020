import { FC, useState } from 'react'

import SavedGroupTable from 'components/global/savedGroupTable'
import { filterBy } from 'utils/app.const'
import { SavedGroupsColumn } from '../saved.const'

const Groups: FC = () => {
    // Hooks
    const [filter, setFilter] = useState('newest')

    // Handlers
    const handleOnFilterChange = (event: any) => {
        const {
            target: { value }
        } = event
        setFilter(value)
    }

    return (
        <>
            <SavedGroupTable
                columns={SavedGroupsColumn}
                filter={filter}
                filterBy={filterBy}
                filterChange={handleOnFilterChange}
                addGroup
                hasActions
                showOption
            />
        </>
    )
}

export default Groups
