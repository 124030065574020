import { AxiosResponse } from 'axios'
import { ISenderName } from 'utils/app.interface'
import { privateClient } from '../interceptor/request.interceptor'

export const getSenderNames = async (param: { id: number }) => {
    try {
        return (await privateClient({
            url: `/sender/names?id=${param.id}`,
            method: 'get'
        })) as unknown as AxiosResponse<ISenderName[]>
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}
