import { privateClient } from '../interceptor/request.interceptor'

export const getMessage = async (reqPayload: {
    order: string
    limit: number
    page: number
    title: string
}) => {
    try {
        return await privateClient({
            url: '/message',
            method: 'get',
            params: { ...reqPayload }
        })
    } catch (error: any) {
        throw new Error(error.response.data.message || error.message)
    }
}
