import { styled } from '@mui/system'
import { Typography } from '@mui/material'

export const StyledToastSuccessTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.success.main
}))

export const StyledToastErrorTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}))
