import { privateClient } from 'api/interceptor/request.interceptor'
import { AxiosResponse } from 'axios'
import React from 'react'

/**
 * @param [number] {id} ID of the scheduled history row/record.
 */
type smsQueueSchedListReqPayload = {
    id: number
}

type smsQueueSchedListResponse = {
    id: number
    name?: string
    description?: string
    sms_history_id: number
    mobile: string[]
    date: string
    status: number
    message: string
    reason?: string
    statusDescription?: string
    totalSmsQueue: number
}

async function smsQueueScheduledList(reqPayload: smsQueueSchedListReqPayload) {
    return (await privateClient({
        params: reqPayload,
        url: 'smsQueue/scheduled/list',
        method: 'GET'
    })) as unknown as AxiosResponse<smsQueueSchedListResponse>
}

export default smsQueueScheduledList
