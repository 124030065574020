import React, { FC, useState, useCallback, useEffect } from 'react'
import UploadIcon from '@mui/icons-material/Upload'
import DeleteIcon from '@mui/icons-material/Delete'
import { FileRejection, useDropzone } from 'react-dropzone'
import {
    SelectChangeEvent,
    Box,
    Typography,
    Button as MButton,
    Chip
} from '@mui/material'

import Button from 'components/global/Button'
import CommonModal from 'components/global/commonModal'
import TextInput from 'components/global/TextInput'
import CommonConfirmation from 'components/global/commonConfirmation'
import { filterBy, REGEX } from 'utils/app.const'
import { StyledUpload } from './step2.styled'
import {
    filetypeErrorText,
    formatErrorText,
    inputNumberLabel,
    limitErrorText,
    maxNumber,
    SavedGroupsColumns
} from './step2.cont'
import { IStep2Props } from './step2.interface'
import SavedGroupTable from 'components/global/savedGroupTable'

import useToast from 'utils/useToast'
import { getMembers } from 'api/members/getMember'
import { IGroups, IMembers } from 'utils/app.interface'
import ToolTip from 'components/global/commonToolTip'
import { isValidNumber } from 'utils'

const masNumber = 2147483645

const Step2: FC<IStep2Props> = (props: IStep2Props) => {
    const {
        onPreview,
        setPhoneValueNumbersProps,
        phoneValueNumbersProps,
        csvFile,
        setCsvFile,
        selectedGroup,
        setSelectedGroup,
        setGroupNumbers
    } = props
    // Hooks
    const [modalOpen, setOpen] = useState(false)
    const [filter, setFilter] = useState('')
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [tempFile, setTempFile] = useState(null)
    const [numbers, setNumbers] = useState(
        phoneValueNumbersProps.join('\n') || ''
    )
    const [hasNumbersError, setHasNumbersError] = useState('')
    const [hasFileError, setHasFileError] = useState<string>('')

    const [error] = useState(null)

    useEffect(() => {
        if (exceedLimit()) {
            setHasNumbersError(limitErrorText)
        }
    }, [numbers])
    const getGroupMembers = async (GroupID: string) => {
        try {
            const res: any = await getMembers({
                groupId: GroupID,
                limit: masNumber,
                page: 0,
                search: ''
            })
            const groupNums = res.data.data.map((groupNum: IMembers) =>
                groupNum.mobile_number.toString()
            )
            setGroupNumbers(groupNums.length)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    // Static Tooltip data
    const rules = {
        title: 'Rules',
        details: [
            'Use “_” or camel case to separate two or more variable name/words.',
            'File columns only accepts numbers in “09xxxxxxxxx” or “9xxxxxxxxx” format.'
        ]
    }
    const notes = {
        title: 'Notes',
        details: [
            'If there is/are empty field/s in the csv file, the message/s for the corresponding number/s will not be sent.'
        ]
    }

    // Handlers
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && numbers.includes('\n'))
            setNumbers((numbers) => numbers.trim())
        else setHasNumbersError('')
    }

    const handleOnFilterChange = (event: SelectChangeEvent) => {
        const {
            target: { value }
        } = event
        setFilter(value)
    }

    const handleOnChangeNumbers = (event: any) => {
        const {
            target: { value }
        } = event

        if (hasFileError) setHasFileError('')

        setNumbers(value)
        const arrValue = value.split('\n')
        if (arrValue.length < 2) {
            if (isValidNumber(value)) return
            setHasNumbersError(formatErrorText)
        }
        checkNumbers(arrValue)
    }

    const checkNumbers = (value: Array<string>) => {
        let hasChange = false
        const result = value
            .filter((number) => number.length > 0)
            .map((number) => {
                if (REGEX.MOBILE_NUMBER_FORMAT.test(number)) {
                    if (REGEX.INTERNATIONAL_DIAL_CODE.test(number)) {
                        number = number.replace('+63', '0')
                        hasChange = true
                    }
                    return number
                } else {
                    setHasNumbersError(formatErrorText)
                    return number
                }
            })
        if (hasChange) {
            setNumbers([...result, ''].join('\n'))
        }
    }
    const exceedLimit = () => numbers.split('\n').length > maxNumber.value

    const onDrop = useCallback(
        (acceptedFiles: any, fileRejections: FileRejection[]) => {
            if (fileRejections.length > 0) {
                setHasFileError(filetypeErrorText)
                return
            }

            setHasFileError('')
            setShowConfirmation(true)
            setTempFile(acceptedFiles)
        },
        []
    )

    const handleConfirmationProceed = () => {
        setCsvFile(tempFile)
        setNumbers('')
        setSelectedGroup(null)
        setShowConfirmation(false)
    }

    const { getInputProps, getRootProps, open } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
            'application/*': ['.xls', '.xlsx']
        },
        noClick: true
    })

    const handleConfirmationClose = (): void => {
        setShowConfirmation(false)
    }

    const handlePreview = () => {
        if (numbers.length > 0) {
            setPhoneValueNumbersProps(
                numbers.split('\n').filter((n) => n.length > 0)
            )
        }
        onPreview()
    }
    const handleSelectedGroup = (value: IGroups) => {
        setPhoneValueNumbersProps([])
        setNumbers('')
        setSelectedGroup(value)
        setOpen(false)
        getGroupMembers(value.id)
        setHasNumbersError('')
    }

    const handleDelete = () => {
        setSelectedGroup(null)
        setGroupNumbers(null)
    }

    const isPreviewDisabled = () => {
        if (csvFile) return false

        const invalid =
            hasNumbersError.length > 0 ||
            hasFileError.length > 0 ||
            (numbers.trim().length === 0 && !selectedGroup)

        return invalid
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h2" color="primary" gutterBottom>
                    Send to the following mobile numbers
                </Typography>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box sx={{ width: '30%', position: 'relative' }}>
                        <Typography gutterBottom>
                            Input mobile numbers
                        </Typography>
                        <TextInput
                            fullWidth
                            multiline
                            rows={5}
                            error={hasNumbersError.length > 0}
                            value={numbers || ''}
                            onChange={handleOnChangeNumbers}
                            disabled={csvFile !== null || !!selectedGroup}
                            onKeyDown={handleKeyDown}
                        />
                        <Box
                            sx={{ position: 'absolute', width: '100%' }}
                            gap={5}
                            mt={1}
                            display="flex"
                            justifyContent="space-between"
                        >
                            {hasNumbersError ? (
                                <Typography
                                    fontSize={11}
                                    color="red"
                                    fontStyle="italic"
                                >
                                    {hasNumbersError}
                                </Typography>
                            ) : (
                                <Typography
                                    fontSize={11}
                                    color="primary"
                                    fontStyle="italic"
                                >
                                    {selectedGroup
                                        ? `Selected Group: ${selectedGroup?.name}`
                                        : inputNumberLabel}
                                </Typography>
                            )}
                            <Typography
                                fontSize={11}
                                fontStyle="italic"
                                color={exceedLimit() ? 'red' : 'black'}
                            >
                                {numbers.split('\n').length - 1}/
                                {maxNumber.label}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        justifyContent="center"
                        sx={{ width: '30%' }}
                    >
                        <Typography>OR</Typography>
                    </Box>

                    {/* FILE UPLOAD */}
                    <Box sx={{ width: '30%' }}>
                        <Typography gutterBottom>
                            Upload your file{' '}
                            <ToolTip rules={rules} notes={notes} />
                        </Typography>
                        <StyledUpload {...getRootProps()}>
                            <input {...getInputProps()} />
                            <>
                                {csvFile ? (
                                    <>
                                        <Typography mb={2}>
                                            {csvFile[0].name}
                                        </Typography>
                                        <MButton
                                            variant="outlined"
                                            color="error"
                                            startIcon={<DeleteIcon />}
                                            onClick={() => setCsvFile(null)}
                                        >
                                            Delete
                                        </MButton>
                                    </>
                                ) : (
                                    <Box
                                        onClick={open}
                                        component="div"
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <UploadIcon />
                                        <Typography>
                                            Upload XLS
                                            <br /> or CSV File
                                        </Typography>
                                    </Box>
                                )}
                            </>
                        </StyledUpload>
                        {/* upload file error message */}
                        <Typography
                            fontSize={11}
                            color="red"
                            fontStyle="italic"
                        >
                            {hasFileError}
                        </Typography>
                    </Box>
                </Box>

                <Box
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                    gap={2}
                    mb={5}
                    mt={5}
                >
                    {selectedGroup && (
                        <Chip
                            label={selectedGroup.name}
                            onClick={() => null}
                            onDelete={handleDelete}
                        />
                    )}
                    <Button onClick={handleOpen} disabled={csvFile !== null}>
                        SAVED GROUPS
                    </Button>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Button
                        onClick={handlePreview}
                        disabled={isPreviewDisabled()}
                    >
                        PREVIEW
                    </Button>
                    {error && (
                        <Typography
                            mt={2}
                            color="red"
                            fontSize={12}
                            align="center"
                        >
                            {error}
                        </Typography>
                    )}
                </Box>
                <CommonModal open={modalOpen} close={handleClose}>
                    <>
                        <Box sx={{ width: 752 }}>
                            <SavedGroupTable
                                columns={SavedGroupsColumns}
                                filter={filter}
                                filterBy={filterBy}
                                filterChange={handleOnFilterChange}
                                rowClick={(value: IGroups) => {
                                    handleSelectedGroup(value)
                                }}
                            />
                        </Box>
                    </>
                </CommonModal>
            </Box>

            {/* Confirmation modal */}
            <CommonConfirmation
                open={showConfirmation}
                close={handleConfirmationClose}
                confirmationText="Warning!"
            >
                <Box>
                    <Typography align="center" color="red">
                        Uploading File will remove inputted mobile numbers and
                        selected saved group (if theres any).
                    </Typography>

                    <Box display="flex" justifyContent="center" gap={5} mt={5}>
                        <Button onClick={handleConfirmationClose}>
                            Cancel
                        </Button>
                        <Button onClick={handleConfirmationProceed}>
                            Proceed
                        </Button>
                    </Box>
                </Box>
            </CommonConfirmation>
        </>
    )
}

export default Step2
