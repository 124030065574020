import Page404 from 'pages/errorPages/Page404'
import { RouteObject } from 'react-router-dom'

/** Pages */
import AdminLogin from 'pages/admin/login'
import Login from 'pages/login'
import { AdminPaths } from 'utils/app.const'

const publicRoutes: RouteObject[] = [
    {
        path: `/`,
        element: <Login />
    },
    {
        path: AdminPaths.LOGIN,
        element: <AdminLogin />
    },
    {
        path: '/*',
        element: <Page404 />
    }
]
export default publicRoutes
