import { styled } from '@mui/system'
import ModalUnstyled from '@mui/base/ModalUnstyled'
import CloseIcon from '@mui/icons-material/Close'
import { Box } from '@mui/material'
import Button from 'components/global/Button'

export const StyledModal = styled(ModalUnstyled)({
    position: 'fixed',
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
})

export const modalBackground = styled('div')({
    zIndex: -1,
    position: 'fixed',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    WebkitTapHighlightColor: 'transparent'
})

export const StyledModalBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    margin: 'auto',
    width: '100%',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '20px'
}))

export const StyledModalBoxHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3)
}))

export const StyledModalBody = styled('div')(({ theme }) => ({
    padding: `0px ${theme.spacing(6)}  ${theme.spacing(6)} ${theme.spacing(6)}`,
    heigth: 'auto',
    width: 'auto'
}))

export const StyledModalCloseIcon = styled(CloseIcon)(() => ({
    '&:hover': {
        cursor: 'pointer'
    }
}))
export const StyledModalSendIcon = styled(Button)(() => ({
    '&:hover': {
        cursor: 'pointer'
    }
}))
