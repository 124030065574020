import { styled } from '@mui/system'

export const StyledInfoIcon = styled('img')(() => ({
    '&:hover': {
        cursor: 'pointer'
    },
    objectFit: 'cover'
}))

export const StyledTooltip = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'inline-block',
    top: '0.1em',
    left: '0.1em'
}))

export const StyledToolTipDiv = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    position: 'absolute',
    zIndex: 3,
    padding: '0.75em 0.5em 0.5em 0.5em',
    borderRadius: '0.2em',
    width: '25rem',
    marginTop: '0.3em',
    left: '0'
}))
export const StyledTooltipDivArrow = styled('span')(({ theme }) => ({
    cursor: 'pointer',
    content: '" "',
    width: '0',
    height: '0',
    borderLeft: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: `5px solid ${theme.palette.common.white}`,
    position: 'absolute',
    top: '-5px',
    left: '0'
}))

export const StyledToolTipBox = styled('div')(({ theme }) => ({
    marginTop: '0.2em',
    marginBottom: '0.2em'
}))

export const StyledToolTipHeader = styled('div')(({ theme }) => ({
    fontWeight: 800,
    color: '#001AA2',
    marginBottom: '0.2em'
}))

export const StyledToolTipBody = styled('div')(({ theme }) => ({
    paddingLeft: '0.8em'
}))

export const StyledToolTipDot = styled('div')(({ theme }) => ({
    height: '3px',
    width: '3px',
    backgroundColor: 'black',
    borderRadius: '5em',
    display: 'inline-block',
    marginRight: '0.5em',
    marginBottom: '0.1em'
}))
