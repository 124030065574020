import React, { useEffect, useState , SyntheticEvent } from 'react'
import { Box, Tabs } from '@mui/material'
import useBasePath from 'hooks/useBasePath'
import { useNavigate } from 'react-router-dom'
import { TabProps } from './commonTabComponent.interface'
import { StyledTab } from './commonTabComponent.styled'

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}

export default function BasicTabs(props: TabProps) {
    const { tabs } = props
    const navigate = useNavigate()
    const basePath = useBasePath()
    const [value, setValue] = useState(basePath)

    useEffect(() => {
        setValue(basePath)
    }, [basePath])

    const handleChange = (event: SyntheticEvent, newValue: any) => {
        setValue(newValue)
        navigate(newValue)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Tabs value={value} onChange={handleChange}>
                    {tabs.map(({ label, path }, index) => {
                        return (
                            <StyledTab
                                label={label}
                                key={index}
                                value={path}
                                {...a11yProps(index)}
                            />
                        )
                    })}
                </Tabs>
            </Box>
        </Box>
    )
}
