export const myAccountContentLeft = [
    {
        key: 'company_name',
        value: 'COMPANY NAME',
        temporaryValue: 'Xurpas Inc.'
    },
    {
        key: 'contact_name',
        value: 'CONTACT PERSON',
        temporaryValue: 'Vanessa Barrameda'
    },
    {
        key: 'contact_mobile',
        value: 'CONTACT NUMBER',
        temporaryValue: '09171238465'
    }
]
export const myAccountContentRight = [
    {
        key: 'company_address',
        value: 'COMPANY ADDRESS',
        temporaryValue:
            '7F Cambridge Center, 108 Tordesillas St., Salcedo Village, Makati City'
    },
    {
        key: 'contact_title',
        value: 'Title',
        temporaryValue: 'Marketing Manager'
    },
    {
        key: 'username',
        value: 'EMAIL ADDRESS',
        temporaryValue: 'vanessa@xurpas.com'
    }
]
export const historyColumns = [
    {
        columnLabel: 'Date Created',
        columnName: 'date_created' as const
    },
    // NEED FOR FUTURE
    // {
    //     columnLabel: 'Date of Xblast',
    //     columnName: 'dateOfXblast' as const
    // },
    {
        columnLabel: 'Campaign Name',
        columnName: 'campaign_name' as const
    },
    {
        columnLabel: 'Sender Name',
        columnName: 'sender_name' as const
    },
    {
        columnLabel: 'Total Amount',
        columnName: 'total_amount' as const
    },
    {
        columnLabel: 'Total Recipients',
        columnName: 'numberOfRecipients' as const
    }
]

export const filterBy = [
    {
        label: 'Type',
        value: 'type',
        children: [
            {
                label: 'SMS',
                value: 'type-sms'
            }
        ]
    },
    {
        label: 'Status',
        value: 'status',
        children: [
            {
                label: 'Sent',
                value: 'status-1'
            },
            {
                label: 'Sending',
                value: 'status-0'
            }
        ]
    }
]
export const sortBy = [
    {
        label: 'Date',
        value: 'date',
        children: [
            {
                label: 'Newest',
                value: 'date-new'
            },
            {
                label: 'Oldest',
                value: 'date-old'
            }
        ]
    },
    {
        label: 'Alphabetically',
        value: 'alpha',
        children: [
            {
                label: 'Aa - Zz',
                value: 'alpha-asc'
            },
            {
                label: 'Zz - Aa',
                value: 'alpha-desc'
            }
        ]
    }
]
