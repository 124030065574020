import { IHistory } from 'utils/app.interface'
import { campaignType } from '../campaigns.const'

export const ongoingColumms = [
    {
        columnLabel: 'Date Created',
        columnName: 'date_created' as const
    },
    {
        columnLabel: 'Campaign Name',
        columnName: 'campaign_name' as const
    },
    {
        columnLabel: 'Campaign Type',
        columnName: 'recipient_type' as const,
        render: (row: IHistory) => {
            return row.recipient_type === campaignType.SMS ? 'SMS' : 'Load'
        }
    },
    {
        columnLabel: 'Total Recipients',
        columnName: 'numberOfRecipients' as const
    },
    {
        columnLabel: 'Total Sent',
        columnName: 'sentCount' as const
    }
]
