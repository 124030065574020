import React, { FC, useState, useEffect, useRef } from 'react'
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    Typography,
    MenuItem,
    ListItemIcon,
    ListItemText,
    SelectChangeEvent,
    TextField
} from '@mui/material'
import { useTheme } from '@mui/system'
import {
    MenuProps,
    StyledCheckbox,
    StyledShowReport
} from 'admin/reports/reports.styled'
import { getAllCompanyNames } from 'api/reports/getReports'
import useToast from 'utils/useToast'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'

const Logs: FC = () => {
    const theme = useTheme()
    const [selected, setSelected] = useState<string[]>([])
    const [companyNames, setCompanyNames] = useState([])
    const dropdownRef = useRef<HTMLDivElement>(null)
    const [date, setDate] = useState(null)
    const handleChangeDate = (newValue: any) => {
        setDate(newValue)
    }
    const getCompanyNames = async () => {
        try {
            const res = await getAllCompanyNames()
            setCompanyNames(res.data)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }
    const isAllSelected =
        companyNames.length > 0 && selected.length === companyNames.length

    const handleChange = (event: SelectChangeEvent<typeof selected>) => {
        const {
            target: { value }
        } = event
        if (value[value.length - 1] === 'all') {
            setSelected(
                selected.length === companyNames.length ? [] : companyNames
            )
            return
        }
        setSelected(typeof value === 'string' ? value.split(',') : value)
    }
    useEffect(() => {
        getCompanyNames()
    }, [])
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Typography variant="h2" color="primary" mb={3}>
                    Admin Logs
                </Typography>
                <Box display="flex" gap={5} alignItems="center">
                    <Box>
                        <Typography sx={{ color: theme.palette.grey[100] }}>
                            Client Name
                        </Typography>
                        <div>
                            <FormControl sx={{ width: '300px' }}>
                                <InputLabel
                                    sx={{ color: theme.palette.grey[200] }}
                                >
                                    Select All
                                </InputLabel>
                                <Select
                                    multiple
                                    value={selected}
                                    onChange={handleChange}
                                    renderValue={(selected) =>
                                        selected.join(', ')
                                    }
                                    sx={{
                                        background: theme.palette.common.white,
                                        color: theme.palette.primary.main
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value="all">
                                        <ListItemIcon>
                                            <StyledCheckbox
                                                checked={isAllSelected}
                                                indeterminate={
                                                    selected.length > 0 &&
                                                    selected.length <
                                                        companyNames.length
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="Select All" />
                                    </MenuItem>
                                    {companyNames.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            <ListItemIcon>
                                                <StyledCheckbox
                                                    checked={
                                                        selected.indexOf(
                                                            option
                                                        ) > -1
                                                    }
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={option} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </Box>
                    <Box ref={dropdownRef}>
                        <Typography sx={{ color: theme.palette.grey[100] }}>
                            Date Range
                        </Typography>
                        <div style={{ position: 'relative' }}>
                            <DesktopDatePicker
                                PopperProps={{
                                    sx: {
                                        span: {
                                            color: theme.palette.common.black
                                        }
                                    }
                                }}
                                className="filterdate"
                                inputFormat="yyyy-MM-dd"
                                value={date}
                                onChange={handleChangeDate}
                                renderInput={(params: any) => (
                                    <TextField
                                        sx={{
                                            background:
                                                theme.palette.common.white
                                        }}
                                        autoComplete="off"
                                        {...params}
                                    />
                                )}
                            />
                        </div>
                    </Box>
                    <Box display="flex" gap={2}>
                        <StyledShowReport>Show Report</StyledShowReport>
                        <StyledShowReport>Reset Search</StyledShowReport>
                    </Box>
                </Box>
            </LocalizationProvider>
        </>
    )
}

export default Logs
