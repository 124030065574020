import { styled } from '@mui/system'

export const StyledContentToolBar = styled('div')(({ theme }) => ({
    background: theme.palette.tertiary.main,
    width: '100%',
    height: '100%',
    paddingBlock: theme.spacing(3),
    paddingInline: theme.spacing(6),
    boxShadow: '0px 3px 6px #00000029',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
}))
