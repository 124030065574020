import { Box } from '@mui/material'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import {
    StyledSidebarLayoutContainer,
    StyledSidebarLayoutMainContent
} from './sidebarLayout.styled'
// import Sidebar from './sidebar'
import { BalanceProvider } from 'context/balance'
import { getUser } from 'utils/token'
import useToast from 'utils/useToast'

type SidebarLayoutProps = {
    sidebar: JSX.Element
    toolbar: JSX.Element
}

const SidebarLayout = ({
    sidebar: Sidebar,
    toolbar: Toolbar
}: SidebarLayoutProps): JSX.Element => {
    const navigate = useNavigate()

    useEffect(() => {
        const intervalId = setInterval(checkUser, 3000)
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    const checkUser = () => {
        const user = getUser()

        if (!user) {
            navigate('/')
            useToast().error('You have been logged out due to duplicate login.')
        }
    }
    return (
        <BalanceProvider>
            <StyledSidebarLayoutContainer>
                <Box display="flex">
                    {Sidebar}
                    <StyledSidebarLayoutMainContent component="main">
                        {Toolbar}
                        <Box p={5}>
                            <Outlet />
                        </Box>
                    </StyledSidebarLayoutMainContent>
                </Box>
            </StyledSidebarLayoutContainer>
        </BalanceProvider>
    )
}

export default SidebarLayout
