import ClientContentToolBar from 'components/layout/contentToolBar/ClientContentToolbar'
import ClientSidebar from 'components/layout/sidebar/ClientSidebar'
import SidebarLayout from 'components/layout/sidebarLayout'
import History from 'pages/campaigns/history'
import Ongoing from 'pages/campaigns/ongoing'
import Scheduled from 'pages/campaigns/scheduled/Scheduled'
import ContactUs from 'pages/contactUs'
import FAQ from 'pages/faq'
import Home from 'pages/home'
import MyAccount from 'pages/myaccount'
import MyAccountProfile from 'pages/myaccount/myAccount'
import Saved from 'pages/saved'
import Groups from 'pages/saved/groups'
import AddMembersGroup from 'pages/saved/groups/add'
import Messages from 'pages/saved/messages'
import Send from 'pages/send'
import TopUp from 'pages/topup'
import { RouteObject } from 'react-router-dom'
import { AccountRoles } from 'utils/app.const'
import PrivateRoute from './privateRoute'

const homeRoute: RouteObject = {
    path: `${process.env.PUBLIC_URL}/home`,
    element: <Home />
}

const sendRoute: RouteObject = {
    path: `${process.env.PUBLIC_URL}/send`,
    element: <Send />
}

const campaignRoutes: RouteObject = {
    path: `${process.env.PUBLIC_URL}/campaigns`,
    element: <Saved />,
    children: [
        { index: true, element: <Ongoing /> },
        {
            path: 'scheduled',
            element: <Scheduled />
        },
        {
            path: 'history',
            element: <History />
        },
        {
            path: 'groups/:id',
            element: <AddMembersGroup />
        }
    ]
}

const savedRoute: RouteObject = {
    path: `${process.env.PUBLIC_URL}/saved`,
    element: <Saved />,
    children: [
        { index: true, element: <Messages /> },
        {
            path: 'messages',
            element: <Messages />
        },
        {
            path: 'groups',
            element: <Groups />
        },
        {
            path: 'groups/:id',
            element: <AddMembersGroup />
        }
    ]
}

const topupRoute: RouteObject = {
    path: `${process.env.PUBLIC_URL}/topup`,
    element: <TopUp />
}

const FAQRoute: RouteObject = {
    path: `${process.env.PUBLIC_URL}/faq`,
    element: <FAQ />
}

const AccountRoute: RouteObject = {
    path: `${process.env.PUBLIC_URL}/account`,
    element: <MyAccount />,
    children: [
        {
            index: true,
            element: <MyAccountProfile />
        }
    ]
}

const ContactusRoute: RouteObject = {
    path: `${process.env.PUBLIC_URL}/contactus`,
    element: <ContactUs />
}

/** This is the parent route of client */
const clientRoutes: RouteObject[] = [
    {
        element: (
            <PrivateRoute role={AccountRoles.CLIENT}>
                <SidebarLayout
                    sidebar={<ClientSidebar />}
                    toolbar={<ClientContentToolBar />}
                />
            </PrivateRoute>
        ),
        children: [
            homeRoute,
            sendRoute,
            campaignRoutes,
            savedRoute,
            topupRoute,
            FAQRoute,
            AccountRoute,
            ContactusRoute
        ]
    }
]

export default clientRoutes
