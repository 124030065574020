import {
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Popover,
    SelectChangeEvent,
    Typography
} from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from 'react'

import cancelScheduled from 'api/scheduled/cancelScheduled'
import {
    getScheduledHistory,
    IScheduledHistoryResponse
} from 'api/scheduled/scheduledHistory'
import { AxiosResponse } from 'axios'
import Button from 'components/global/Button'
import CommonModal from 'components/global/commonModal'
import CommonTable from 'components/global/commonTable'
import Filter from 'components/global/Filter'
import Search from 'components/global/Search'
import { theme } from 'theme'
import { defaultRowsPerPage, isEmpty, parseIntConfig } from 'utils/app.const'
import useToast from 'utils/useToast'
import EditScheduled from '../edit'
import { IScheduledHistoryData } from '../scheduled.interface'
import SortByDateDropDown from './components/SortByDate'
import { CAMPAIGN_TYPE, sortBy, types } from './scheduledHistoryTable.const'
import {
    IScheduledTableProps,
    ISortDateFilter
} from './scheduledHistoryTable.interface'
import {
    StyledCheckBox,
    StyledOutlinedButton,
    StyledScheduledButtonContainer
} from './scheduledHistoryTable.styled'

const ScheduledHistoryTable: FC<IScheduledTableProps> = (props) => {
    const { title, Note, columns, rowClick } = props

    // check campaigns meaning/data
    const [scheduledList, setScheduledList] =
        useState<IScheduledHistoryData[]>()

    const [searchString, setSearchString] = useState<string>('')
    const [anchorEl, setAnchorEl] = useState(null)
    const [sortDateFilter, setSortDateFilter] =
        useState<ISortDateFilter>('newest')
    // comment out for now
    // const [sortAlpha, setSortAlpha] = useState('AZ')
    const [selectedDate, setSelectedDate] = useState(null)
    const [campaignTypes, setCampaignTypes] = useState(['sms'])

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
    const [totalHistory, setTotalHistory] = useState(0)

    // Editing
    const [isEditing, setIsEditing] = useState(false)

    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const clickedRow = useRef<IScheduledHistoryResponse>()

    const handleChangePage = (
        event: ChangeEvent<unknown> | null,
        newPage: number
    ) => {
        setPage(newPage - 1)
    }

    const handleCancelButton = (row: IScheduledHistoryResponse) => {
        clickedRow.current = row
        setShowCancelModal(true)
    }

    useEffect(() => {
        if (showCancelModal === false) {
            setTimeout(() => {
                loadTableData()
            }, 500)
        }
    }, [setShowCancelModal])

    const handleCancelRequest = (id: number) => {
        ;(async () => {
            try {
                const res = await cancelScheduled({ id })

                if ([200, 201, 202].includes(res!.status)) {
                    setShowSuccessModal(true)
                }
            } catch (e) {
                if (e instanceof Error) {
                    useToast().error(e.message)
                }
            } finally {
                setShowCancelModal(false)
                loadTableData()
            }
        })()
    }

    const handleChangeRowsPerPage = (event: SelectChangeEvent<string>) => {
        setRowsPerPage(parseInt(event.target.value, parseIntConfig))
        setPage(0)
    }

    const paginationPayload = {
        currentPage: page,
        currentLimit: rowsPerPage,
        total: totalHistory,
        onPageChange: handleChangePage,
        handleOnPageSizeChange: handleChangeRowsPerPage,
        showOption: true
    }

    useEffect(() => {
        loadTableData()
    }, [sortDateFilter, page, rowsPerPage])

    useEffect(() => {
        if (isEmpty(searchString)) {
            loadTableData()
        }
    }, [searchString])

    const loadTableData = async () => {
        try {
            const res = (await getScheduledHistory({
                s: sortDateFilter,
                limit: rowsPerPage,
                page,
                k: searchString,
                d: selectedDate
                    ? moment(selectedDate).format('YYYY-MM-DD')
                    : undefined
            })) as unknown as AxiosResponse<IScheduledHistoryResponse[]>

            setScheduledList(
                res.data.map((scheduledHistoryItem) => {
                    return {
                        dateCreated: moment(scheduledHistoryItem.date_created)
                            .format('YYYY/MM/DD h:mmA')
                            .toString(),
                        dateOfBlast: moment(scheduledHistoryItem.date_scheduled)
                            .format('YYYY/MM/DD h:mmA')
                            .toString(),
                        campaignName: scheduledHistoryItem.campaign_name,
                        campaignType:
                            CAMPAIGN_TYPE[scheduledHistoryItem.recipient_type],
                        buttons: [
                            <StyledScheduledButtonContainer
                                key={scheduledHistoryItem.id + 'co'}
                                onClickCapture={() => {
                                    clickedRow.current = scheduledHistoryItem
                                }}
                            >
                                {/* Edit button */}
                                <StyledOutlinedButton
                                    key={scheduledHistoryItem.id + 'ed'}
                                    onClick={() => setIsEditing(true)}
                                >
                                    Edit
                                </StyledOutlinedButton>

                                {/* Cancelled button */}
                                <StyledOutlinedButton
                                    key={scheduledHistoryItem.id + 'cl'}
                                    // onClick={() => { setShowCancelModal(true) }}
                                    onClick={() =>
                                        handleCancelButton(scheduledHistoryItem)
                                    }
                                >
                                    Cancel
                                </StyledOutlinedButton>
                            </StyledScheduledButtonContainer>
                        ]
                    } as unknown as IScheduledHistoryData
                })
            )
            setTotalHistory(res.data[0]?.total_history || 0)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    const handleChange = (newValue: any) => {
        setSelectedDate(newValue)
    }

    const handleTypeSelect = (value: string) => {
        if (campaignTypes.length < 2) return
        if (campaignTypes.includes(value)) {
            const newTypes = campaignTypes.filter((item) => item !== value)
            setCampaignTypes(newTypes)
        } else {
            setCampaignTypes((prevValue) => [...prevValue, value])
        }
    }

    const handleApplyFilter = () => {
        loadTableData()
    }

    const handleClearFilter = () => {
        setCampaignTypes(['SMS', 'LOAD'])
        setSelectedDate(null)
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSortChange = (type: string, value: ISortDateFilter) => {
        switch (type) {
            case 'date':
                setSortDateFilter(value)
                break
        }
    }

    const handleIsChecked = (type: string, value: string) => {
        switch (type) {
            case 'date':
                return sortDateFilter === value
        }
    }

    const handleSearch = (e: FormEvent) => {
        e.preventDefault()
        loadTableData()
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    if (isEditing) {
        return (
            <EditScheduled
                back={() => setIsEditing(false)}
                clickedHistory={clickedRow.current!}
            />
        )
    } else {
        return (
            <>
                {/* SUCCESS MODAL */}
                <CommonModal
                    open={showSuccessModal}
                    close={() => setShowSuccessModal(false)}
                >
                    <>
                        <Typography
                            color={theme.palette.primary.main}
                            fontWeight={theme.typography.fontWeightBold}
                            variant={'h3'}
                        >
                            Cancelled!
                        </Typography>
                        <Typography>
                            Your campaign has been cancelled.
                        </Typography>
                    </>
                </CommonModal>
                {/* END SUCCESS MODAL */}
                {/* CANCELLATION MODAL */}
                <CommonModal
                    open={showCancelModal}
                    close={() => setShowCancelModal(false)}
                >
                    <Box>
                        <Typography
                            variant={'h2'}
                            sx={{ margin: '1rem 2rem 2rem 3rem' }}
                        >
                            Are you sure you want to cancel?
                        </Typography>
                        <Box
                            display={'flex'}
                            justifyContent={'space-around'}
                            gap={2}
                        >
                            <Button onClick={() => setShowCancelModal(false)}>
                                Close
                            </Button>
                            <Button
                                onClick={() => {
                                    handleCancelRequest(clickedRow.current!.id)
                                }}
                            >
                                Yes
                            </Button>
                        </Box>
                    </Box>
                </CommonModal>
                {/* CANCELLATION MODAL END */}
                <Grid container gap={1} justifyContent="flex-end">
                    <Grid
                        item
                        xs={3}
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        gap={1}
                    >
                        <SortByDateDropDown
                            handleClick={handleClick}
                            sortDateFilter={sortDateFilter}
                            open={open}
                        />
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                        >
                            <Box sx={{ width: '100%' }} p={1.5}>
                                {sortBy.map((item) => (
                                    <Grid key={item.key}>
                                        <FormGroup>
                                            {item.children.map(
                                                (child, index) => (
                                                    <>
                                                        <FormControlLabel
                                                            key={child.value}
                                                            control={
                                                                <StyledCheckBox
                                                                    checked={handleIsChecked(
                                                                        item.key,
                                                                        child.value
                                                                    )}
                                                                    onChange={() =>
                                                                        handleSortChange(
                                                                            item.key,
                                                                            child.value as unknown as ISortDateFilter
                                                                        )
                                                                    }
                                                                />
                                                            }
                                                            label={child.label}
                                                        />
                                                        {index <
                                                            item.children
                                                                .length -
                                                                1 && (
                                                            <Divider />
                                                        )}
                                                    </>
                                                )
                                            )}
                                        </FormGroup>
                                    </Grid>
                                ))}
                            </Box>
                        </Popover>
                    </Grid>
                    <Grid item>
                        <Filter
                            onDateChange={handleChange}
                            types={types}
                            date={selectedDate}
                            selectedTypes={campaignTypes}
                            onTypeSelect={handleTypeSelect}
                            applyFilter={handleApplyFilter}
                            clearFilter={handleClearFilter}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Search
                            handleSearchChange={setSearchString}
                            handleSubmit={handleSearch}
                        />
                    </Grid>
                </Grid>

                <Grid container gap={2} alignItems="center" my={2}>
                    <Typography variant="h2" color="primary">
                        {title}
                    </Typography>

                    {Note ?? Note}
                </Grid>

                <CommonTable
                    columns={columns}
                    rows={scheduledList ?? null}
                    rowClick={rowClick}
                    paginationPayload={paginationPayload}
                />
            </>
        )
    }
}

export default ScheduledHistoryTable
