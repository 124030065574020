import { Box, Typography } from '@mui/material'
import { useBalanceState } from 'context/balance'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import BasicTabs from 'components/global/commonTabComponent'
import {
    CampaignTabs,
    MyAccountTabs,
    SavedTabs,
    SmsTabs
} from './clientContentToolBar.const'
import { IContentToolBarProps } from './contentToolBar.interface'
import { StyledContentToolBar } from './contentToolBar.styled'

const ClientContentToolBar = (props?: IContentToolBarProps): JSX.Element => {
    const balance = useBalanceState()
    const { pathname } = useLocation()
    const [tabs, setTabs] = useState<any>([])

    useEffect(() => {
        renderTabs(pathname.split('/')[1])
    }, [pathname])

    const renderTabs = (location: string) => {
        switch (location) {
            case 'send':
                setTabs(SmsTabs)
                break
            case 'account':
                setTabs(MyAccountTabs)
                break
            case 'saved':
                setTabs(SavedTabs)
                break
            case 'campaigns':
                setTabs(CampaignTabs)
                break
            default:
                setTabs([])
        }
    }

    return (
        <Box {...props} position="relative" sx={{ zIndex: 999 }}>
            <div style={{ position: 'absolute', bottom: 0, left: 40 }}>
                <BasicTabs tabs={tabs} />
            </div>

            <StyledContentToolBar>
                <Typography variant="h3">PHP {balance.amount}</Typography>
                <img
                    src={require('assets/images/topup.png')}
                    alt="Top Up Icon"
                />
            </StyledContentToolBar>
        </Box>
    )
}

export default ClientContentToolBar
