import { FC } from 'react'
import { ListSubheader, MenuItem, SelectProps } from '@mui/material'
import { IDropdownProps } from './dropdown.interface'
import { StyledSelect } from './dropdown.styled'

const RenderDropdown = (option: any) => {
    if (!option.children) {
        return (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        )
    }
    const items = option?.children?.map((child: any) => {
        return (
            <MenuItem key={child.value} value={child.value}>
                {child.label}
            </MenuItem>
        )
    })
    return [
        <ListSubheader key={option.value} color="primary">
            {option.label}
        </ListSubheader>,
        items
    ]
}

const Dropdown: FC<SelectProps & IDropdownProps> = (props): JSX.Element => {
    const { options, sx, ...others } = props

    return (
        <StyledSelect {...others}>
            {options.map((option: any) => RenderDropdown(option))}
        </StyledSelect>
    )
}

export default Dropdown
