import clients from 'assets/svgs/clients.svg'
import reports from 'assets/svgs/reports.svg'
import logs from 'assets/svgs/logs.svg'
import { AdminPaths } from 'utils/app.const'

const adminSidebarItems = [
    {
        icon: reports,
        label: 'Reports',
        route: AdminPaths.HOME
    },
    {
        icon: clients,
        label: 'Clients',
        route: AdminPaths.CLIENTS
    },
    {
        icon: logs,
        label: 'Admin Logs',
        route: AdminPaths.LOGS
    }
]

export default adminSidebarItems
