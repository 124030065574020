import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react'
import moment from 'moment'
import {
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Popover,
    Typography,
    Button,
    SelectChangeEvent
} from '@mui/material'
import { Box } from '@mui/system'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import RefreshIcon from '@mui/icons-material/Refresh'

import CommonTable from 'components/global/commonTable'
import Filter from 'components/global/Filter'
import useToast from 'utils/useToast'
import { ICampaignsTableProps } from './campaignsTable.interface'
import { StyledButton, StyledCheckBox } from './campaignsTable.styled'
import { sortBy, types } from './campaignsTable.const'
import { getHistories } from '../../../api/account'
import {
    defaultRowsPerPage,
    isCurrentPage,
    isEmpty,
    parseIntConfig
} from 'utils/app.const'
import { IHistory } from 'utils/app.interface'
import Search from 'components/global/Search'

const CampaignsTable: FC<ICampaignsTableProps> = (props) => {
    const {
        title,
        columns,
        status,
        hasRefresh,
        rowClick,
        hasDownload,
        downloadModal
    } = props
    const [campaigns, setCampaigns] = useState([])
    const [search, setSearch] = useState('')
    const [anchorEl, setAnchorEl] = useState(null)
    const [sortDate, setSortDate] = useState('newest')
    // comment out for now
    // const [sortAlpha, setSortAlpha] = useState('AZ')
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedTypes, setSelectedTypes] = useState(['sms'])

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
    const [recordLength, setRecordLength] = useState(0)

    const handleChangePage = (
        event: ChangeEvent<unknown> | null,
        newPage: number
    ) => {
        setPage(newPage - 1)
    }

    const handleChangeRowsPerPage = (event: SelectChangeEvent<string>) => {
        setRowsPerPage(parseInt(event.target.value, parseIntConfig))
        setPage(0)
    }

    const paginationPayload = {
        currentPage: page,
        currentLimit: rowsPerPage,
        total: recordLength,
        onPageChange: handleChangePage,
        handleOnPageSizeChange: handleChangeRowsPerPage,
        showOption: true
    }

    useEffect(() => {
        getData()
    }, [sortDate, page, rowsPerPage])

    useEffect(() => {
        if (isEmpty(search)) {
            getData()
        }
    }, [search])

    const getData = async () => {
        try {
            const res: any = await getHistories({
                s: sortDate,
                t: 'sms',
                d: selectedDate
                    ? moment(selectedDate).format('YYYY-MM-DD').toString()
                    : null,
                o: 'AZ',
                status,
                k: search,
                in: rowsPerPage,
                page: isCurrentPage(page, search)
            })
            setCampaigns(
                res.data.map((item: IHistory) => ({
                    ...item,
                    mobile_number: item.mobile_number.join('')
                }))
            )
            setRecordLength(res.data[0]?.totalHistory || 0)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    const handleChange = (newValue: any) => {
        setSelectedDate(newValue)
    }

    const handleTypeSelect = (value: string) => {
        if (selectedTypes.length < 2) return
        if (selectedTypes.includes(value)) {
            const newTypes = selectedTypes.filter((item) => item !== value)
            setSelectedTypes(newTypes)
        } else {
            setSelectedTypes((prevValue) => [...prevValue, value])
        }
    }

    const handleApplyFilter = () => {
        getData()
    }

    const handleClearFilter = () => {
        setSelectedTypes(['sms', 'load'])
        setSelectedDate(null)
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleSortChange = (type: string, value: string) => {
        switch (type) {
            case 'date':
                setSortDate(value)
                break
            // case 'alpha':
            //     setSortAlpha(value)
            //     break
            // default:
        }
    }
    const handleIsChecked = (type: string, value: string) => {
        switch (type) {
            case 'date':
                return sortDate === value
            // case 'alpha':
            //     return sortAlpha === value
            // default:
        }
    }

    const handleSearch = (e: FormEvent) => {
        e.preventDefault()
        getData()
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    return (
        <>
            <Grid container gap={1} justifyContent="flex-end">
                <Grid
                    item
                    xs={3}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    gap={1}
                >
                    <Typography variant="h6">Sort By</Typography>
                    <StyledButton
                        aria-describedby={id}
                        variant="contained"
                        onClick={handleClick}
                        endIcon={<ArrowDropDownIcon />}
                    >
                        {sortDate}
                    </StyledButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }}
                    >
                        <Box sx={{ width: '100%' }} p={1.5}>
                            {sortBy.map((item) => (
                                <Grid key={item.key}>
                                    {/* <Typography variant="h4">
                                        {item.label}
                                    </Typography> */}
                                    <FormGroup>
                                        {item.children.map((child, index) => (
                                            <>
                                                <FormControlLabel
                                                    key={child.value}
                                                    control={
                                                        <StyledCheckBox
                                                            checked={handleIsChecked(
                                                                item.key,
                                                                child.value
                                                            )}
                                                            onChange={() =>
                                                                handleSortChange(
                                                                    item.key,
                                                                    child.value
                                                                )
                                                            }
                                                        />
                                                    }
                                                    label={child.label}
                                                />
                                                {index <
                                                    item.children.length -
                                                        1 && <Divider />}
                                            </>
                                        ))}
                                    </FormGroup>
                                </Grid>
                            ))}
                        </Box>
                    </Popover>
                </Grid>
                <Grid item>
                    <Filter
                        onDateChange={handleChange}
                        types={types}
                        date={selectedDate}
                        selectedTypes={selectedTypes}
                        onTypeSelect={handleTypeSelect}
                        applyFilter={handleApplyFilter}
                        clearFilter={handleClearFilter}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Search
                        handleSearchChange={setSearch}
                        handleSubmit={handleSearch}
                    />
                </Grid>
            </Grid>

            <Grid container gap={2} alignItems="center" my={2}>
                <Typography variant="h2" color="primary">
                    {title}
                </Typography>

                {/* __DOWNLOAD ALL BUTTON__ */}
                {hasDownload && campaigns.length !== 0 ? (
                    <Button
                        onClick={downloadModal}
                        variant="outlined"
                        sx={{
                            borderRadius: '20px',
                            background: '#FFF',
                            ':hover': {
                                backgroundColor: '#001AA2',
                                color: '#fff'
                            }
                        }}
                    >
                        Download All
                    </Button>
                ) : null}

                {/* __REFRESH BUTTON__ */}
                {hasRefresh && (
                    <Button
                        endIcon={<RefreshIcon />}
                        variant="contained"
                        size="small"
                        onClick={() => getData()}
                    >
                        Refresh
                    </Button>
                )}
            </Grid>

            <CommonTable
                columns={columns}
                rows={campaigns}
                rowClick={rowClick}
                paginationPayload={paginationPayload}
            />
        </>
    )
}

export default CampaignsTable
