import React, { FC, useCallback, useEffect, useState } from 'react'
import UploadIcon from '@mui/icons-material/Upload'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDropzone } from 'react-dropzone'
import { Box, Typography, Button as MButton } from '@mui/material'
import Button from 'components/global/Button'
import TextInput from 'components/global/TextInput'

import {
    formatErrorText,
    inputNumberLabel,
    limitErrorText,
    maxNumber,
    numberFormatLength
} from 'pages/send/step2/step2.cont'

import { StyledUpload } from './multiNumber.styled'
import { IMultiNumbersProps } from './multiNumber.interface'
import { REGEX } from 'utils/app.const'
import CommonConfirmation from 'components/global/commonConfirmation'
import ToolTip from 'components/global/commonToolTip'

const MultiNumber: FC<IMultiNumbersProps> = (props: IMultiNumbersProps) => {
    const {
        phoneValueNumbersProps,
        setPhoneValueNumbersProps,
        handleAddMultipleNumbers,
        csvFile,
        setCsvFile
    } = props
    // Hooks
    const [numbers, setNumbers] = useState(
        phoneValueNumbersProps.join('\n') || ''
    )
    const [hasNumbersError, setHasNumbersError] = useState('')
    const [tempFile, setTempFile] = useState(null)
    const [showConfirmation, setShowConfirmation] = useState(false)

    // Static Tooltip data
    const rules = {
        title: 'Rules',
        details: [
            'Use “_” or camel case to separate two or more variable name/words.',
            'File columns only accepts numbers in “09xxxxxxxxx” or “9xxxxxxxxx” format.'
        ]
    }
    const notes = {
        title: 'Notes',
        details: [
            'If there is/are empty field/s in the csv file, the message/s for the corresponding number/s will not be sent.'
        ]
    }

    // Handlers
    const handleOnChangeNumbers = (event: any) => {
        setHasNumbersError('')
        const {
            target: { value }
        } = event
        setNumbers(value)
        const arrValue = value.split('\n')
        if (arrValue.length < 2) {
            if (value.length > 0 && value.length !== numberFormatLength)
                setHasNumbersError(formatErrorText)
            return
        }
        checkNumbers(arrValue)
    }

    const checkNumbers = (value: Array<string>) => {
        let hasChange = false
        const result = value
            .filter((number) => number.length > 0)
            .map((number) => {
                if (REGEX.MOBILE_NUMBER_FORMAT.test(number)) {
                    if (REGEX.INTERNATIONAL_DIAL_CODE.test(number)) {
                        number = number.replace('+63', '0')
                        hasChange = true
                    }
                    return number
                } else {
                    setHasNumbersError(formatErrorText)
                    return number
                }
            })
        if (hasChange) {
            setNumbers([...result, ''].join('\n'))
        }
    }
    const exceedLimit = () => numbers.split('\n').length > maxNumber.value
    const handlePreview = () => {
        if (numbers.length > 0) {
            setPhoneValueNumbersProps(numbers.split('\n'))
        }
    }
    const onDrop = useCallback((acceptedFiles: any) => {
        setShowConfirmation(true)
        setTempFile(acceptedFiles)
    }, [])
    const handleConfirmationProceed = () => {
        setCsvFile(tempFile)
        setNumbers('')
        setShowConfirmation(false)
    }

    const { getInputProps, getRootProps, open } = useDropzone({
        onDrop,
        accept: {
            'text/*': ['.xls', '.xlsx', '.csv']
        },
        noClick: true
    })
    const handleConfirmationClose = (): void => {
        setShowConfirmation(false)
    }
    useEffect(() => {
        if (exceedLimit()) {
            setHasNumbersError(limitErrorText)
        }
    }, [numbers])

    const isProceedDisabled = () => {
        if (csvFile) return false

        return hasNumbersError.length > 0 || numbers.length === 0
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <form onSubmit={handleAddMultipleNumbers}>
                    <Typography variant="h2" color="primary" gutterBottom>
                        Send to the following mobile numbers
                    </Typography>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Box sx={{ width: '40%', position: 'relative' }}>
                            <Typography gutterBottom>
                                Input mobile numbers
                            </Typography>
                            {csvFile ? (
                                <TextInput
                                    fullWidth
                                    multiline
                                    rows={5}
                                    value={csvFile[0].name}
                                    disabled={csvFile !== null}
                                />
                            ) : (
                                <TextInput
                                    fullWidth
                                    multiline
                                    rows={5}
                                    error={hasNumbersError.length > 0}
                                    value={numbers}
                                    onChange={handleOnChangeNumbers}
                                />
                            )}
                            <Box
                                sx={{ position: 'absolute', width: '100%' }}
                                gap={5}
                                mt={1}
                                display="flex"
                                justifyContent="space-between"
                            >
                                {hasNumbersError ? (
                                    <Typography
                                        fontSize={11}
                                        color="red"
                                        fontStyle="italic"
                                    >
                                        {hasNumbersError}
                                    </Typography>
                                ) : (
                                    <Typography
                                        fontSize={11}
                                        color="primary"
                                        fontStyle="italic"
                                    >
                                        {inputNumberLabel}
                                    </Typography>
                                )}
                                <Typography
                                    fontSize={11}
                                    fontStyle="italic"
                                    color={exceedLimit() ? 'red' : 'black'}
                                >
                                    {numbers.split('\n').length - 1}/
                                    {maxNumber.label}
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="center"
                            sx={{ width: '20%' }}
                        >
                            <Typography>OR</Typography>
                        </Box>
                        <Box sx={{ width: '40%' }}>
                            <Typography gutterBottom>
                                Upload your file{' '}
                                <ToolTip rules={rules} notes={notes} />
                            </Typography>
                            <StyledUpload {...getRootProps()}>
                                <input {...getInputProps()} />
                                <>
                                    {csvFile ? (
                                        <>
                                            <Typography mb={2}>
                                                {csvFile[0].name}
                                            </Typography>
                                            <MButton
                                                variant="outlined"
                                                color="error"
                                                startIcon={<DeleteIcon />}
                                                onClick={() => setCsvFile(null)}
                                            >
                                                Delete
                                            </MButton>
                                        </>
                                    ) : (
                                        <Box
                                            onClick={open}
                                            component="div"
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="center"
                                        >
                                            <UploadIcon />
                                            <Typography>
                                                Upload XLS
                                                <br /> or CSV File
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            </StyledUpload>
                        </Box>
                    </Box>

                    <Box
                        display="flex"
                        justifyContent="end"
                        alignItems="center"
                        gap={2}
                        mb={5}
                        mt={5}
                    />
                    <Box display="flex" justifyContent="center">
                        <Button
                            type="submit"
                            onClick={handlePreview}
                            disabled={isProceedDisabled()}
                        >
                            ADD
                        </Button>
                    </Box>
                    <CommonConfirmation
                        open={showConfirmation}
                        close={handleConfirmationClose}
                        confirmationText="Warning!"
                    >
                        <Box>
                            <Typography align="center" color="red">
                                Uploading File will remove inputted mobile
                                numbers and selected saved group (if
                                there&apos;s any).
                            </Typography>

                            <Box
                                display="flex"
                                justifyContent="center"
                                gap={5}
                                mt={5}
                            >
                                <Button onClick={handleConfirmationClose}>
                                    Cancel
                                </Button>
                                <Button onClick={handleConfirmationProceed}>
                                    Proceed
                                </Button>
                            </Box>
                        </Box>
                    </CommonConfirmation>
                </form>
            </Box>
        </>
    )
}

export default MultiNumber
