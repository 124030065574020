import { Drawer, List, ListItemButton, Typography } from '@mui/material'
import logout from 'assets/svgs/logout.svg'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { TSidebarItems } from 'utils/app.interface'
import { clearUser, getRole, getUserHome, getUserLoginPath } from 'utils/token'
import {
    StyledListItemIcon,
    StyledSidebar,
    StyledSidebarLogo
} from './sidebar.styled'

const drawerWidth = 240

type SidebarProps = {
    sidebarItems: TSidebarItems
}

const Sidebar = ({ sidebarItems: SidebarItems }: SidebarProps): JSX.Element => {
    const navigate = useNavigate()

    // Hooks
    const [mobileOpen, setMobileOpen] = useState<boolean>(false)

    // Hanlders
    const handleDrawerToggle = (): void => {
        setMobileOpen(!mobileOpen)
    }

    const handleSidebarItemClick = (path: string): void => {
        navigate(path)
    }

    const handleLogout = (): void => {
        navigate(getUserLoginPath(getRole()!))
        clearUser()
    }

    const drawer = (
        <div>
            <StyledSidebarLogo>
                <img
                    onClick={() => navigate(`${getUserHome()}`)}
                    src={require('assets/images/Logo.png')}
                    alt="Sidebar Logo"
                />
            </StyledSidebarLogo>
            <List>
                {SidebarItems.map((sidebarItem, index) => (
                    <ListItemButton
                        key={index}
                        onClick={() => {
                            handleSidebarItemClick(sidebarItem.route)
                        }}
                        disabled={sidebarItem.disabled}
                    >
                        <StyledListItemIcon>
                            <img
                                src={sidebarItem.icon}
                                alt={`${sidebarItem.label}-icon`}
                            />
                        </StyledListItemIcon>
                        <Typography fontWeight={500}>
                            {sidebarItem.label}
                        </Typography>
                    </ListItemButton>
                ))}

                <ListItemButton sx={{ marginTop: 4 }} onClick={handleLogout}>
                    <StyledListItemIcon>
                        <img src={logout} alt={`${logout}-icon`} />
                    </StyledListItemIcon>
                    <Typography fontWeight={500}>Log out</Typography>
                </ListItemButton>
            </List>
        </div>
    )

    return (
        <StyledSidebar
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth
                    }
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: drawerWidth
                    }
                }}
                open
            >
                {drawer}
            </Drawer>
        </StyledSidebar>
    )
}

export default Sidebar
