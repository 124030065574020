import { Select, SelectProps } from '@mui/material'
import { styled } from '@mui/system'

export const StyledSelect = styled(Select)<SelectProps>(({ theme }) => ({
    '& .MuiInputBase-input': {
        padding: '0.7em',
        background: theme.palette.common.white
    },
    '& .MuiInputBase-root': {
        background: theme.palette.common.white
    }
}))
