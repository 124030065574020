// Absolute
import type { FC } from 'react'
import { Grid, Typography } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

// Relative
import type { ICommonToastProps } from './commonToast.interface'
import {
    StyledToastErrorTypography,
    StyledToastSuccessTypography
} from './commonToast.styled'
import { toastContent } from './commonToast.cont'

export const SuccessToast: FC<ICommonToastProps> = (
    props: ICommonToastProps
) => {
    const { msg } = props

    return (
        <Grid container>
            <Grid item xs={2}>
                <CheckCircleOutlineIcon color="success" fontSize="large" />
            </Grid>
            <Grid item xs={10}>
                <StyledToastSuccessTypography variant="h4">
                    {toastContent.success}
                </StyledToastSuccessTypography>
                <StyledToastSuccessTypography variant="body1">
                    {msg}
                </StyledToastSuccessTypography>
            </Grid>
        </Grid>
    )
}

export const ErrorToast: FC<ICommonToastProps> = (props: ICommonToastProps) => {
    const { msg } = props

    return (
        <Grid container>
            <Grid item xs={2}>
                <ErrorOutlineIcon color="error" fontSize="large" />
            </Grid>
            <Grid item xs={10}>
                <StyledToastErrorTypography variant="h4">
                    {toastContent.error}
                </StyledToastErrorTypography>
                <StyledToastErrorTypography variant="body1">
                    {msg}
                </StyledToastErrorTypography>
            </Grid>
        </Grid>
    )
}

export const GenericToast: FC<ICommonToastProps> = (
    props: ICommonToastProps
) => {
    const { msg } = props

    return (
        <Grid container>
            <Grid item xs={2}>
                {toastContent.info}
            </Grid>
            <Grid item xs={10}>
                <Typography variant="h4">
                    {toastContent.notification}
                </Typography>
                <Typography variant="body1">{msg}</Typography>
                <span>{toastContent.success}</span>
            </Grid>
        </Grid>
    )
}
