import { styled } from '@mui/system'
import { Checkbox, Button, TextField } from '@mui/material'
import { CSVLink } from 'react-csv'

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.primary.main,
    '.MuiCheckbox-root': {
        borderRadius: '30px'
    }
}))
export const StyledShowReport = styled(Button)(({ theme }) => ({
    background: theme.palette.common.white,
    padding: '5px 20px',
    borderRadius: '20px',
    border: `1px ${theme.palette.primary.main} solid`
}))
export const StyledCSVLink = styled(CSVLink)(({ theme }) => ({
    color: theme.palette.primary.main,
    disabled: 'true'
}))
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

export const StyledFromInput = styled(TextField)(({ theme }) => ({
    '.MuiOutlinedInput-root': {
        borderRadius: `10px 0px 0px 10px`,
        background: theme.palette.common.white,
        color: theme.palette.primary.main
    }
}))
export const StyledToInput = styled(TextField)(({ theme }) => ({
    '.MuiOutlinedInput-root': {
        borderRadius: `0px 10px 10px 0px`,
        background: theme.palette.common.white,
        color: theme.palette.primary.main
    }
}))
