import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, Typography, Button, Box, SelectChangeEvent } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

import CommonTable from 'components/global/commonTable'
import TextInput from 'components/global/TextInput'
import Dropdown from 'components/global/Dropdown'
import {
    defaultRowsPerPage,
    filterBy,
    isCurrentPage,
    isEmpty,
    parseIntConfig
} from 'utils/app.const'
import { ISaveGroupTableProps } from './savedGroupTable.interface'
import { IGroups } from 'utils/app.interface'
import { deleteGroups, getGroups, saveGroup } from 'api/groups'
import { modifyResponseDate } from 'utils'
import CommonModal from '../commonModal'
import CommonConfirmation from 'components/global/commonConfirmation'
import { getUser } from 'utils/token'
import useToast from 'utils/useToast'
import Search from '../Search'

const CommonButton = require('components/global/Button').default

type Rows = Array<IGroups>

const SavedGroupTable: FC<ISaveGroupTableProps> = (
    props: ISaveGroupTableProps
) => {
    const { columns, addGroup, rowClick, hasActions, showOption } = props

    // Hooks
    const navigate = useNavigate()
    const [filter, setFilter] = useState('desc')
    const [groups, setGroups] = useState<Rows>([])
    const [search, setSearch] = useState('')
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(null)
    const [name, setName] = useState('')
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState<IGroups | null>(null)

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
    const [recordLength, setRecordLength] = useState(0)

    const handleChangePage = (
        event: ChangeEvent<unknown> | null,
        newPage: number
    ) => {
        setPage(newPage - 1)
    }

    const handleChangeRowsPerPage = (event: SelectChangeEvent<string>) => {
        setRowsPerPage(parseInt(event.target.value, parseIntConfig))
        setPage(0)
    }

    const paginationPayload = {
        currentPage: page,
        currentLimit: rowsPerPage,
        total: recordLength,
        onPageChange: handleChangePage,
        handleOnPageSizeChange: handleChangeRowsPerPage,
        showOption
    }

    useEffect(() => {
        getData()
    }, [filter, page, rowsPerPage])

    useEffect(() => {
        if (isEmpty(search)) {
            getData()
        }
    }, [search])

    const getData = async () => {
        try {
            const res: any = await getGroups({
                order: filter,
                limit: rowsPerPage,
                page: isCurrentPage(page, search),
                search
            })
            const data = modifyResponseDate(res.data.data)
            setRecordLength(res.data.total)
            setGroups(data)
        } catch (error: any) {
            setError(error)
        }
    }

    // Handlers
    const handleOnFilterChange = (event: any) => {
        const {
            target: { value }
        } = event
        setFilter(value)
    }

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleSaveGroup = async (event: FormEvent) => {
        event.preventDefault()
        const user = getUser()
        if (!user) return

        try {
            await saveGroup({
                name
            })
            setOpen(false)
            useToast().success('Successfully created a group')
            getData()
            // FOR FUTURE USE
            // navigate(`/saved/groups/${name}`)
        } catch (error: any) {
            setError(error.message)
        }
    }

    const handleConfirmationClose = () => {
        setSelectedGroup(null)
        setShowConfirmation(false)
    }

    const selectDelete = (message: IGroups) => {
        setSelectedGroup(message)
        setShowConfirmation(true)
    }

    const handleDelete = async () => {
        if (!selectedGroup) return
        try {
            await deleteGroups({ ids: [`${selectedGroup.id}`] })
            useToast().success('Delete success')
            setShowConfirmation(false)
            getData()
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    const addDisableGroup = (): boolean => {
        return name.length === 0
    }
    const getColumns = () => {
        if (hasActions) {
            return [
                ...columns,
                {
                    columnName: 'action' as const,
                    columnLabel: '',
                    colSpan: 4,
                    render: (row: IGroups) => {
                        return (
                            <Button
                                onClick={() =>
                                    navigate(`/saved/groups/${row.id}`)
                                }
                                variant="outlined"
                                sx={{
                                    borderRadius: '20px',
                                    background: '#FFF'
                                }}
                            >
                                Edit
                            </Button>
                        )
                    }
                },
                {
                    columnName: 'action' as const,
                    columnLabel: '',
                    colSpan: 4,
                    render: (row: IGroups) => {
                        return (
                            <Button
                                onClick={() => selectDelete(row)}
                                variant="outlined"
                                sx={{
                                    borderRadius: '20px',
                                    background: '#FFF'
                                }}
                            >
                                Delete
                            </Button>
                        )
                    }
                }
            ]
        } else {
            return columns
        }
    }

    const handleSearch = (e: FormEvent) => {
        e.preventDefault()
        getData()
    }

    return (
        <>
            <Grid container gap={1} justifyContent="flex-end">
                <Grid
                    item
                    xs={3}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    gap={1}
                >
                    <Typography variant="h6">Sort By</Typography>
                    <Dropdown
                        value={filter}
                        options={filterBy}
                        onChange={handleOnFilterChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Search
                        handleSearchChange={setSearch}
                        handleSubmit={handleSearch}
                    />
                </Grid>
            </Grid>

            <Grid container gap={2} alignItems="center" mb={2}>
                <Typography variant="h2" color="primary">
                    Saved Groups
                </Typography>
                {addGroup && (
                    <Button
                        startIcon={<AddIcon />}
                        size="small"
                        sx={{ background: '#FFF', padding: '5px 20px' }}
                        onClick={handleOpen}
                    >
                        New Group
                    </Button>
                )}
            </Grid>

            <CommonTable
                columns={getColumns()}
                rows={groups}
                rowClick={rowClick}
                paginationPayload={paginationPayload}
            />

            <CommonModal open={open} close={handleClose}>
                <Box sx={{ width: 500 }} padding={1}>
                    <Box>
                        <form onSubmit={handleSaveGroup}>
                            <Typography
                                variant="h2"
                                color="primary"
                                gutterBottom
                            >
                                Add New Group Name
                            </Typography>
                            <Box mb={5}>
                                <TextInput
                                    required
                                    placeholder="Xurpas-Employees"
                                    fullWidth
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                {error && (
                                    <Typography
                                        mt={2}
                                        color="red"
                                        fontSize={12}
                                        align="center"
                                    >
                                        {error}
                                    </Typography>
                                )}
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <CommonButton
                                    disabled={addDisableGroup()}
                                    type="submit"
                                >
                                    Next
                                </CommonButton>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </CommonModal>

            {/* DELETE CONFIRMATION */}
            <CommonConfirmation
                open={showConfirmation}
                close={handleConfirmationClose}
                confirmationText="Warning!"
            >
                <Box>
                    <Typography align="center" color="red">
                        Are you sure you want to delete this group?
                    </Typography>

                    <Box display="flex" justifyContent="center" gap={5} mt={5}>
                        <CommonButton onClick={handleConfirmationClose}>
                            Cancel
                        </CommonButton>
                        <CommonButton onClick={handleDelete}>
                            Proceed
                        </CommonButton>
                    </Box>
                </Box>
            </CommonConfirmation>

            {/* Insert Pagination Ui */}
        </>
    )
}

export default SavedGroupTable
