import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { AccountRoles } from 'utils/app.const'
import {
    getUserHome,
    getUserLoginPath,
    isAuthenticated,
    isAuthorized
} from 'utils/token'
import useToast from 'utils/useToast'

type PrivateRouteProps = {
    children?: React.ReactNode
    role: AccountRoles
}

const PrivateRoute = ({
    children,
    role: authorizedRole
}: PrivateRouteProps) => {
    const authenticated = isAuthenticated()
    const authorized = isAuthorized(authorizedRole)
    const failedRedirectPath = ((): string | null => {
        if (authenticated && !authorized) {
            // authenticated but NOT authorized :: return to account home
            return getUserHome()
        }
        if (!authenticated && !authorized) {
            // NOT authenticated AND NOT authorized :: return to LOGIN screen
            return getUserLoginPath(authorizedRole)
        }
        // authenticated AND authorized :: success
        return null
    })()

    if (!authenticated) {
        useToast().error('User must log in first')
    }
    if (!authorized) {
        useToast().error('User does not have authorization for this resource')
    }

    return (
        <>
            {authenticated && authorized && !failedRedirectPath ? (
                children || <Outlet />
            ) : (
                <Navigate to={failedRedirectPath!} />
            )}
        </>
    )
}

export default PrivateRoute
