export const SavedGroupsColumns = [
    {
        columnLabel: 'Title',
        columnName: 'name' as const
    },
    {
        columnLabel: 'Date Created',
        columnName: 'date_created' as const
    },
    {
        columnLabel: 'Total Numbers',
        columnName: 'total_numbers' as const
    },
    {
        columnLabel: 'Created By',
        columnName: 'created_by' as const
    }
]
export const SavedGroupsRows = [
    {
        groupName: 'Group 123',
        dateCreated: '04/10/2020 12:28PM',
        totalNumber: '50',
        createdBy: 'Vanessa B.'
    },
    {
        groupName: 'Group 456',
        dateCreated: '04/10/2020 12:28PM',
        totalNumber: '50',
        createdBy: 'Vanessa B.'
    }
]

export const maxNumber = {
    value: 501,
    label: '500'
}

export const filetypeErrorText = `Invalid Filetype. Can only accept .csv, .xlsx, and .xls filetypes.`
export const limitErrorText = `Entries exceed limit of ${maxNumber.label}, please upload an xls or a csv file instead.`
export const formatErrorText = 'Number format is invalid. (eg. 09XXXXXXXXX)'
export const inputNumberLabel = `Max of ${maxNumber.label} numbers & Allowed number format is 0917XXXXXX`
export const numberFormatLength = 11
