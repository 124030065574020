import { TextField, TextFieldProps } from '@mui/material'
import { styled } from '@mui/system'

export const StyledTextField = styled(TextField)<TextFieldProps>(
    ({ theme }) => ({
        '& .MuiInputBase-input': {
            padding: '0.7em'
        },
        '& .MuiInputBase-root': {
            background: theme.palette.common.white
        }
    })
)
