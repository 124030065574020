import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { clearUser, getToken } from 'utils/token'
import { XBLAST_API_ENDPOINT } from 'constants/env'

export const publicClient = axios.create({
    baseURL: XBLAST_API_ENDPOINT
})
// privateClient will attach the bearer token from local storage
// for you, use privateClient instance for api calls
// that needs the bearer token
export const privateClient = axios.create({
    baseURL: XBLAST_API_ENDPOINT
})

privateClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const token = getToken()
    if (token) config.headers!.Authorization = `Bearer ${token}`
    return config
})

privateClient.interceptors.response.use(
    async (response: AxiosResponse) => {
        return response
    },
    (error: AxiosError) => {
        const _error = error.response
        if (_error && _error.status === 401) {
            clearUser()
            throw error
        }
        return error
    }
)
