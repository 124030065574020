export const reportsColumn = [
    {
        columnLabel: 'CLIENT ID',
        columnName: 'account_id' as const
    },
    {
        columnLabel: 'CLIENT NAME',
        columnName: 'company_name' as const
    },
    {
        columnLabel: 'SENDER NAME',
        columnName: 'sender_name' as const
    },
    {
        columnLabel: 'COUNT',
        columnName: 'sent_count' as const
    }
]
export const reportsColumnCSV = [
    {
        label: 'CLIENT ID',
        key: 'account_id'
    },
    {
        label: 'CLIENT NAME',
        key: 'company_name'
    },
    {
        label: 'SENDER NAME',
        key: 'sender_name'
    },
    {
        label: 'COUNT',
        key: 'sent_count'
    }
]
