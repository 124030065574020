import {
    Box,
    TextField,
    TextFieldProps,
    Button,
    ButtonProps,
    Popover,
    Typography
} from '@mui/material'
import { styled } from '@mui/system'
import SearchIcon from '@mui/icons-material/Search'

export const StyledButtonContainer = styled(Box)({
    margin: '0 6rem',
    display: 'flex',
    columnGap: 4,
    flexWrap: 'nowrap',
    justifyContent: 'right'
})

export const StyledButtonFormContainer = styled(Box)({
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'center',
    columnGap: '1rem'
})

export const StyledAdminButton = styled(Button)<ButtonProps>(({ theme }) => ({
    background: theme.palette.common.white,
    border: `2px solid ${theme.palette.admin.primary}`,
    color: theme.palette.admin.primary,
    fontWeight: 800,
    padding: '0.6em 2.6em',
    minWidth: '12em',
    display: 'flex',
    whiteSpace: 'nowrap',

    '&:disabled': {
        background: theme.palette.action.disabledBackground,
        border: 'none'
    }
}))

export const StyledAdminFilledButton = styled(Button)<ButtonProps>(
    ({ theme }) => ({
        backgroundColor: theme.palette.admin.primary,
        color: theme.palette.common.white,
        fontWeight: 800,
        padding: '0.6em 2.6em',
        minWidth: '12em',
        display: 'flex',
        whiteSpace: 'nowrap',

        '&:hover': {
            background: theme.palette.admin.primary
        },
        '&:disabled': {
            background: theme.palette.action.disabledBackground,
            border: 'none'
        }
    })
)

export const StyledTextField = styled(TextField)<TextFieldProps>(
    ({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            background: theme.palette.common.white,
            '& fieldset': {
                border: `2px solid ${theme.palette.admin.primary}` // default
            },
            '&.Mui-focused fieldset': {
                border: `2px solid ${theme.palette.admin.primary}` // focus
            },
            '&:hover fieldset': {
                borderColor: theme.palette.admin.primary
            }
        }
    })
)

export const StyledIconButton = styled('button')(({ theme }) => ({
    border: 'none',
    borderRadius: '4px',
    padding: '0.2em 0.4em',

    backgroundColor: theme.palette.admin.primary,
    color: theme.palette.text.secondary,
    '&:hover': {
        background: theme.palette.primary.main,
        cursor: 'pointer'
    }
}))

export const StyledForm = styled('form')({
    display: 'flex',
    columnGap: '2rem',
    marginBottom: '1rem'
})

export const StyledSearchIcon = styled(SearchIcon)({
    color: 'rgba(0, 0, 0, 0.2)',
    margin: '0 0.5rem'
})

export const StyledPopover = styled(Popover)({
    '.MuiPopover-paper': {
        padding: '0.4rem'
    }
})

export const StyledPopoverText = styled(Typography)({
    padding: '0 0.5rem',
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#D9D9D9'
    }
})
