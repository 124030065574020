import send from 'assets/svgs/send.svg'
import saved from 'assets/svgs/saved.svg'
import account from 'assets/svgs/account.svg'
import campaign from 'assets/svgs/campaign.svg'
import topup from 'assets/svgs/topup.svg'
import faq from 'assets/svgs/faq.svg'
import contact from 'assets/svgs/contact.svg'

const clientSidebarItems = [
    {
        icon: send,
        label: 'Send',
        route: `${process.env.PUBLIC_URL}/send`
    },
    {
        icon: campaign,
        label: 'Campaigns',
        route: `${process.env.PUBLIC_URL}/campaigns`
    },
    {
        icon: saved,
        label: 'Saved',
        route: `${process.env.PUBLIC_URL}/saved`
    },
    {
        icon: topup,
        label: 'Top Up',
        route: `${process.env.PUBLIC_URL}/topup`,
        disabled: true
    },
    {
        icon: account,
        label: 'My Account',
        route: `${process.env.PUBLIC_URL}/account`
    },
    {
        icon: faq,
        label: 'FAQ',
        route: `${process.env.PUBLIC_URL}/faq`,
        disabled: true
    },
    {
        icon: contact,
        label: 'Contact Us',
        route: `${process.env.PUBLIC_URL}/contactus`
    }
]

export default clientSidebarItems
