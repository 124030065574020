import type { FC } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import TextInput from '../TextInput'
import { ISearchProps } from './search.interface'

const Search: FC<ISearchProps> = (props): JSX.Element => {
    const { handleSearchChange, handleSubmit } = props

    return (
        <form onSubmit={handleSubmit}>
            <TextInput
                placeholder="Search"
                InputProps={{ endAdornment: <SearchIcon /> }}
                onChange={(e) => handleSearchChange(e.target.value)}
            />
        </form>
    )
}

export default Search
