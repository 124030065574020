import type { FC } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

// Components
import Layout from 'components/layout'

import { Typography } from '@mui/material'

import { XBLAST_APP_VERSION } from 'constants/env'

import RenderAllRoutes from 'routes'

const App: FC = (): JSX.Element => {
    return (
        <>
            <Router>
                <Layout>
                    <RenderAllRoutes />
                </Layout>
            </Router>
            <Typography
                sx={{ position: 'absolute', bottom: '10px', left: '10px' }}
            >
                Version: {XBLAST_APP_VERSION}
            </Typography>
        </>
    )
}

export default App
