import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react'
import useToast from 'utils/useToast'
import clickedInfo from '../../../assets/svgs/clickedInfo.svg'
import { ITooltipProps } from './commonToolTip.interface'
import {
    StyledInfoIcon,
    StyledTooltip,
    StyledToolTipBody,
    StyledToolTipBox,
    StyledToolTipDiv,
    StyledTooltipDivArrow,
    StyledToolTipDot,
    StyledToolTipHeader
} from './commonToolTip.styled'

/** Defaults popout to right,
 *  but checks if it overflows to the right of the window, then it pops out to the left
 *
 *  other checks to be improved later on
 */
const ToolTip: FC<ITooltipProps> = (props: ITooltipProps): JSX.Element => {
    const { rules, notes, textBodySX, titleSX, tooltipSX } = props
    const [isHovering, setIsHovering] = useState(false)
    const toolTipRef = useRef<HTMLDivElement | null>(null)
    const toolTipArrowRef = useRef<HTMLSpanElement | null>(null)

    const handleMouseOver = () => {
        setIsHovering(true)
    }

    const handleMouseOut = () => {
        setIsHovering(false)
    }

    const popOutToRight = () => {
        if (toolTipRef.current && toolTipArrowRef.current) {
            toolTipRef.current!.style.left = `0`
            toolTipArrowRef.current!.style.right = 'unset'
            toolTipArrowRef.current!.style.left = '0'
        }
    }

    const popOutToLeft = (width: number) => {
        if (toolTipRef.current && toolTipArrowRef.current) {
            toolTipRef.current!.style.left = `calc(-${width}px + 0.7rem)`
            toolTipArrowRef.current!.style.right = '0'
            toolTipArrowRef.current!.style.left = 'unset'
        }
    }

    const CheckOverflow = () => {
        if (toolTipArrowRef.current) {
            const tooltipRect = toolTipRef.current!.getBoundingClientRect()
            const isRightOverflow = tooltipRect.right > window.innerWidth
            if (isRightOverflow) {
                popOutToLeft(toolTipRef.current!.clientWidth)
            } else {
                popOutToRight()
            }
        }
    }

    useLayoutEffect(() => {
        if (isHovering) {
            CheckOverflow()
        }
    }, [isHovering])

    return (
        <StyledTooltip
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
        >
            <StyledInfoIcon src={clickedInfo} />
            {isHovering && (
                <>
                    <StyledToolTipDiv
                        onMouseOver={handleMouseOver}
                        onMouseOut={handleMouseOut}
                        ref={toolTipRef}
                        sx={tooltipSX}
                    >
                        {rules?.title && (
                            <StyledToolTipBox>
                                <StyledToolTipHeader sx={titleSX}>
                                    {rules.title}
                                </StyledToolTipHeader>
                                {rules.details.map((details, index) => (
                                    <StyledToolTipBody
                                        key={index}
                                        sx={textBodySX}
                                    >
                                        <StyledToolTipDot></StyledToolTipDot>
                                        {details}
                                    </StyledToolTipBody>
                                ))}
                            </StyledToolTipBox>
                        )}
                        {notes?.title && (
                            <StyledToolTipBox>
                                <StyledToolTipHeader sx={titleSX}>
                                    {notes.title}
                                </StyledToolTipHeader>
                                {notes.details.map((details, index) => (
                                    <StyledToolTipBody
                                        key={index}
                                        sx={textBodySX}
                                    >
                                        <StyledToolTipDot></StyledToolTipDot>
                                        {details}
                                    </StyledToolTipBody>
                                ))}
                            </StyledToolTipBox>
                        )}
                        <StyledTooltipDivArrow ref={toolTipArrowRef} />
                    </StyledToolTipDiv>
                </>
            )}
        </StyledTooltip>
    )
}

export default ToolTip
