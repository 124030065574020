import React, { useState } from 'react'
import { IAddClientProps } from './addClient.interface'
import useToast from 'utils/useToast'
import { addClient } from 'api/clients/addClient'
import ClientForm from '../_components/ClientForm'
import { IClientForm } from '../_components/ClientForm/clientform.interface'

const clientInitialValues = {
    username: '',
    password: '',
    confirm_password: '',
    company_name: '',
    company_address: '',
    contact_name: '',
    contact_title: '',
    contact_mobile: '',
    contact_email: '',
    role: 'client'
}

const AddClient = ({ handleModalClose }: IAddClientProps) => {
    const [formData, setFormData] = useState<IClientForm>(clientInitialValues)

    const handleAddClient = async () => {
        try {
            const res: any = await addClient({
                username: formData.username,
                password: formData.password,
                company_name: formData.company_name,
                company_address: formData.company_address,
                contact_name: formData.contact_name,
                contact_title: formData.contact_title,
                contact_mobile: formData.contact_mobile,
                contact_email: formData.contact_email,
                role: formData.role
            })

            if (res.status === 201) {
                handleModalClose()
                setFormData(clientInitialValues)
                useToast().success('Client successfully added')
            } else {
                useToast().error(res.response.data.message)
            }
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    return (
        <ClientForm
            formTitle="Add Client"
            formData={formData}
            setFormData={setFormData}
            handleModalClose={handleModalClose}
            confirmationText="Are you sure you want to add client's details?"
            isReadOnly={false}
            handleSubmit={handleAddClient}
        />
    )
}

export default AddClient
