import React, { useState, useRef, useEffect } from 'react'
import { Box, Typography, Divider } from '@mui/material'

import {
    StyledAdminButton,
    StyledAdminFilledButton,
    StyledButtonFormContainer
} from 'admin/clients/clients.styled'
import CommonConfirmation from 'components/global/commonConfirmation'
import CommonModal from 'components/global/commonModal'
import AddSender from '../AddSender'
import AddIcon from '@mui/icons-material/Add'
import { ISenderProps } from './editSender.interface'
import { StyledContainer } from './editSender.styled'
import { FormikProps, FormikValues } from 'formik'
import { ISenderForm } from '../_components/SenderForm/senderForm.interface'
import { AxiosResponse } from 'axios'
import useToast from 'utils/useToast'
import { getSender } from 'api/clients/getSender'
import { editSender } from 'api/clients/editSender'
import EditSenderForm from '../_components/SenderForm/EditSenderForm'

const EditSender = (props: ISenderProps) => {
    const { formTitle, handleModalClose, isReadOnly, rowId } = props

    const [senderNames, setSenderNames] = useState<ISenderForm[]>([])
    const [dirtySender, setDirtySender] = useState<FormikValues[]>([])
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
    const [showAddSender, setShowAddSender] = useState<boolean>(false)
    const formRef = useRef<FormikProps<FormikValues>>(null)

    // Handler
    const handleResetPerSender = (id: number | undefined) => {
        const removeSender = dirtySender.filter((sender) => sender.id !== id)
        setDirtySender(removeSender)
    }

    const handleNo = () => {
        setDirtySender([])
        setShowConfirmation(false)
    }

    const handleSave = () => {
        if (formRef.current?.isValid) {
            setShowConfirmation(true)
        }
    }

    // Edit API Handler
    const editSenderPromise = () => {
        return dirtySender.map((sender) => {
            return editSender({
                id: sender.id,
                sender_name: sender.sender_name,
                account_id: sender.account_id,
                client_password: sender.client_password,
                client_id: sender.client_id
            })
        })
    }
    const handleEditSender = async () => {
        try {
            ;(await Promise.all(
                editSenderPromise()
            )) as unknown as AxiosResponse<ISenderForm[]>

            handleModalClose()
            setShowConfirmation(false)
            useToast().success("Editing sender's details was successful.")
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    // Get Sender Names API
    const getSenderResponse = async () => {
        try {
            const res = (await getSender({
                id: rowId
            })) as AxiosResponse<ISenderForm[]>

            setSenderNames(res.data)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    useEffect(() => {
        getSenderResponse()
    }, [showAddSender])

    return (
        <Box>
            {Array.isArray(senderNames) ? (
                <>
                    <Typography variant="h3" mb={4} textAlign="center">
                        {isReadOnly ? 'Sender Details' : formTitle}
                    </Typography>
                    <Box display="flex" justifyContent="flex-end">
                        <StyledAdminButton
                            sx={{ borderRadius: '40px', width: '1rem' }}
                            onClick={() => setShowAddSender(true)}
                        >
                            <AddIcon /> Add Sender
                        </StyledAdminButton>
                    </Box>

                    {/* FORMS */}
                    <StyledContainer isGreaterThanTwo={senderNames.length > 2}>
                        {senderNames.map((data, index) => {
                            return (
                                <Box key={data.id} mb={6}>
                                    <EditSenderForm
                                        formRef={formRef}
                                        formData={data}
                                        setDirtySender={setDirtySender}
                                        isReadOnly={isReadOnly}
                                        handleResetPerSender={
                                            handleResetPerSender
                                        }
                                    />
                                    {index < senderNames.length - 1 && (
                                        <Divider sx={{ margin: '2rem 0' }} />
                                    )}
                                </Box>
                            )
                        })}
                        {!isReadOnly && (
                            <StyledButtonFormContainer>
                                <StyledAdminButton onClick={handleModalClose}>
                                    Cancel
                                </StyledAdminButton>
                                <StyledAdminFilledButton
                                    onClick={handleSave}
                                    disabled={!dirtySender.length}
                                >
                                    Save
                                </StyledAdminFilledButton>
                            </StyledButtonFormContainer>
                        )}
                    </StyledContainer>
                </>
            ) : (
                <Box>
                    <Typography variant="h3" textAlign="center" my={4}>
                        No Sender Names Available
                    </Typography>
                    <Box display="flex" justifyContent="center">
                        <StyledAdminButton
                            sx={{ borderRadius: '40px', width: '1rem' }}
                            onClick={() => setShowAddSender(true)}
                        >
                            <AddIcon /> Add Sender
                        </StyledAdminButton>
                    </Box>
                </Box>
            )}

            {/* ADD SENDER MODAL */}
            <CommonModal
                open={showAddSender}
                close={() => setShowAddSender(false)}
            >
                <AddSender
                    handleModalClose={() => setShowAddSender(false)}
                    rowId={rowId}
                />
            </CommonModal>

            {/* __ CONFIRMATION MODAL __ */}
            <CommonConfirmation
                open={showConfirmation}
                close={() => setShowConfirmation(false)}
                confirmationText={`Are you sure you want to save the changes you made?`}
            >
                <Box display="flex" justifyContent="center" columnGap={2}>
                    <StyledAdminButton onClick={handleNo}>No</StyledAdminButton>
                    <StyledAdminFilledButton onClick={handleEditSender}>
                        Yes
                    </StyledAdminFilledButton>
                </Box>
            </CommonConfirmation>
        </Box>
    )
}

export default EditSender
