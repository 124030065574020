import aes from 'crypto-js/aes'
import enc from 'crypto-js/enc-utf8'
import { AccountRoles, RoleHomePaths, RoleLoginPaths } from './app.const'
import { IUser } from './app.interface'

const _w = window

const isAuthenticated = (): boolean => {
    // TODO: Properly need to check if user is authenticated or not
    if (getToken()) return true

    return false
}

const encryptData = (data: string): string => {
    return aes.encrypt(data, process.env.REACT_APP_SECRET_KEY || '').toString()
}

const decryptData = (data: string): string => {
    const bytes = aes.decrypt(data, process.env.REACT_APP_SECRET_KEY || '')
    const originalText = bytes.toString(enc)
    return originalText
}

const getUser = (): IUser | null => {
    const user = _w.localStorage.getItem('user')
    if (user) return JSON.parse(decryptData(user))
    return null
}

const setUser = (user: IUser): void => {
    _w.localStorage.setItem('user', encryptData(JSON.stringify(user)))
}

const isAuthorized = (role: AccountRoles): boolean => {
    return getRole() === role
}

const clearUser = (): void => {
    _w.localStorage.clear()
}

const getRole = (): AccountRoles | null => {
    return (getUser()?.role as unknown as AccountRoles) ?? null
}

const getToken = (): string | null => {
    const token = _w.localStorage.getItem('token')
    if (token) return decryptData(token)
    return null
}

const setToken = (token: string): void => {
    _w.localStorage.setItem('token', encryptData(token))
}

const clearToken = (): void => {
    _w.localStorage.clear()
}

const getUserHome = () => {
    return RoleHomePaths[
        getRole()!.toUpperCase() as unknown as keyof typeof AccountRoles
    ]
}

const getUserLoginPath = (role: AccountRoles) => {
    return RoleLoginPaths[
        role.toLocaleUpperCase() as unknown as keyof typeof RoleLoginPaths
    ]
}

export {
    isAuthenticated,
    setToken,
    getToken,
    clearToken,
    getUser,
    setUser,
    isAuthorized,
    clearUser,
    getRole,
    getUserHome,
    getUserLoginPath
}
