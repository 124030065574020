import { ITableColumn } from 'components/global/commonTable/commonTable.interface'
import { IHistory } from 'utils/app.interface'
import { campaignType } from '../campaigns.const'
import { IRecipientsTableData } from './history.interface'

export const historyColumns = [
    {
        columnLabel: 'Date Created',
        columnName: 'date_created' as const
    },
    {
        columnLabel: 'End of Blast',
        columnName: 'date_of_blast' as const
    },
    {
        columnLabel: 'Campaign Name',
        columnName: 'campaign_name' as const
    },
    {
        columnLabel: 'Campaign Type',
        columnName: 'recipient_type' as const,
        render: (row: IHistory) => {
            return row.recipient_type === campaignType.SMS ? 'SMS' : 'Load'
        }
    },
    {
        columnLabel: 'Total Recipients',
        columnName: 'numberOfRecipients' as const
    },
    {
        columnLabel: 'Total Sent',
        columnName: 'sentCount' as const
    }
]

export const modalColumns: ITableColumn<IRecipientsTableData>[] = [
    {
        columnLabel: 'Mobile Number',
        columnName: 'mobile' as const
    },
    {
        columnLabel: 'Status',
        columnName: 'status' as const
    },
    {
        columnLabel: 'REASON',
        columnName: 'reason' as const
    }
]

export enum STATUS_DESCRIPTION {
    'Sent' = 1,
    'With Fallouts' = 2
}

export const csvColumns = [
    {
        label: 'Mobile Number',
        key: 'mobile'
    },
    {
        label: 'Status',
        key: 'statusDescription'
    },
    {
        label: 'Reason',
        key: 'reason'
    }
]

export const filterBy = [
    {
        label: 'All Data',
        value: ''
    },
    {
        label: 'Successful',
        value: '1'
    },
    {
        label: 'Fall Outs',
        value: '2'
    }
]

export const downloadModalSelect = [
    {
        label: 'CSV',
        value: 'csv'
    }
    // {
    //     label: 'PDF',
    //     value: 'pdf'
    // }
]

export const historyHeaders = [
    {
        label: 'DATE_CREATED',
        key: 'date_created'
    },
    {
        label: 'TOTAL_AMOUNT',
        key: 'total_amount'
    },
    {
        label: 'TOTAL_SENT',
        key: 'sentCount'
    },
    {
        label: 'CAMPAIGN_NAME',
        key: 'campaign_name'
    },
    {
        label: 'CAMPAIGN_TYPE',
        key: 'campaign_type'
    },
    {
        label: 'END_OF_BLAST',
        key: 'date_of_blast'
    }
]
