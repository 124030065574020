import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
    // eslint-disable-next-line
    interface PaletteOptions {
        danger: {
            main: string
            light: string
            contrastText: string
        }
        tertiary: {
            main: string
            light?: string
        }
        mainBg: {
            main: string
            light?: string
        }
        admin: {
            primary: string
        }
    }
}

export const theme = createTheme({
    palette: {
        action: {
            disabledBackground: '#A2A2AF',
            disabled: '#FFFFFF'
        },
        primary: {
            main: '#001AA2'
        },
        secondary: {
            main: '#BBD2FF'
        },
        tertiary: {
            main: '#CCD6FF'
        },
        mainBg: {
            main: '#EEF0F8'
        },
        success: {
            main: '#4CAF50',
            light: '#DDFFDD',
            contrastText: '#FFFFFF'
        },
        danger: {
            main: '#F44336',
            light: '#FFDDDD',
            contrastText: '#FFFFFF'
        },
        text: {
            primary: '#494949',
            secondary: '#FFFFFF'
        },
        common: {
            black: '#707070',
            white: '#FFFFFF'
        },
        info: {
            main: '#2196F3',
            light: '#DDFFFF'
        },
        grey: {
            50: '#151C1B',
            100: '#707070',
            200: '#959595'
        },
        admin: {
            primary: '#8994CE'
        }
    },
    typography: {
        fontFamily: ['Poppins', 'sans-serif'].join(','),
        h1: {
            fontSize: '2.25rem',
            fontWeight: 800
        },
        h2: {
            fontSize: '1.875rem',
            fontWeight: 600
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 600
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 600
        },
        h5: {
            fontSize: '0.875rem',
            fontWeight: 600
        },
        h6: {
            fontSize: '0.75rem',
            fontWeight: 600
        },
        subtitle1: {
            fontSize: '0.75rem'
        },
        body1: {
            fontSize: '1rem'
        }
    },
    shape: {
        borderRadius: 8
    }
})
