// Absolute
import { Box, Typography } from '@mui/material'
import { FC, FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

// Relative
import { login } from 'api/login'
import Button from 'components/global/Button'
import TextInput from 'components/global/TextInput'
import { AccountRoles, AdminPaths } from 'utils/app.const'
import { isAuthorized, setToken, setUser } from 'utils/token'
import {
    StyledMainBackgroundImage,
    StyledMainContainer,
    StyledMainContent
} from './adminLogin.styled'

const AdminLogin: FC = (): JSX.Element => {
    // Hooks
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<string | null>(null)

    // edit
    // Handlers
    const handleLogIn = async (e: FormEvent) => {
        // TODO: Implement login logic/api integration
        // Simulate success redirect to dashboard
        e.preventDefault()

        try {
            const res = await login({ username, password })
            const { token, ...details } = res.data
            setUser(details)
            setToken(token)
            if (isAuthorized(AccountRoles.ADMIN)) {
                navigate(AdminPaths.HOME)
            } else {
                setError('User Account unauthorized.')
            }
        } catch (error: any) {
            setError(error.message)
        }
    }

    const loginDisabled = (): boolean => {
        return username.length === 0 || password.length === 0
    }

    return (
        <>
            <StyledMainContainer>
                <StyledMainBackgroundImage />
                <StyledMainContent>
                    <Box>
                        <Box>
                            <img
                                src={require('assets/images/Logo.png')}
                                alt=""
                            />
                        </Box>
                        <Box mb={5}>
                            <Typography variant="h2" color="primary">
                                ADMIN LOG IN
                            </Typography>
                        </Box>
                        <Box>
                            <form onSubmit={handleLogIn}>
                                <Box mb={3}>
                                    <Typography
                                        variant="subtitle1"
                                        mb={1}
                                        fontWeight={500}
                                    >
                                        Email Address
                                    </Typography>
                                    <TextInput
                                        autoFocus
                                        required
                                        type="email"
                                        fullWidth
                                        value={username}
                                        error={error !== null}
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                        onClick={(e) => setError(null)}
                                    />
                                </Box>
                                <Box mb={3}>
                                    <Typography
                                        variant="subtitle1"
                                        mb={1}
                                        fontWeight={500}
                                    >
                                        Password
                                    </Typography>
                                    <TextInput
                                        required
                                        fullWidth
                                        type="password"
                                        value={password}
                                        error={error !== null}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        onClick={(e) => setError(null)}
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        disabled={loginDisabled()}
                                    >
                                        LOG IN
                                    </Button>
                                    {error && (
                                        <Typography
                                            mt={2}
                                            color="red"
                                            fontSize={12}
                                            align="center"
                                        >
                                            {error}
                                        </Typography>
                                    )}
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </StyledMainContent>
            </StyledMainContainer>
        </>
    )
}

export default AdminLogin
