import { Grid, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Dropdown from 'components/global/Dropdown'
import TextInput from 'components/global/TextInput'
import { numberFormatLength } from 'pages/send/step2/step2.cont'
import React, { FC } from 'react'
import { isConstructorDeclaration } from 'typescript'
import { isValidNumber } from 'utils'
import { days, genders, Months, years } from 'utils/app.const'
import {
    ISingleMemberProps,
    ISingleMemberHandlersProps
} from './singleNumber.interface'
import { StyledFormControl, StyledInputLabel } from './singleNumber.styled'

const CommonButton = require('components/global/Button').default

type AllProps = ISingleMemberProps & ISingleMemberHandlersProps

const SingleNumberModal: FC<AllProps> = (props: AllProps) => {
    const addDisabled = (): boolean => {
        return props.number.length !== 11 || hasNumberError()
    }

    const hasNumberError = () => {
        if (props.number.length < 1) return false
        return (
            props.number.length !== numberFormatLength ||
            !isValidNumber(props.number)
        )
    }

    return (
        <div>
            <>
                <Box padding={4}>
                    <Typography variant="h2" color="primary" mb={3}>
                        {props.actionMember} Member
                    </Typography>
                    <Box mb={3}>
                        <Typography variant="h3">Basic Info</Typography>
                        <Typography fontSize={13}>
                            <span style={{ color: 'red' }}>*</span> required
                            fields
                        </Typography>
                    </Box>
                    <form
                        onSubmit={props.handleAddMemberProp}
                        autoComplete="off"
                    >
                        <Grid container gap={4} mb={3}>
                            <Stack spacing={2}>
                                <Box>
                                    <Typography fontStyle="italic">
                                        Last Name
                                    </Typography>
                                    <TextInput
                                        value={props.last_Name}
                                        onChange={props.handleLastNameProp}
                                    />
                                </Box>
                                <Box>
                                    <Typography fontStyle="italic">
                                        First Name
                                    </Typography>
                                    <TextInput
                                        value={props.first_Name}
                                        onChange={props.handleFirstNameProp}
                                    />
                                </Box>
                            </Stack>
                            <Stack spacing={2}>
                                <Box>
                                    <Typography fontStyle="italic">
                                        Contact Number
                                        <span style={{ color: 'red' }}> *</span>
                                    </Typography>

                                    <TextInput
                                        placeholder="09XXXXXXXXX"
                                        value={props.number}
                                        onChange={props.handleContactNumberProp}
                                        error={hasNumberError()}
                                    />
                                </Box>
                                <Box>
                                    <Typography fontStyle="italic">
                                        Email Address
                                    </Typography>
                                    <TextInput
                                        value={props.email_address}
                                        onChange={props.handleEmailProp}
                                        type="email"
                                    />
                                </Box>
                            </Stack>
                        </Grid>
                        <Box display="flex" gap={5} mb={7}>
                            <Box>
                                <Typography fontStyle="italic" mb={1.5}>
                                    Birthday
                                </Typography>
                                <Box display="flex" gap={4}>
                                    <StyledFormControl>
                                        <StyledInputLabel>
                                            Year
                                        </StyledInputLabel>
                                        <Dropdown
                                            defaultValue={props.birth_year + 1}
                                            options={years.map((year) => ({
                                                label: year.toString(),
                                                value: year.toString()
                                            }))}
                                            value={props.birth_year}
                                            onChange={props.handleBirthYearProp}
                                        />
                                    </StyledFormControl>
                                    <StyledFormControl
                                        sx={{ width: 110, height: 46 }}
                                    >
                                        <StyledInputLabel>
                                            Month
                                        </StyledInputLabel>
                                        <Dropdown
                                            options={Months.map((month) => ({
                                                label: month.label,
                                                value: month.value
                                            }))}
                                            value={props.birth_month}
                                            onChange={
                                                props.handleBirthMonthProp
                                            }
                                        />
                                    </StyledFormControl>
                                    <StyledFormControl
                                        sx={{ width: 110, height: 46 }}
                                    >
                                        <StyledInputLabel>Day</StyledInputLabel>
                                        <Dropdown
                                            options={days.map((day) => ({
                                                label: day.toString(),
                                                value: day.toString()
                                            }))}
                                            value={props.birth_day}
                                            onChange={props.handleBirthDayProp}
                                        />
                                    </StyledFormControl>
                                </Box>
                            </Box>
                            <Box>
                                <Typography fontStyle="italic" mb={1.5}>
                                    Gender
                                </Typography>
                                <StyledFormControl
                                    sx={{ width: 110, height: 46 }}
                                >
                                    <Dropdown
                                        options={genders}
                                        value={props.gender}
                                        onChange={props.handleGenderMemberProp}
                                        displayEmpty
                                    />
                                </StyledFormControl>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="center">
                            <CommonButton
                                disabled={addDisabled()}
                                type="submit"
                            >
                                ADD
                            </CommonButton>
                        </Box>
                    </form>
                </Box>
            </>
        </div>
    )
}

export default SingleNumberModal
