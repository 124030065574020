export const SavedMessagesColumn = [
    {
        columnLabel: 'Title',
        columnName: 'title' as const
    },
    {
        columnLabel: 'Date Created',
        columnName: 'dateCreated' as const
    },
    {
        columnLabel: 'Message',
        columnName: 'message' as const
    },
    {
        columnLabel: 'Total SMS Count',
        columnName: 'messageCount' as const
    }
]

export const SavedGroupsColumn = [
    {
        columnLabel: 'Title',
        columnName: 'name' as const
    },
    {
        columnLabel: 'Date Created',
        columnName: 'date_created' as const
    },
    {
        columnLabel: 'Total Numbers',
        columnName: 'total_numbers' as const
    },
    {
        columnLabel: 'Created By',
        columnName: 'created_by' as const
    },
    {
        columnLabel: 'Last Used',
        columnName: 'last_used' as const
    }
]

export const GroupsEmployeesColumn = [
    {
        columnLabel: 'Last Name',
        columnName: 'last_name' as const
    },
    {
        columnLabel: 'First Name',
        columnName: 'first_name' as const
    },
    {
        columnLabel: 'Mobile Number',
        columnName: 'mobile_number' as const
    },
    {
        columnLabel: 'Status',
        columnName: 'status' as const
    }
]
