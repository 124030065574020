import type { FC } from 'react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { ToastContainer } from 'react-toastify'
import { theme } from 'theme'
import { ILayoutProps } from './layout.interface'

const Layout: FC<ILayoutProps> = ({ children }): JSX.Element => {
    return (
        <>
            <CssBaseline />
            <ThemeProvider theme={theme}>
                {children}
                <ToastContainer />
            </ThemeProvider>
        </>
    )
}

export default Layout
