import React from 'react'
import Sidebar from '.'
import clientSidebarItems from './clientSidebar.const'

type Props = {}

function ClientSidebar() {
    return <Sidebar sidebarItems={clientSidebarItems} />
}

export default ClientSidebar
