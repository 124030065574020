import React, { FC, useEffect, useState, useRef, ChangeEvent } from 'react'
import {
    Box,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    InputAdornment
} from '@mui/material'
import CommonTable from 'components/global/commonTable'
import {
    StyledCheckbox,
    StyledShowReport,
    StyledCSVLink,
    MenuProps,
    StyledFromInput,
    StyledToInput
} from './reports.styled'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import { IReports } from 'utils/app.interface'
import useToast from 'utils/useToast'
import { reportsColumn, reportsColumnCSV } from './reports.const'
import { getReports, getAllCompanyNames } from 'api/reports/getReports'
import { useTheme } from '@mui/system'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { DateRangePicker } from 'react-date-range'
import moment from 'moment'
import {
    defaultRowsPerPage,
    isCurrentPage,
    parseIntConfig
} from 'utils/app.const'

const Reports: FC = () => {
    const theme = useTheme()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage)
    const [recordLength, setRecordLength] = useState(0)
    const [selected, setSelected] = useState<string[]>([])
    const [companyNames, setCompanyNames] = useState([])
    const [reports, setReports] = useState([])
    const [show, setShow] = useState<boolean>(false)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const momentStart = moment(startDate).format(`YYYY-MM-DD 00:00:00`)
    const momentEnd = moment(endDate).format('YYYY-MM-DD 23:59:59')
    const displayStart = moment(startDate).format('MM/DD/YYYY')
    const displayEnd = moment(endDate).format('MM/DD/YYYY')
    const handleSelect = (ranges: any) => {
        setStartDate(ranges.selection.startDate)
        setEndDate(ranges.selection.endDate)
    }
    const dropdownRef = useRef<HTMLDivElement>(null)
    const handleDropDownFocus = (state: boolean) => {
        setShow(!state)
    }
    const handleClickOutsideDropdown = (e: any) => {
        if (show && !dropdownRef.current?.contains(e.target as Node)) {
            setShow(false)
        }
    }
    window.addEventListener('click', handleClickOutsideDropdown)

    const selectionRange = {
        startDate,
        endDate,
        key: 'selection'
    }

    const getCompanyNames = async () => {
        try {
            const res = await getAllCompanyNames()
            setCompanyNames(res.data)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }
    const getTableData = async () => {
        try {
            const res = await getReports({
                limit: rowsPerPage,
                page: isCurrentPage(page, '')
            })
            setReports(res.data)
            setRecordLength(res.data[0].total_report_count)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }
    const getTableDataSearch = async () => {
        try {
            const res = await getReports({
                company_names: selected,
                start_date: momentStart,
                end_date: momentEnd,
                limit: rowsPerPage,
                page: isCurrentPage(page, '')
            })
            setReports(res.data)
        } catch (error: any) {
            useToast().error(error.message)
        }
    }

    const sentCount = reports.map((item: IReports) => item.sent_count)
    const totalCount = sentCount.reduce((a, v) => (a = a + v), 0)

    const isAllSelected =
        companyNames.length > 0 && selected.length === companyNames.length

    const handleChange = (event: SelectChangeEvent<typeof selected>) => {
        const {
            target: { value }
        } = event
        if (value[value.length - 1] === 'all') {
            setSelected(
                selected.length === companyNames.length ? [] : companyNames
            )
            return
        }
        setSelected(typeof value === 'string' ? value.split(',') : value)
    }
    const handleSearch = () => {
        getTableDataSearch()
    }
    const handleClear = () => {
        getTableData()
        setSelected([])
        setStartDate(new Date())
        setEndDate(new Date())
    }
    useEffect(() => {
        getTableData()
        getCompanyNames()
    }, [page, rowsPerPage])

    const csvReport = {
        filename: 'Report.csv',
        headers: reportsColumnCSV,
        data: reports
    }

    const handleChangePage = (
        event: ChangeEvent<unknown> | null,
        newPage: number
    ) => {
        setPage(newPage - 1)
    }

    const handleChangeRowsPerPage = (event: SelectChangeEvent<string>) => {
        setRowsPerPage(parseInt(event.target.value, parseIntConfig))
        setPage(0)
    }
    const paginationPayload = {
        currentPage: page,
        currentLimit: rowsPerPage,
        total: recordLength,
        onPageChange: handleChangePage,
        handleOnPageSizeChange: handleChangeRowsPerPage,
        showOption: true
    }
    return (
        <>
            <Typography variant="h2" color="primary" mb={3}>
                Reports
            </Typography>
            <Box display="flex" gap={5} alignItems="center">
                <Box>
                    <Typography sx={{ color: theme.palette.grey[100] }}>
                        Client Name
                    </Typography>
                    <div>
                        <FormControl sx={{ width: '300px' }}>
                            <InputLabel sx={{ color: theme.palette.grey[200] }}>
                                Select All
                            </InputLabel>
                            <Select
                                multiple
                                value={selected}
                                onChange={handleChange}
                                renderValue={(selected) => selected.join(', ')}
                                sx={{
                                    background: theme.palette.common.white,
                                    color: theme.palette.primary.main
                                }}
                                MenuProps={MenuProps}
                            >
                                <MenuItem value="all">
                                    <ListItemIcon>
                                        <StyledCheckbox
                                            checked={isAllSelected}
                                            indeterminate={
                                                selected.length > 0 &&
                                                selected.length <
                                                    companyNames.length
                                            }
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Select All" />
                                </MenuItem>
                                {companyNames.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <ListItemIcon>
                                            <StyledCheckbox
                                                checked={
                                                    selected.indexOf(option) >
                                                    -1
                                                }
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={option} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </Box>
                <Box ref={dropdownRef}>
                    <Typography sx={{ color: theme.palette.grey[100] }}>
                        Date Range
                    </Typography>
                    <div style={{ position: 'relative' }}>
                        <StyledFromInput
                            value={displayStart}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                From
                                            </Typography>
                                            <CalendarTodayIcon fontSize="small" />
                                        </div>
                                    </InputAdornment>
                                )
                            }}
                            onClick={() => handleDropDownFocus(show)}
                        />
                        <StyledToInput
                            value={displayEnd}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column'
                                            }}
                                        >
                                            <Typography variant="subtitle1">
                                                To
                                            </Typography>
                                            <CalendarTodayIcon fontSize="small" />
                                        </div>
                                    </InputAdornment>
                                )
                            }}
                            onClick={() => handleDropDownFocus(show)}
                        />
                    </div>
                    {show && (
                        <div
                            style={{
                                position: 'absolute'
                            }}
                        >
                            <DateRangePicker
                                ranges={[selectionRange]}
                                maxDate={new Date()}
                                rangeColors={[theme.palette.primary.main]}
                                onChange={handleSelect}
                            />
                        </div>
                    )}
                </Box>
                <Box display="flex" gap={2}>
                    <StyledShowReport onClick={handleSearch}>
                        Show Report
                    </StyledShowReport>
                    <StyledShowReport onClick={handleClear}>
                        Reset Search
                    </StyledShowReport>
                </Box>
            </Box>
            <Box mt={5}>
                <CommonTable
                    columns={reportsColumn}
                    rows={reports}
                    paginationPayload={paginationPayload}
                />
                {totalCount > 0 ? (
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        gap={5}
                        mt={5}
                    >
                        <Typography>TOTAL {totalCount}</Typography>
                        <StyledCSVLink {...csvReport}>
                            <Box display="flex">
                                <DownloadOutlinedIcon />
                                <Typography>Download All</Typography>
                            </Box>
                        </StyledCSVLink>
                    </Box>
                ) : (
                    <></>
                )}
            </Box>
        </>
    )
}

export default Reports
