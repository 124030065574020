import { styled } from '@mui/system'

export const StyledWelcomeCardIcon = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    background: theme.palette.secondary.main,
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden'
}))

export const StyledComingSoonOverlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    width: '120%',
    minHeight: '2em',
    background: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'rotate(-10deg)',
    color: theme.palette.common.white,
    fontSize: '1.3rem',
    fontWeight: 500,
    opacity: 0.73
}))
