// Absolute
import React, { FC } from 'react'

// Relative
import BasicTabs from 'components/global/commonTabComponent'
import TopUpHistory from './topUpHistory'
import TopUpMain from './topUpMain'

const TopUpTabs = [
    {
        label: 'Top Up',
        children: (
            <>
                <TopUpMain />
            </>
        )
    },
    {
        label: 'Top Up History',
        children: (
            <>
                <TopUpHistory />
            </>
        )
    }
]
const TopUp: FC = () => {
    return (
        <div>
            <BasicTabs tabs={TopUpTabs} />
        </div>
    )
}
export default TopUp
